import { cloneDeep } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, NgZone, OnInit } from '@angular/core';

import { CBelt } from '../../../../models/cBelt.model';

import { BeltsService } from '../../../../services/c-belt/belts.service';
import { StateService } from '../../../../services/c-belt/state.service';
import { AlertService } from '../../../../shared/services/alert/alert.service';
import { LoadingService } from './../../../../shared/services/loading/loading.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from '../../../../state/installation/installation.state';
import * as InstallationActions from '../../../../state/installation/installation.actions';
import * as InstallationSelectors from '../../../../state/installation/installation.selector';

@Component({
  selector: 'app-pair-conveyor-and-belt',
  templateUrl: './pair-conveyor-and-belt.component.html',
  styleUrls: ['./pair-conveyor-and-belt.component.scss'],
})
export class PairConveyorAndBeltComponent implements OnInit {
  public editing: boolean;
  public rfidTagId: string;
  public companyId: string;
  public belt: CBelt = new CBelt();
  public scanning: boolean = false;
  public current: ConfigurationState = null;

  public constructor(
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private cBeltService: BeltsService,
    private stateService: StateService,
    private loadingService: LoadingService,
  ) {}

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
    });
    this.subscribeToCurrentConfiguration();
  }

  public subscribeToCurrentConfiguration(): void {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.editing = this.current.editing;
        this.belt = this.current.belt;
        this.belt.rfidTagId = '';
      });
  }

  public startScanning(): void {
    this.toggleScanning();
    window['plugins'].rfidScannerPlugin.scan(
      (rfid) => this.ngZone.run(() => this.onScanFound(rfid)),
      (err) => this.ngZone.run(() => this.onRfidError(err)),
    );
  }

  public async onScanFound(rfid: string): Promise<void> {
    this.stopScanning();
    this.rfidTagId = rfid;
    this.navToHome();
  }

  public onRfidError(err: unknown): void {
    this.scanning = false;
    this.alertService.alertError(JSON.stringify(err));
  }

  public stopScanning(): void {
    window['plugins'].rfidScannerPlugin.stop(
      () => this.ngZone.run(() => this.onScanningStopped()),
      (err) => this.ngZone.run(() => this.onRfidError(err)),
    );
  }

  private onScanningStopped(): void {
    this.toggleScanning();
  }

  private toggleScanning(): void {
    this.scanning = !this.scanning;
  }

  public async navToHome(): Promise<void> {
    this.loadingService.show();
    try {
      this.belt.rfidTagId = this.rfidTagId;
      this.belt.cpr = this.current.belt.cpr;
      this.belt.conveyorId = this.current.conveyor.id;
      this.belt.salesLocationCode = this.current.belt.salesLocationCode;

      await this.cBeltService.updateBeltWithConveyorId(this.belt);

      this.alertService.alertSuccess('Belt and Conveyor Paired Successfully!');
      this.store.dispatch(InstallationActions.updateBelt({ belt: this.belt }));
      this.stateService.subscribeToCurrentAndSave();
      this.router.navigate(['/installing', this.companyId]);
    } catch (err) {
      this.loadingService.hide();
      this.alertService.handleError(err);
      this.router.navigate(['/installing', this.companyId]);
    }
    this.loadingService.hide();
  }
}
