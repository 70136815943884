<div class="mobile" *ngIf="conveyor">
  <app-mobile-header
    [header]="'Pairing the Conveyor with a reader box'"
    [routerLinkUrl]="['/installing', companyId, 'conveyor-reader-configuration']"
  >
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <form name="configuration-form" class="d-flex">
      <div class="bg-white card p-3">
        <button type="button" class="btn btn-primary" (click)="navToPairReader()">
          Or: Add With NFC
        </button>

        <label for="readerId" class="form-label mt-5">Reader ID </label>
        <div class="grey">This unique ID can be found on the box the reader came in</div>
        <input
          [(ngModel)]="rfidReaderId"
          class="form-control mt-2"
          name="readerId"
          type="number"
          placeholder="eg: 24756373473"
        />
      </div>
    </form>

    <button type="button" class="btn btn-primary mt-4" (click)="navToAddConveyorReader()">
      Continue
    </button>
  </div>
</div>
