<div class="mobile">
  <app-mobile-header
    [header]="'Pairing a Belt and Conveyor'"
    [routerLinkUrl]="['/installing', companyId, 'conveyor-reader-configuration']">
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <div class="bg-white card p-3">
      <div class="text-center">Connect the RFID reader to your phone and scan the belt chip</div>

      <div class="d-flex justify-content-center">
        <button
          *ngIf="!scanning"
          type="button"
          class="btn btn-primary mt-2"
          (click)="startScanning()">
          Start Scanning
        </button>
        <button
          *ngIf="scanning"
          type="button"
          class="btn btn-primary mt-2"
          (click)="stopScanning()">
          Stop Scanning
        </button>
      </div>

      <div class="small-grey text-center mt-2 px-5">
        Waiting for the reader to pick up the RFID signal
      </div>

      <div
        *ngIf="scanning"
        class="d-flex flex-column w-100 justify-content-center align-items-center mt-2">
        <div class="spinner-border text-primary"></div>
        <div>Scanning...</div>
      </div>
    </div>

    <div class="d-flex justify-content-center my-3">OR</div>

    <div class="bg-white card p-3">
      <label
        for="beltRfid"
        class="form-label"
        >RFID</label
      >
      <input
        [(ngModel)]="belt.rfidTagId"
        class="form-control mt-2"
        name="beltRfid"
        type="text"
        placeholder="eg: 34735693" />
      <button
        (click)="navToHome()"
        type="button"
        class="btn btn-primary mt-4">
        Pair
      </button>
    </div>
  </div>
</div>
