<div class="mobile">
  <app-mobile-header
    [header]="'Configuring the wi-fi device'"
    [routerLinkUrl]="[
      '/installing',
      companyId,
      'gateway-configuration',
      'new-gateway-device',
      'configure-wifi'
    ]">
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <form
      name="configuration-form"
      (submit)="navToSteps()">
      <div class="bg-white card p-3">
        <div class="d-flex justify-content-between">
          <div class="pb-2">Configuring the wi-fi device...</div>
          <div
            *ngIf="loading"
            class="spinner-border spinner-border-sm mt-1"></div>
        </div>
        <div *ngFor="let message of messages">
          <ul>
            <li>{{ message }}</li>
          </ul>
        </div>

        <button
          [disabled]="loading"
          type="submit"
          class="btn btn-primary mt-1">
          {{ loading ? "Configuring..." : "Save" }}
        </button>
      </div>
      <div class="d-flex small grey wrap mt-2 ms-2">Ensure the Gateway Device is turned on</div>
      <div class="d-flex small grey wrap mt-2 ms-2">
        Give the Gateway Device time to configure itself
      </div>
    </form>
  </div>
</div>
