import { Csc } from './../../models/csc.model';
import { BaseState } from '../base.state';

export class CscState extends BaseState {
  public cscs: Csc[];
}

export const initialState = {
  cscs: [],
};
