import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserState } from './user.state';

// const formatError = (err) => {
//   switch (err?.message) {
//     case AuthErrors.emailAddressAlreadyExists:
//       return 'Email Address Already Exists';
//     case AuthErrors.badlyFormattedEmailAddress:
//       return 'Email Address Badly Formatted';
//     case AuthErrors.noPasswordSupplied:
//       return 'No Password Supplied';
//     case AuthErrors.invalidEmailOrPassword:
//       return 'Invalid Email Or Password';
//     case AuthErrors.userBlocked:
//       return 'User blocked, please reset your password';
//     case AuthErrors.invalidTwoFactorAuthCode:
//       return 'Invalid Two Factor Authentication Code';
//     case AuthErrors.emailAddressDoesNotExist:
//       return 'Email Address Does Not Exist';
//     case AuthErrors.invalidResetCode:
//       return 'Invalid Reset Code';
//     case AuthErrors.unexpectedError:
//       return 'Unexpected error';
//   }

//   return err;
// };

export const selectUser = createFeatureSelector<UserState>('user');

export const selectCurrentUser = createSelector(
  selectUser,
  (state: UserState) => state.currentUser
);

export const selectLoading = createSelector(
  selectUser,
  (state: UserState) => state.loading
);

// export const selectError = createSelector(selectUser, (state: UserState) =>
//   formatError(state.error)
// );

export const selectIsLoggedIn = createSelector(
  selectCurrentUser,
  (currentUser) => !!currentUser
);

export const selectPasswordResetInitiated = createSelector(
  selectUser,
  (state: UserState) => !!state.passwordResetInitiated
);

export const selectPasswordResetCompleted = createSelector(
  selectUser,
  (state: UserState) => !!state.passwordResetCompleted
);
