<div
  class="form-group position-relative"
  [formGroup]="form">
  <label
    class="form-label position-absolute top-0 start-0 small px-1 bg-white"
    *ngIf="label">
    {{ label }}
    <span
      class="text-danger small"
      *ngIf="isRequired()">
      *
    </span>
  </label>
  <input
    class="form-control dark-border py-3"
    [type]="type"
    [formControlName]="name"
    [class.is-invalid]="formControl.invalid && form.submitted"
    [autofocus]="autofocus"
    [placeholder]="placeholder"
    (blur)="focus = false"
    (focus)="focus = true" />
  <div
    *ngIf="form.submitted && !focus && this.formControl.invalid"
    class="errors">
    <div
      class="error"
      [data-testid]="name + '-error-input'">
      {{ getError() }}
    </div>
  </div>
</div>
