export class Installation {
  public id: string;
  public orderNumber: string;
  public rfidTagId: string;
  public conveyorId: string;
  public organizationId: string;
  public installedUtc: string;
  public deinstalledUtc: string;
  public width: string;
  public length: string;
  public conveyorName: string;
  public machineId: string;
  public machineName: string;

  public static fromJson(json: {
    id: string;
    orderNumber: string;
    rfidTagId: string;
    conveyorId: string;
    organizationId: string;
    installedUtc: string;
    deinstalledUtc: string;
    width: string;
    length: string;
    conveyorName: string;
    machineId: string;
    machineName: string;
  }): Installation {
    const installation = new Installation();
    installation.id = json.id;
    installation.orderNumber = json.orderNumber;
    installation.rfidTagId = json.rfidTagId;
    installation.conveyorId = json.conveyorId;
    installation.organizationId = json.organizationId;
    installation.installedUtc = json.installedUtc;
    installation.deinstalledUtc = json.deinstalledUtc;
    installation.width = json.width;
    installation.length = json.length;
    installation.conveyorName = json.conveyorName;
    installation.machineId = json.machineId;
    installation.machineName = json.machineName;
    return installation;
  }
}
