import { Model } from '../../shared/models/model';
export var TwoFactorAuthStatus;
(function (TwoFactorAuthStatus) {
    TwoFactorAuthStatus["disabled"] = "disabled";
    TwoFactorAuthStatus["enabled"] = "enabled";
    TwoFactorAuthStatus["pending"] = "pending";
})(TwoFactorAuthStatus || (TwoFactorAuthStatus = {}));
export class User extends Model {
    constructor() {
        super(...arguments);
        this.email = '';
        this.roles = [];
        this.verified = false;
        this.twoFactorAuth = {
            type: '',
            status: TwoFactorAuthStatus.disabled,
        };
        this.data = {};
    }
}
