import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { CBelt } from 'src/app/models/cBelt.model';

import { AlertService } from 'src/app/shared/services/alert/alert.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';
import { BeltsService } from 'src/app/services/c-belt/belts.service';

@Component({
  selector: 'app-c-belt-configuration',
  templateUrl: './c-belt-configuration.component.html',
  styleUrls: ['./c-belt-configuration.component.scss'],
})
export class CBeltConfigurationComponent implements OnInit {
  public belt: CBelt = new CBelt();
  public isRndUser: boolean = false;
  public current: ConfigurationState = null;

  public constructor(
    private store: Store,
    private router: Router,
    private cBeltService: BeltsService,
    private loadingService: LoadingService,
    private alertService: AlertService
  ) {}

  public ngOnInit(): void {
    this.subscribeToCurrentConfiguration();
  }

  public subscribeToCurrentConfiguration(): void {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.isRndUser = this.current.isRndUser;
        if (this.current.belt) {
          this.belt = cloneDeep(this.current.belt);
          this.belt.length = this.belt.length * 1000;
          this.belt.width = this.belt.width * 1000;
        }
      });
  }

  public async openScanningPage(): Promise<void> {
    if (!this.isValid()) {
      this.alertService.alertError('Please fill in fields to continue');
      return;
    }
    const res = await this.fetchAndUpdateBeltSalesLocationCode();
    if (!res.success) return;
    this.updateBeltState();
    this.router.navigate(['fitting', 'scanning', this.belt.orderNumber]);
  }

  public async openManualEntryPage(): Promise<void> {
    if (!this.isValid()) {
      this.alertService.alertError('Please fill in fields to continue');
      return;
    }
    const res = await this.fetchAndUpdateBeltSalesLocationCode();
    if (!res.success) return;
    this.updateBeltState();
    this.router.navigate(['fitting', 'scanning', this.belt.orderNumber, 'add-manually']);
  }

  private async fetchAndUpdateBeltSalesLocationCode(): Promise<{ success: boolean }> {
    if (this.isRndUser) {
      this.belt.salesLocationCode = 'DEMO';
      return { success: true };
    }

    this.loadingService.show();
    let success = false;
    try {
      const response = await this.cBeltService.getSalesLocationCode(this.belt.orderNumber);
      this.belt.width = Number(response.width) / 1000;
      this.belt.length = Number(response.length) / 1000;
      this.belt.cpr = response.cpr;
      this.belt.salesLocationCode = response.salesLocationCode;
      success = true;
    } catch (err) {
      if (err.status == 404) {
        this.alertService.alertError('Belt Item Reference not found on AMMcare');
      } else {
        this.alertService.handleError(err);
      }
    }
    this.loadingService.hide();
    return { success };
  }

  public navToFittingHome(): void {
    this.router.navigate(['c-belt']);
  }

  public updateBeltState(): void {
    if (this.isRndUser) {
      this.belt.width = this.belt.width / 1000;
      this.belt.length = this.belt.length / 1000;
    }
    this.store.dispatch(InstallationActions.setCurrentBelt({ belt: this.belt }));
    this.store.dispatch(InstallationActions.updateBelt({ belt: this.belt }));
  }

  public isValid(): boolean {
    if (!this.isRndUser) {
      return !!this.belt.orderNumber;
    } else {
      return !!this.belt.orderNumber && !!this.belt.width && !!this.belt.length;
    }
  }

  public toggleRndUser(event: { target: { checked: boolean } }): void {
    this.isRndUser = event.target.checked;
    this.store.dispatch(InstallationActions.updateIsRndUserStatus({ isRndUser: this.isRndUser }));
  }

  public onBackPressed(): void {
    this.store.dispatch(InstallationActions.setCurrentBelt({ belt: null }));
    this.store.dispatch(InstallationActions.updateIsRndUserStatus({ isRndUser: false }));
  }
}
