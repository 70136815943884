import { Injectable } from '@angular/core';

export interface Model {
  id?: string;
  created?: Date;
  updated?: Date;
  deleted?: boolean;
  dirty: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class StateUtils {
  public static combineStateArr<T extends Model>(stateItems: T[], incomingItems: T[]): T[] {
    if (!incomingItems?.length) return [...stateItems];

    const result: T[] = [];
    const incomingItemMap: { [id: string]: T } = {};
    const stateItemMap: { [id: string]: boolean } = {};

    for (const item of incomingItems) {
      if (!item) continue;
      incomingItemMap[item.id] = item;
    }

    for (const stateItem of stateItems) {
      const incomingItem = incomingItemMap[stateItem.id];
      if (incomingItem) {
        result.push(this.selectItemUsingMergeLogic(stateItem, incomingItem));
      } else {
        result.push(stateItem);
      }
      stateItemMap[stateItem.id] = true;
    }

    for (const item of incomingItems) {
      if (!stateItemMap[item.id]) result.push(item);
    }

    return result;
  }

  public static selectItemUsingMergeLogic<T extends Model>(stateItem: T, incomingItem: T): T {
    if (incomingItem.dirty) return incomingItem;

    if (!incomingItem.dirty && stateItem.dirty) {
      const incomingDate = new Date(incomingItem.updated);
      const stateDate = new Date(stateItem.updated);
      stateDate.setMilliseconds(0);
      incomingDate.setMilliseconds(0);
      if (incomingDate >= stateDate) return incomingItem;
      return stateItem;
    }

    return incomingItem;
  }

  public static combineState<T extends Model>(existingItems: T[], newItem: T): T[] {
    if (!newItem) return [...existingItems];

    existingItems = [...existingItems];
    if (existingItems.find((x) => x.id == newItem.id)) {
      existingItems = existingItems.map((x) => (x.id == newItem.id ? newItem : x));
    } else {
      existingItems.push(newItem);
    }

    return existingItems;
  }
}
