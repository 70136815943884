<div class="mobile user-select-none" *ngIf="showHamburgerMenu()">
  <div
    class="menu-toggle pointer"
    data-testid="menu"
    [class.active]="isSidebarOpen"
    (click)="toggleSidebar()">
    <i class="fa" [ngClass]="isSidebarOpen ? 'fa-times' : 'fa-bars'"></i>
  </div>

  <div class="shade" [@fadeInOut] *ngIf="isSidebarOpen" (click)="toggleSidebar()"></div>

  <div
    class="sidebar position-fixed"
    [class.acceptance]="!isProduction"
    [@slideInOut]
    *ngIf="isSidebarOpen"
  >
    <app-nav [isProduction]="isProduction" (closeSidebar)="toggleSidebar()"></app-nav>
  </div>
</div>

<div class="desktop sidebar" [class.acceptance]="!isProduction" *ngIf="!isScreenSmall">
  <app-nav [isProduction]="isProduction" (closeSidebar)="toggleSidebar()"></app-nav>
</div>
