class Utils {
    isTokenExpired(token, offsetSeconds = 0) {
        const expirationDate = this.getJWTExpirationDate(token);
        if (!expirationDate) {
            return false;
        }
        return !(expirationDate.valueOf() >
            new Date().valueOf() + offsetSeconds * 1000);
    }
    decodeToken(token) {
        const parts = token.split('.');
        if (parts.length !== 3) {
            throw new Error('Invalid JWT Token, expecting 3 parts');
        }
        const decoded = this.urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error('Cannot decode the token');
        }
        return this.deepFreeze(JSON.parse(decoded));
    }
    deepFreeze(obj) {
        Object.keys(obj).forEach((prop) => {
            if (typeof obj[prop] === 'object' && obj[prop] !== null) {
                this.deepFreeze(obj[prop]);
            }
        });
        return Object.freeze(obj);
    }
    urlBase64Decode(input) {
        let output = input.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                throw new Error('Illegal base64url string!');
        }
        return window.decodeURIComponent(escape(window.atob(output)));
    }
    getJWTExpirationDate(token) {
        const decoded = this.decodeToken(token);
        if (typeof decoded.exp === 'undefined') {
            return null;
        }
        const expirationDate = new Date(0);
        expirationDate.setUTCSeconds(decoded.exp);
        return expirationDate;
    }
    pathJoin(...parts) {
        const separator = '/';
        const replace = new RegExp(separator + '{1,}', 'g');
        return parts
            .join(separator)
            .replace(replace, separator)
            .replace('http:/', 'http://')
            .replace('https:/', 'https://');
    }
    sleep(milliseconds) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }
}
const utils = new Utils();
export { utils };
