export class Conveyor {
  public id: string;

  public cpr: string;
  public machineId: string;
  public aliasName: string;
  public description: string;
  public diameter: number;
  public idleDrumDiameter: number;
  public customerNumber: string;

  public static fromJson(json: {
    id: string;
    cpr: string;
    machineId: string;
    aliasName: string;
    description: string;
    diameter: number;
    idleDrumDiameter: number;
    customer;
  }): Conveyor {
    const conveyor = new Conveyor();
    conveyor.id = json.id;
    conveyor.diameter = json.diameter;
    conveyor.idleDrumDiameter = json.idleDrumDiameter;
    conveyor.machineId = json.machineId;
    conveyor.aliasName = json.aliasName;
    conveyor.description = json.description;
    return conveyor;
  }
}
