<div class="mobile" *ngIf="conveyor">
  <app-mobile-header
    [header]="returnTitle()"
    [routerLinkUrl]="['/installing', companyId, 'system-setup-sensors']"
  >
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <form name="configuration-form" class="d-flex">
      <div class="bg-white card p-3">
        <label for="newDevice" class="form-label">Add a new sensor</label>
        <button type="button" class="btn btn-primary" (click)="navToAddSensor()">Pair</button>

        <div class="mt-5">Existing Sensors</div>
        <hr />
        <div *ngIf="sensors.length">
          <div
            class="d-flex align-items-center justify-content-between my-1"
            *ngFor="let sensor of sensors"
          >
            <div>
              {{ sensor.sensorNickname ? sensor.sensorNickname : returnSensorType(sensor.sid) }}
            </div>
            <button type="button" class="btn btn-primary ms-3" (click)="navToEditSensor(sensor)">
              Edit
            </button>
          </div>
        </div>
        <div *ngIf="!sensors.length">No Sensors for {{ conveyor.aliasName }}</div>
      </div>
    </form>
  </div>
</div>
