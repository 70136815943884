<router-outlet *ngIf="!loading && !startupError"></router-outlet>

<div
  *ngIf="loading"
  class="d-flex flex-column center">
  <div class="spinner-border text-primary"></div>
  <div class="mt-1">{{ initializingProgressMessage }}</div>
</div>

<div
  *ngIf="startupError"
  class="d-flex flex-column center">
  <h3 class="text-muted">Initialisation Failed</h3>
  <p>
    Please try reload the page<br />
    If you see this message again after reloading the page, please contact support.
  </p>

  <button
    class="btn btn-primary"
    (click)="onReloadPageClicked()">
    {{ t.translate("_RELOAD_PAGE") }}
  </button>
</div>
