import { Injectable } from '@angular/core';

import { Conveyor } from 'src/app/models/conveyor.model';

import { CBeltApiService } from '../api/cBeltApi.service';

@Injectable({ providedIn: 'root' })
export class ConveyorsService {
  public constructor(private cBeltApiService: CBeltApiService) {}

  public async getConveyors(rfidReaderId: string): Promise<Conveyor[]> {
    const options = { params: { rfidReaderId: rfidReaderId, pageSize: 1 } };
    const response = await this.cBeltApiService.get('/conveyors', options);
    return response['result']['items'].map((x) => Conveyor.fromJson(x));
  }

  public async getConveyorById(id: string): Promise<Conveyor> {
    const response = await this.cBeltApiService.get(`/conveyors/${id}`);
    return Conveyor.fromJson(response['result']);
  }

  public async addConveyor(conveyor: Conveyor): Promise<Conveyor> {
    return await this.cBeltApiService.post('/conveyors', conveyor);
  }

  public async updateConveyor(conveyor: Conveyor): Promise<Conveyor> {
    return await this.cBeltApiService.put(`/conveyors/${conveyor.id}`, conveyor);
  }
}
