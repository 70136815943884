import { createAction, props } from '@ngrx/store';
import { Customer } from '../../models/customer.model';


export const resetCustomerState = createAction(
  'Reset Customer State'
);

export const fetchCustomers = createAction(
  '[Customer API] Fetch Customers Initiated'
);
export const fetchCustomersSuccess = createAction(
  '[Customer API] Fetch Customers Success',
  props<{ customers: Array<Customer> }>()
);
export const fetchCustomersFailed = createAction(
  '[Customer API] Fetch Customers Failed',
  props<{ error: Error }>()
);

export const updateCustomersSuccess = createAction(
  '[Belt API] Update Customers Success',
  props<{ customers: Customer[] }>()
);

export const setCustomersSuccess = createAction(
  '[Belt API] Set Customers Success',
  props<{ customers: Customer[] }>()
);

export const setCurrentCustomer = createAction(
  '[Customer] Set Current Customer',
  props<{ customer: Customer }>()
);

export const addCustomerSuccess = createAction(
  '[Customer] Add Customer Success',
  props<{ customer: Customer }>()
);
