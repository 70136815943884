/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CscState } from './csc.state';

export const selectCscs = createFeatureSelector<CscState>('csc');

export const selectAllCscs = createSelector(selectCscs, (state) => state.cscs);

export const selectCscById = (props: { id: string }) =>
  createSelector(selectAllCscs, (cscs) => cscs.find((csc) => csc.id == props.id));
