var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { utils } from '../../shared/utils';
import { AppstraxServices } from '../..';
import { preferences } from '../preferences';
import { http } from '../../shared/http';
import { AuthResult, AuthStatus } from '../models/auth_result';
export class AuthService {
    constructor() {
        this.user = null;
        this.tokens = null;
        this.loading = false;
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            const tokens = preferences.getTokens();
            if (tokens) {
                this.tokens = tokens;
                yield this.validateTokens();
                this.onAuthStateChanged(this.tokens);
            }
        });
    }
    validateTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            while (this.loading) {
                yield utils.sleep(50);
            }
            if (!this.tokens) {
                return;
            }
            this.loading = true;
            try {
                if (utils.isTokenExpired(this.tokens.token)) {
                    if (this.canRefreshTokens()) {
                        yield this.refreshTokens();
                    }
                    else {
                        yield this.logout();
                    }
                }
            }
            catch (err) {
                this.onAuthStateChanged(null);
                console.log(err);
            }
            this.loading = false;
        });
    }
    canRefreshTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            return (preferences.canRefreshToken() &&
                this.tokens.refreshToken &&
                !utils.isTokenExpired(this.tokens.refreshToken));
        });
    }
    onAuthStateChanged(tokens) {
        this.tokens = tokens;
        preferences.setTokens(tokens);
        if (tokens) {
            const decoded = utils.decodeToken(tokens.token);
            if (decoded.id) {
                this.user = decoded;
            }
            else {
                this.user = null;
            }
        }
        else {
            this.user = null;
        }
    }
    refreshTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const refreshToken = this.tokens.refreshToken;
                const tokens = yield this.post(AppstraxServices.getAuthUrl('refresh-token'), { refreshToken });
                yield this.onAuthStateChanged(tokens);
            }
            catch (err) {
                yield this.logout();
            }
        });
    }
    register(registerDto) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (yield this.isAuthenticated()) {
                    yield this.logout();
                }
                const tokens = yield this.post(AppstraxServices.getAuthUrl('register'), registerDto);
                yield this.onAuthStateChanged(tokens);
                return AuthResult.authenticated(this.user);
            }
            catch (err) {
                yield this.logout();
                throw err;
            }
        });
    }
    login(loginDto) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (yield this.isAuthenticated()) {
                    yield this.logout();
                }
                const tokens = yield this.post(AppstraxServices.getAuthUrl('login'), loginDto);
                preferences.setCanRefreshToken((_a = loginDto.remember) !== null && _a !== void 0 ? _a : true);
                yield this.onAuthStateChanged(tokens);
                if (this.user) {
                    return AuthResult.authenticated(this.user);
                }
                else if (this.tokens) {
                    return AuthResult.requireTwoFactorAuthCode();
                }
            }
            catch (err) {
                yield this.logout();
                throw err;
            }
        });
    }
    verifyTwoFactorAuthCode(code) {
        return __awaiter(this, void 0, void 0, function* () {
            if (utils.isTokenExpired(this.tokens.token)) {
                yield this.logout();
                throw new Error('Your token has expired');
            }
            if (this.tokens && this.user) {
                throw new Error('User already authenticated');
            }
            const tokens = yield this.post(AppstraxServices.getAuthUrl('verify-2fa'), { code }, false);
            yield this.onAuthStateChanged(tokens);
            return this.user;
        });
    }
    forgotPassword(forgotDto) {
        return this.post(AppstraxServices.getAuthUrl('forgot-password'), forgotDto);
    }
    resetPassword(resetDto) {
        return this.post(AppstraxServices.getAuthUrl('reset-password'), resetDto);
    }
    changePassword(changePasswordDto) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.isAuthenticated())) {
                throw new Error('User not authenticated');
            }
            return yield this.post(AppstraxServices.getUserUrl('change-password'), changePasswordDto, false);
        });
    }
    saveUserData(data) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.isAuthenticated())) {
                throw new Error('User not authenticated');
            }
            const tokens = yield this.post(AppstraxServices.getUserUrl('data'), data, false);
            yield this.onAuthStateChanged(tokens);
            return this.user;
        });
    }
    sendEmailVerificationCode() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.isAuthenticated())) {
                throw new Error('User not authenticated');
            }
            yield this.post(AppstraxServices.getUserUrl('send-verification-email'), {}, false);
        });
    }
    verifyEmailAddress(code) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.isAuthenticated())) {
                throw new Error('User not authenticated');
            }
            const tokens = yield this.post(AppstraxServices.getUserUrl('verify-email'), { code }, false);
            yield this.onAuthStateChanged(tokens);
            return this.user;
        });
    }
    generateTwoFactorAuthSecret() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.isAuthenticated())) {
                throw new Error('User not authenticated');
            }
            const response = yield this.post(AppstraxServices.getUserUrl('generate-2fa-secret'), {}, false);
            yield this.onAuthStateChanged(response.tokens);
            return {
                secret: response.secret,
                qrCode: response.qr,
            };
        });
    }
    enableTwoFactorAuth(code) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.isAuthenticated())) {
                throw new Error('User not authenticated');
            }
            const tokens = yield this.post(AppstraxServices.getUserUrl('enable-2fa'), { code }, false);
            yield this.onAuthStateChanged(tokens);
            return this.user;
        });
    }
    disableTwoFactorAuthentication() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.isAuthenticated())) {
                throw new Error('User not authenticated');
            }
            const tokens = yield this.post(AppstraxServices.getUserUrl('disable-2fa'), {}, false);
            yield this.onAuthStateChanged(tokens);
            return this.user;
        });
    }
    isAuthenticated() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.validateTokens();
            return this.user != null;
        });
    }
    getAuthTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.validateTokens();
            return this.tokens || null;
        });
    }
    getUser() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.validateTokens();
            return this.user;
        });
    }
    getAuthStatus() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.validateTokens();
            if (this.user) {
                return AuthStatus.authenticated;
            }
            if (this.tokens && !this.user) {
                return AuthStatus.pendingTwoFactorAuthCode;
            }
            return AuthStatus.notAuthenticated;
        });
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.post(AppstraxServices.getAuthUrl('logout'), {});
            this.onAuthStateChanged(null);
        });
    }
    post(url, body = {}, ignoreExpiredToken = true) {
        return http.post(url, body, { ignoreExpiredToken });
    }
}
