import { Injectable } from '@angular/core';

import { IAlert } from './alert.interface';

@Injectable()
export class AlertService {
  public listener: IAlert;

  public subscribeToAlerts(listener: IAlert): void {
    this.listener = listener;
  }

  public alertSuccess(text: string): void {
    if (!this.listener) {
      return;
    }
    this.listener.onSuccessAlert(text);
  }

  public alertError(text: string): void {
    if (!this.listener) {
      return;
    }
    this.listener.onErrorAlert(text);
  }

  public handleError(err: any): void {
    console.log(err);
    let message = err?.error?.error?.message ?? '';
    if (!message) message = err?.error?.message ?? '';
    if (message) {
      this.listener.onErrorAlert(message);
    } else {
      this.listener.onErrorAlert(JSON.stringify(err));
    }
  }

  public alertWarning(text: string): void {
    if (!this.listener) {
      return;
    }
    this.listener.onWarningAlert(text);
  }

  public alertInfo(text: string): void {
    if (!this.listener) {
      return;
    }
    this.listener.onInfoAlert(text);
  }

  public handleHttpError(err): void {
    console.error('handleHttpError err', err);

    if (err.error && typeof err.error == 'string') {
      this.alertError(err.error);
    } else if (
      err.message &&
      typeof err.message == 'string' &&
      err.message == 'Invalid token specified'
    ) {
      this.alertError(
        'There is a new app release! Please log out and login again to get the latest features'
      );
    } else if (err.message && typeof err.message == 'string') {
      console.log(err.message);
    } else {
      console.log(err);
    }
  }
}
