import { Model } from '../state/state-utils';

export class Customer implements Model {
  public id: string;
  public created: Date = new Date();
  public updated: Date = new Date();
  public createdBy: string;
  public updatedBy: string;

  public deleted: boolean;

  public customer_name: string;
  public customer_no: string;

  public cscId: string;
  public userCount: number;
  public customerNumber: string;
  public logo_url: string;
  public hasAmmcareConnect: boolean;
  public dirty: boolean = true;

  public static fromJson(json: {
    id: string;
    customer_name: string;
    customerNumber: string;
  }): Customer {
    const customer = new Customer();
    customer.id = json.id;
    customer.customer_name = json.customer_name;
    customer.customerNumber = json.customerNumber;
    return customer;
  }

  public static fromServer(json: { id: string; name: string; customerNumber: string }): Customer {
    const customer = new Customer();
    customer.id = json.id;
    customer.customer_name = json.name;
    customer.customerNumber = json.customerNumber;
    return customer;
  }
}
