<div class="mobile">
  <app-mobile-header
    [header]="'Select the customer'"
    [routerLinkUrl]="['/c-belt']">
  </app-mobile-header>

  <div class="mx-3">
    <div class="d-flex align-items-center my-3">
      <app-search-input
        class="col"
        [(searchText)]="searchText"
        (searchTextChange)="filterClients()">
      </app-search-input>
    </div>

    <table
      class="table table-hover user-select-none border-10"
      *ngIf="customers">
      <thead>
        <tr>
          <th>Customer Name</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let customer of customers"
          (click)="navTo(customer.customerNumber, customer)"
          class="pointer">
          <td>
            {{ customer.customer_name }}
          </td>
        </tr>
        <tr *ngIf="!customers.length">
          <td colspan="6">No customers found.</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
