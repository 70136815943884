<div class="desktop">
  <app-desktop-header
    [header]="t.translate('_CHANGE_LANGUAGE')"
    [showBackButton]="false">
  </app-desktop-header>
  <div class="mx-lg-5 d-flex justify-content-between flex-column mt-lg-7">
    <div class="bg-white rounded border w-100 py-1 mt-4 mb-5">
      <ng-container *ngFor="let language of languages">
        <div
          class="language d-flex align-items-center justify-content-between p-4 cursor-pointer"
          (click)="onSelectLanguage(language.key)">
          <div class="d-flex align-items-center">
            <span
              class="border icon fi fis me-4"
              [class]="'fi-' + language.country_flag">
            </span>
            <h5 class="mb-0">{{ language.title }}</h5>
          </div>
          <input
            [checked]="languageKey == language.key"
            class="cursor-pointer"
            type="radio" />
        </div>
        <hr
          *ngIf="language.id != languages[languages.length - 1].id"
          class="m-0" />
      </ng-container>
    </div>
  </div>
</div>

<div class="mobile">
  <app-mobile-header
    [header]="t.translate('_CHANGE_LANGUAGE')"
    [routerLinkUrl]="['/surveys']">
  </app-mobile-header>
  <div class="mx-2 d-flex justify-content-between flex-column">
    <div class="bg-white rounded border w-100 py-1 mt-4 mb-5">
      <ng-container *ngFor="let language of languages">
        <div
          class="language d-flex align-items-center justify-content-between p-4 cursor-pointer"
          (click)="onSelectLanguage(language.key)">
          <div class="d-flex align-items-center">
            <span
              class="border icon fi fis me-4"
              [class]="'fi-' + language.country_flag">
            </span>
            <h5 class="mb-0">{{ language.title }}</h5>
          </div>
          <input
            [checked]="languageKey == language.key"
            class="cursor-pointer"
            type="radio" />
        </div>
        <hr
          *ngIf="language.id != languages[languages.length - 1].id"
          class="m-0" />
      </ng-container>
    </div>
  </div>
</div>
