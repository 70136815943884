export class Sensor {
  public id: string;

  public conveyorId: string;
  public uniqueId: number;
  public sensorNickname: string;
  public aliasName: string;
  public sensorType: string;
  public gatewayDeviceId: string;
  public description: string;
  public sid: string;
  public edid: string;
  public role: string;

  public static fromJson(json: {
    id: string;
    conveyorId: string;
    aliasName: string;
    description: string;
    sid: string;
    edid: string;
    role: string;
  }): Sensor {
    const sensor = new Sensor();
    sensor.id = json.id;
    sensor.conveyorId = json.conveyorId;
    sensor.sensorNickname = json.aliasName;
    sensor.description = json.description;
    sensor.sid = json.sid;
    sensor.edid = json.edid;
    sensor.role = json.role;
    return sensor;
  }
}

export interface UpdateSensorPayload {
  conveyorId: string;
  aliasName: string;
  description: string;
}
