import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import { appInit } from '../core.actions';
import * as InstallationActions from './installation.actions';
import { StateService } from 'src/app/services/c-belt/state.service';
import { Action } from '@ngrx/store';

@Injectable()
export class InstallationEffects {
  public constructor(
    private actions$: Actions<Action>,
    private stateService: StateService,
  ) {}

  public appInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInit),
      map(() => {
        const state = this.stateService.loadCurrentFromLocalStorage();
        return InstallationActions.setCurrentState({ current: state });
      }),
    ),
  );
}
