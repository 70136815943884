import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {
  public static cannotBeWhitespace(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    if ((control.value as string).trim().length == 0) {
      return { whiteSpaceOnly: true };
    }

    return null;
  }
}
