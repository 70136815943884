export class CBelt {
  public id: string;

  public cpr: string;
  public width: number;
  public length: number;
  public rfidTagId: string;
  public conveyorId: string;
  public orderNumber: string;
  public salesLocationCode: string;

  public static fromJson(json: {
    id: string;
    cpr: string;
    width: number;
    length: number;
    rfidTagId: string;
    conveyorId: string;
    orderNumber: string;
    salesLocationCode: string;
  }): CBelt {
    const belt = new CBelt();
    belt.id = json.id;
    belt.orderNumber = json.orderNumber;
    belt.conveyorId = json.conveyorId;
    belt.rfidTagId = json.rfidTagId;
    belt.width = json.width;
    belt.length = json.length;
    belt.cpr = json.cpr ?? '';
    belt.salesLocationCode = json.salesLocationCode ?? '';
    return belt;
  }
}
