import { Injectable } from '@angular/core';

import { fromEvent, Observable, Subscriber, Subscription } from 'rxjs';

import { AmmscanApiService } from '../api/ammscanApi.service';
import { UtilService } from './../../utils/util.service';

@Injectable()
export class OnlineService {
  private online: boolean = false;
  private apiPollingInterval: NodeJS.Timeout;

  private online$: Observable<boolean>;
  private subscribers: Subscriber<boolean>[] = [];
  private fetchedAt: Date;

  constructor(private ammscanApiService: AmmscanApiService, private utils: UtilService) {
    if (window.navigator.onLine) {
      this.startOnlinePolling();
    }

    fromEvent(window, 'online').subscribe(async () => {
      this.startOnlinePolling();
    });

    fromEvent(window, 'offline').subscribe(() => {
      this.notifySubscribersIfOnlineHasChanged(false);
      clearInterval(this.apiPollingInterval);
      this.apiPollingInterval = null;
    });

    this.online$ = new Observable<boolean>((subscriber) => {
      subscriber.next(this.online);
      this.subscribers.push(subscriber);
    });
  }

  private startOnlinePolling(): void {
    this.updateOnlineStatus();

    if (this.apiPollingInterval) {
      clearInterval(this.apiPollingInterval);
    }

    this.apiPollingInterval = setInterval(() => this.updateOnlineStatus(), 10000);
  }

  private async updateOnlineStatus() {
    const online = await this.canConnectToApi();
    this.notifySubscribersIfOnlineHasChanged(online);
  }

  private async canConnectToApi(): Promise<boolean> {
    try {
      await this.ammscanApiService.get('/sync/connect', {});
      this.fetchedAt = new Date();
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  public subscribe(callback: (online: boolean) => void): Subscription {
    return this.online$.subscribe(callback);
  }

  private notifySubscribersIfOnlineHasChanged(online: boolean): void {
    if (this.online == online) return;

    if (!this.online && online) {
      setTimeout(() => this.startOnlinePolling(), 1000);
    }

    this.online = online;

    this.removeInactiveSubscribers();
    for (const subscribers of this.subscribers) {
      subscribers.next(online);
    }
  }

  private removeInactiveSubscribers(): void {
    this.subscribers = this.subscribers.filter((x) => !x.closed);
  }

  public async isOnline(): Promise<boolean> {
    let count = 0;
    while (!this.fetchedAt && window.navigator.onLine && count < 5) {
      await this.utils.sleep(100);
      count++;
    }
    return this.online;
  }
}
