var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios, { AxiosHeaders } from 'axios';
import { appstraxAuth } from '../../auth';
import { preferences } from '../../auth/preferences';
import { AppstraxServices } from '../..';
export class HttpService {
    get(url, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getHeaders(options);
            const response = yield axios.get(url, { headers });
            return response.data;
        });
    }
    post(url, body = {}, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getHeaders(options);
            const response = yield axios.post(url, body, { headers });
            return response.data;
        });
    }
    put(url, body = {}, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getHeaders(options);
            const response = yield axios.put(url, body, { headers });
            return response.data;
        });
    }
    patch(url, body = {}, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getHeaders(options);
            const response = yield axios.patch(url, body, { headers });
            return response.data;
        });
    }
    delete(url, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getHeaders(options);
            const response = yield axios.delete(url, { headers });
            return response.data;
        });
    }
    uploadFile(url, file, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const body = new FormData();
            body.append('file', file);
            const headers = yield this.getHeaders(options, true);
            const response = yield axios.post(url, body, {
                headers,
                onUploadProgress: (e) => {
                    if (options.onUploadProgress) {
                        options.onUploadProgress({
                            total: e.total,
                            loaded: e.loaded,
                            percent: e.progress,
                        });
                    }
                },
            });
            return response.data;
        });
    }
    getHeaders(options, isFile) {
        return __awaiter(this, void 0, void 0, function* () {
            let headers = { 'Access-Control-Allow-Origin': '*' };
            headers = Object.assign(headers, (options === null || options === void 0 ? void 0 : options.headers) || {});
            if (!isFile) {
                headers['Content-Type'] = 'application/json';
            }
            if (!AppstraxServices.hasAuthUrl()) {
                return new AxiosHeaders(headers);
            }
            let token;
            if (options === null || options === void 0 ? void 0 : options.ignoreExpiredToken) {
                const tokens = yield preferences.getTokens();
                if (tokens)
                    token = tokens.token;
            }
            else {
                // this call returns a refreshed the token if expired
                const tokens = yield appstraxAuth.getAuthTokens();
                if (tokens)
                    token = tokens.token;
            }
            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }
            return new AxiosHeaders(headers);
        });
    }
}
