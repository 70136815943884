import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { State } from './core.state';

import * as BeltReducer from './belt/belt.reducer';
import * as SurveyReducer from './survey/survey.reducer';
import * as CustomerReducer from './customer/customer.reducer';
import * as CscReducer from './csc/csc.reducer';
import * as UserReducer from './user/user.reducer';
import * as ConfigurationReducer from './installation/installation.reducer';

export const reducers: ActionReducerMap<State> = {
  belt: BeltReducer.reducer,
  survey: SurveyReducer.reducer,
  customer: CustomerReducer.reducer,
  csc: CscReducer.reducer,
  user: UserReducer.reducer,
  installation: ConfigurationReducer.reducer,
};

export const metaReducers: MetaReducer<State>[] = [];
