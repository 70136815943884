import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Customer } from 'src/app/models/customer.model';

import { StateService } from 'src/app/services/c-belt/state.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-c-belt-installation',
  templateUrl: './c-belt-installation.component.html',
  styleUrls: ['./c-belt-installation.component.scss'],
})
export class CBeltInstallationComponent implements OnInit {
  public editing: boolean;
  public companyId: string;
  public customer: Customer;
  public current: ConfigurationState = null;
  public containsRfidReader: boolean = true;
  public loading: boolean = false;

  public constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateService,
    private alertService: AlertService,
  ) {}

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  public toggleRfidReader(event: { target: { checked: boolean } }): void {
    this.containsRfidReader = event.target.checked;
    this.store.dispatch(
      InstallationActions.updateContainsRfidStatus({
        containsRfidReader: this.containsRfidReader,
      }),
    );
  }

  public subscribeToCurrentConfiguration(): void {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.customer = this.current.company;
        this.containsRfidReader = this.current.containsRfidReader;
        this.editing = this.current.editing;
      });
  }

  public returnTitle(): string {
    return `CBLT - ${this.customer.customer_name}`;
  }

  public navToConfigurationOverview(): void {
    this.stateService.subscribeToCurrentAndSave();
    this.router.navigate(['/installing', this.companyId, 'configuration-overview']);
  }

  public navToConveyorCalibration(): void {
    if (!this.current.conveyors.length) {
      this.alertService.alertError('Must have a Conveyor Setup first');
      return;
    }
    this.router.navigate(['/installing', this.companyId, 'calibrate-conveyor']);
  }

  public navToSensorConfiguration(): void {
    if (!this.current.conveyors.length) {
      this.alertService.alertError('Must have a Conveyor Setup first');
      return;
    }
    if (!this.current.gateway) {
      this.alertService.alertError('Must have a Gateway Configured first');
      return;
    }
    this.router.navigate(['/installing', this.companyId, 'system-setup-sensors']);
  }
}
