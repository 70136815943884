import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova, cordovaPropertyGet, cordovaPropertySet } from '@awesome-cordova-plugins/core';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
var NFC = /** @class */function (_super) {
  __extends(NFC, _super);
  function NFC() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  NFC.prototype.readerMode = function (flags) {
    return cordova(this, "readerMode", {
      "observable": true,
      "clearFunction": "disableReaderMode",
      "clearWithArgs": false
    }, arguments);
  };
  NFC.prototype.scanNdef = function (options) {
    return cordova(this, "scanNdef", {
      "sync": true
    }, arguments);
  };
  NFC.prototype.scanTag = function (options) {
    return cordova(this, "scanTag", {
      "sync": true
    }, arguments);
  };
  NFC.prototype.cancelScan = function () {
    return cordova(this, "cancelScan", {
      "sync": true
    }, arguments);
  };
  NFC.prototype.connect = function (tech, timeout) {
    return cordova(this, "connect", {
      "sync": true
    }, arguments);
  };
  NFC.prototype.close = function () {
    return cordova(this, "close", {
      "sync": true
    }, arguments);
  };
  NFC.prototype.transceive = function (data) {
    return cordova(this, "transceive", {
      "sync": true
    }, arguments);
  };
  NFC.prototype.beginSession = function (onSuccess, onFailure) {
    return cordova(this, "beginSession", {
      "observable": true,
      "successIndex": 0,
      "errorIndex": 3,
      "clearFunction": "invalidateSession",
      "clearWithArgs": true
    }, arguments);
  };
  NFC.prototype.addNdefListener = function (onSuccess, onFailure) {
    return cordova(this, "addNdefListener", {
      "observable": true,
      "successIndex": 0,
      "errorIndex": 3,
      "clearFunction": "removeNdefListener",
      "clearWithArgs": true
    }, arguments);
  };
  NFC.prototype.addTagDiscoveredListener = function (onSuccess, onFailure) {
    return cordova(this, "addTagDiscoveredListener", {
      "observable": true,
      "successIndex": 0,
      "errorIndex": 3,
      "clearFunction": "removeTagDiscoveredListener",
      "clearWithArgs": true
    }, arguments);
  };
  NFC.prototype.addMimeTypeListener = function (mimeType, onSuccess, onFailure) {
    return cordova(this, "addMimeTypeListener", {
      "observable": true,
      "successIndex": 1,
      "errorIndex": 4,
      "clearFunction": "removeMimeTypeListener",
      "clearWithArgs": true
    }, arguments);
  };
  NFC.prototype.addNdefFormatableListener = function (onSuccess, onFailure) {
    return cordova(this, "addNdefFormatableListener", {
      "observable": true,
      "successIndex": 0,
      "errorIndex": 3
    }, arguments);
  };
  NFC.prototype.write = function (message) {
    return cordova(this, "write", {}, arguments);
  };
  NFC.prototype.makeReadOnly = function () {
    return cordova(this, "makeReadOnly", {}, arguments);
  };
  NFC.prototype.share = function (message) {
    return cordova(this, "share", {}, arguments);
  };
  NFC.prototype.unshare = function () {
    return cordova(this, "unshare", {}, arguments);
  };
  NFC.prototype.erase = function () {
    return cordova(this, "erase", {}, arguments);
  };
  NFC.prototype.handover = function (uris) {
    return cordova(this, "handover", {}, arguments);
  };
  NFC.prototype.stopHandover = function () {
    return cordova(this, "stopHandover", {}, arguments);
  };
  NFC.prototype.showSettings = function () {
    return cordova(this, "showSettings", {}, arguments);
  };
  NFC.prototype.enabled = function () {
    return cordova(this, "enabled", {}, arguments);
  };
  NFC.prototype.bytesToString = function (bytes) {
    return cordova(this, "bytesToString", {
      "sync": true
    }, arguments);
  };
  NFC.prototype.stringToBytes = function (str) {
    return cordova(this, "stringToBytes", {
      "sync": true
    }, arguments);
  };
  NFC.prototype.bytesToHexString = function (bytes) {
    return cordova(this, "bytesToHexString", {
      "sync": true
    }, arguments);
  };
  Object.defineProperty(NFC.prototype, "FLAG_READER_NFC_A", {
    get: function () {
      return cordovaPropertyGet(this, "FLAG_READER_NFC_A");
    },
    set: function (value) {
      cordovaPropertySet(this, "FLAG_READER_NFC_A", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(NFC.prototype, "FLAG_READER_NFC_B", {
    get: function () {
      return cordovaPropertyGet(this, "FLAG_READER_NFC_B");
    },
    set: function (value) {
      cordovaPropertySet(this, "FLAG_READER_NFC_B", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(NFC.prototype, "FLAG_READER_NFC_F", {
    get: function () {
      return cordovaPropertyGet(this, "FLAG_READER_NFC_F");
    },
    set: function (value) {
      cordovaPropertySet(this, "FLAG_READER_NFC_F", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(NFC.prototype, "FLAG_READER_NFC_V", {
    get: function () {
      return cordovaPropertyGet(this, "FLAG_READER_NFC_V");
    },
    set: function (value) {
      cordovaPropertySet(this, "FLAG_READER_NFC_V", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(NFC.prototype, "FLAG_READER_NFC_BARCODE", {
    get: function () {
      return cordovaPropertyGet(this, "FLAG_READER_NFC_BARCODE");
    },
    set: function (value) {
      cordovaPropertySet(this, "FLAG_READER_NFC_BARCODE", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(NFC.prototype, "FLAG_READER_SKIP_NDEF_CHECK", {
    get: function () {
      return cordovaPropertyGet(this, "FLAG_READER_SKIP_NDEF_CHECK");
    },
    set: function (value) {
      cordovaPropertySet(this, "FLAG_READER_SKIP_NDEF_CHECK", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(NFC.prototype, "FLAG_READER_NO_PLATFORM_SOUNDS", {
    get: function () {
      return cordovaPropertyGet(this, "FLAG_READER_NO_PLATFORM_SOUNDS");
    },
    set: function (value) {
      cordovaPropertySet(this, "FLAG_READER_NO_PLATFORM_SOUNDS", value);
    },
    enumerable: false,
    configurable: true
  });
  NFC.ɵfac = /* @__PURE__ */(() => {
    let ɵNFC_BaseFactory;
    return function NFC_Factory(t) {
      return (ɵNFC_BaseFactory || (ɵNFC_BaseFactory = i0.ɵɵgetInheritedFactory(NFC)))(t || NFC);
    };
  })();
  NFC.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NFC,
    factory: NFC.ɵfac
  });
  NFC.pluginName = "NFC";
  NFC.plugin = "phonegap-nfc";
  NFC.pluginRef = "nfc";
  NFC.repo = "https://github.com/chariotsolutions/phonegap-nfc";
  NFC.platforms = ["Android", "iOS", "Windows"];
  NFC = __decorate([], NFC);
  return NFC;
}(AwesomeCordovaNativePlugin);
export { NFC };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NFC, [{
    type: Injectable
  }], null, {
    FLAG_READER_NFC_A: [],
    FLAG_READER_NFC_B: [],
    FLAG_READER_NFC_F: [],
    FLAG_READER_NFC_V: [],
    FLAG_READER_NFC_BARCODE: [],
    FLAG_READER_SKIP_NDEF_CHECK: [],
    FLAG_READER_NO_PLATFORM_SOUNDS: [],
    readerMode: [],
    scanNdef: [],
    scanTag: [],
    cancelScan: [],
    connect: [],
    close: [],
    transceive: [],
    beginSession: [],
    addNdefListener: [],
    addTagDiscoveredListener: [],
    addMimeTypeListener: [],
    addNdefFormatableListener: [],
    write: [],
    makeReadOnly: [],
    share: [],
    unshare: [],
    erase: [],
    handover: [],
    stopHandover: [],
    showSettings: [],
    enabled: [],
    bytesToString: [],
    stringToBytes: [],
    bytesToHexString: []
  });
})();
var Ndef = /** @class */function (_super) {
  __extends(Ndef, _super);
  function Ndef() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Ndef.prototype.record = function (tnf, type, id, payload) {
    return cordova(this, "record", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.textRecord = function (text, languageCode, id) {
    return cordova(this, "textRecord", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.uriRecord = function (uri, id) {
    return cordova(this, "uriRecord", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.absoluteUriRecord = function (uri, payload, id) {
    return cordova(this, "absoluteUriRecord", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.mimeMediaRecord = function (mimeType, payload) {
    return cordova(this, "mimeMediaRecord", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.smartPoster = function (ndefRecords, id) {
    return cordova(this, "smartPoster", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.emptyRecord = function () {
    return cordova(this, "emptyRecord", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.androidApplicationRecord = function (packageName) {
    return cordova(this, "androidApplicationRecord", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.encodeMessage = function (ndefRecords) {
    return cordova(this, "encodeMessage", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.decodeMessage = function (bytes) {
    return cordova(this, "decodeMessage", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.decodeTnf = function (tnf_byte) {
    return cordova(this, "decodeTnf", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.encodeTnf = function (mb, me, cf, sr, il, tnf) {
    return cordova(this, "encodeTnf", {
      "sync": true
    }, arguments);
  };
  Ndef.prototype.tnfToString = function (tnf) {
    return cordova(this, "tnfToString", {
      "sync": true
    }, arguments);
  };
  Object.defineProperty(Ndef.prototype, "TNF_EMPTY", {
    get: function () {
      return cordovaPropertyGet(this, "TNF_EMPTY");
    },
    set: function (value) {
      cordovaPropertySet(this, "TNF_EMPTY", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Ndef.prototype, "TNF_WELL_KNOWN", {
    get: function () {
      return cordovaPropertyGet(this, "TNF_WELL_KNOWN");
    },
    set: function (value) {
      cordovaPropertySet(this, "TNF_WELL_KNOWN", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Ndef.prototype, "TNF_MIME_MEDIA", {
    get: function () {
      return cordovaPropertyGet(this, "TNF_MIME_MEDIA");
    },
    set: function (value) {
      cordovaPropertySet(this, "TNF_MIME_MEDIA", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Ndef.prototype, "TNF_ABSOLUTE_URI", {
    get: function () {
      return cordovaPropertyGet(this, "TNF_ABSOLUTE_URI");
    },
    set: function (value) {
      cordovaPropertySet(this, "TNF_ABSOLUTE_URI", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Ndef.prototype, "TNF_EXTERNAL_TYPE", {
    get: function () {
      return cordovaPropertyGet(this, "TNF_EXTERNAL_TYPE");
    },
    set: function (value) {
      cordovaPropertySet(this, "TNF_EXTERNAL_TYPE", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Ndef.prototype, "TNF_UNKNOWN", {
    get: function () {
      return cordovaPropertyGet(this, "TNF_UNKNOWN");
    },
    set: function (value) {
      cordovaPropertySet(this, "TNF_UNKNOWN", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Ndef.prototype, "TNF_UNCHANGED", {
    get: function () {
      return cordovaPropertyGet(this, "TNF_UNCHANGED");
    },
    set: function (value) {
      cordovaPropertySet(this, "TNF_UNCHANGED", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Ndef.prototype, "TNF_RESERVED", {
    get: function () {
      return cordovaPropertyGet(this, "TNF_RESERVED");
    },
    set: function (value) {
      cordovaPropertySet(this, "TNF_RESERVED", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Ndef.prototype, "textHelper", {
    get: function () {
      return cordovaPropertyGet(this, "textHelper");
    },
    set: function (value) {
      cordovaPropertySet(this, "textHelper", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Ndef.prototype, "uriHelper", {
    get: function () {
      return cordovaPropertyGet(this, "uriHelper");
    },
    set: function (value) {
      cordovaPropertySet(this, "uriHelper", value);
    },
    enumerable: false,
    configurable: true
  });
  Ndef.ɵfac = /* @__PURE__ */(() => {
    let ɵNdef_BaseFactory;
    return function Ndef_Factory(t) {
      return (ɵNdef_BaseFactory || (ɵNdef_BaseFactory = i0.ɵɵgetInheritedFactory(Ndef)))(t || Ndef);
    };
  })();
  Ndef.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Ndef,
    factory: Ndef.ɵfac
  });
  Ndef.pluginName = "NFC";
  Ndef.plugin = "phonegap-nfc";
  Ndef.pluginRef = "ndef";
  Ndef = __decorate([], Ndef);
  return Ndef;
}(AwesomeCordovaNativePlugin);
export { Ndef };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ndef, [{
    type: Injectable
  }], null, {
    TNF_EMPTY: [],
    TNF_WELL_KNOWN: [],
    TNF_MIME_MEDIA: [],
    TNF_ABSOLUTE_URI: [],
    TNF_EXTERNAL_TYPE: [],
    TNF_UNKNOWN: [],
    TNF_UNCHANGED: [],
    TNF_RESERVED: [],
    record: [],
    textRecord: [],
    uriRecord: [],
    absoluteUriRecord: [],
    mimeMediaRecord: [],
    smartPoster: [],
    emptyRecord: [],
    androidApplicationRecord: [],
    encodeMessage: [],
    decodeMessage: [],
    decodeTnf: [],
    encodeTnf: [],
    tnfToString: [],
    textHelper: [],
    uriHelper: []
  });
})();
var NfcUtil = /** @class */function (_super) {
  __extends(NfcUtil, _super);
  function NfcUtil() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  NfcUtil.prototype.toHex = function (i) {
    return cordova(this, "toHex", {
      "sync": true
    }, arguments);
  };
  NfcUtil.prototype.toPrintable = function (i) {
    return cordova(this, "toPrintable", {
      "sync": true
    }, arguments);
  };
  NfcUtil.prototype.bytesToString = function (i) {
    return cordova(this, "bytesToString", {
      "sync": true
    }, arguments);
  };
  NfcUtil.prototype.stringToBytes = function (s) {
    return cordova(this, "stringToBytes", {
      "sync": true
    }, arguments);
  };
  NfcUtil.prototype.bytesToHexString = function (bytes) {
    return cordova(this, "bytesToHexString", {
      "sync": true
    }, arguments);
  };
  NfcUtil.prototype.isType = function (record, tnf, type) {
    return cordova(this, "isType", {
      "sync": true
    }, arguments);
  };
  NfcUtil.prototype.arrayBufferToHexString = function (buffer) {
    return cordova(this, "arrayBufferToHexString", {
      "sync": true
    }, arguments);
  };
  NfcUtil.prototype.hexStringToArrayBuffer = function (hexString) {
    return cordova(this, "hexStringToArrayBuffer", {
      "sync": true
    }, arguments);
  };
  NfcUtil.ɵfac = /* @__PURE__ */(() => {
    let ɵNfcUtil_BaseFactory;
    return function NfcUtil_Factory(t) {
      return (ɵNfcUtil_BaseFactory || (ɵNfcUtil_BaseFactory = i0.ɵɵgetInheritedFactory(NfcUtil)))(t || NfcUtil);
    };
  })();
  NfcUtil.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NfcUtil,
    factory: NfcUtil.ɵfac
  });
  NfcUtil.pluginName = "NFC";
  NfcUtil.plugin = "phonegap-nfc";
  NfcUtil.pluginRef = "util";
  NfcUtil = __decorate([], NfcUtil);
  return NfcUtil;
}(AwesomeCordovaNativePlugin);
export { NfcUtil };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NfcUtil, [{
    type: Injectable
  }], null, {
    toHex: [],
    toPrintable: [],
    bytesToString: [],
    stringToBytes: [],
    bytesToHexString: [],
    isType: [],
    arrayBufferToHexString: [],
    hexStringToArrayBuffer: []
  });
})();
var TextHelper = /** @class */function (_super) {
  __extends(TextHelper, _super);
  function TextHelper() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  TextHelper.prototype.decodePayload = function (data) {
    return;
  };
  TextHelper.prototype.encodePayload = function (text, lang) {
    return;
  };
  return TextHelper;
}(AwesomeCordovaNativePlugin);
export { TextHelper };
var UriHelper = /** @class */function (_super) {
  __extends(UriHelper, _super);
  function UriHelper() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  UriHelper.prototype.decodePayload = function (data) {
    return;
  };
  UriHelper.prototype.encodePayload = function (uri) {
    return;
  };
  return UriHelper;
}(AwesomeCordovaNativePlugin);
export { UriHelper };
