<div class="bg-white" *ngIf="conveyorReader">
  <div class="d-flex align-items-center mb-2">
    <i class="fa fa-check-circle fa-lg title-margin" *ngIf="complete()"></i>
    <i class="fa fa-times-circle fa-lg title-margin" *ngIf="incomplete()"></i>
    <div>Conveyor Reader</div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyorReader.machineName"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyorReader.machineName"></i>
    <div class="small wrap">
      Machine Name:
      <span class="grey">
        {{ conveyorReader.machineName ? conveyorReader.machineName : "N/A" }}
      </span>
    </div>
  </div>
  <div
    class="d-flex align-items-center"
    [ngClass]="{
      'optional-missing': emptyOptionalField(conveyorReader.machineNickName)
    }"
  >
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyorReader.machineNickName"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyorReader.machineNickName"></i>
    <div class="small wrap">
      Machine Nick Name:
      <span class="grey">
        {{ conveyorReader.machineNickName ? conveyorReader.machineNickName : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyorReader.conveyorName"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyorReader.conveyorName"></i>
    <div class="small wrap">
      Conveyor Name:
      <span class="grey">
        {{ conveyorReader.conveyorName ? conveyorReader.conveyorName : "N/A" }}
      </span>
    </div>
  </div>
  <div
    class="d-flex align-items-center"
    [ngClass]="{
      'optional-missing': emptyOptionalField(conveyorReader.description)
    }"
  >
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyorReader.description"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyorReader.description"></i>
    <div class="small wrap">
      Description:
      <span class="grey">
        {{ conveyorReader.description ? conveyorReader.description : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyorReader.diameter"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyorReader.diameter"></i>
    <div class="small wrap">
      Drive Drum Diameter:
      <span class="grey">
        {{ conveyorReader.diameter ? conveyorReader.diameter : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyorReader.idleDrumDiameter"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyorReader.idleDrumDiameter"></i>
    <div class="small wrap">
      Idle Drum Diameter:
      <span class="grey">
        {{ conveyorReader.idleDrumDiameter ? conveyorReader.idleDrumDiameter : "N/A" }}
      </span>
    </div>
  </div>
  <div
    class="d-flex align-items-center"
    [ngClass]="{
      'optional-missing': emptyOptionalField(conveyorReader.customerProductReference)
    }"
  >
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyorReader.customerProductReference"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyorReader.customerProductReference"></i>
    <div class="small wrap">
      Customer Product Reference:
      <span class="grey">
        {{
          conveyorReader.customerProductReference ? conveyorReader.customerProductReference : "N/A"
        }}
      </span>
    </div>
  </div>
  <div
    class="d-flex align-items-center"
    [ngClass]="{
      'optional-missing': emptyOptionalField(conveyorReader.customerProductReference)
    }"
  >
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyorReader.readerId"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyorReader.readerId"></i>
    <div class="small wrap">
      Reader ID:
      <span class="grey">
        {{ conveyorReader.readerId ? conveyorReader.readerId : "N/A" }}
      </span>
    </div>
  </div>
</div>

<div class="bg-white mt-3" *ngIf="!conveyorReader">
  <div class="d-flex align-items-center">
    <i class="fa fa-times-circle fa-lg title-margin"></i>
    <div>No Conveyor Reader</div>
  </div>
</div>
