<div class="desktop header-sticky">
  <app-breadcrumbs
    [latestBreadcrumb]="header"
    *ngIf="showBackButton">
  </app-breadcrumbs>
  <div
    class="page-header bg-white d-flex justify-content-between align-items-center p-3 pb-0 border-bottom flex-wrap"
    [class.pb-3]="hasBottomPadding">
    <div class="col-8">
      <h4
        class="d-flex align-items-center fw-400 mb-0"
        [class.pb-3]="!hasBottomPadding">
        <div
          translate="no"
          class="material-symbols-outlined me-4 cursor-pointer"
          data-testid="back-button"
          (click)="onBackClick()"
          *ngIf="showBackButton">
          chevron_left
        </div>
        <span data-testid="survey-name">{{ header }}</span>
      </h4>
    </div>
    <div class="col-4 d-flex justify-content-end">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</div>
