import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { ApiOptions, ApiService } from './api.service';

@Injectable()
export class CBeltApiService {
  private code = environment.code;
  public baseUrl: string = environment.cBeltApi;

  public constructor(private apiService: ApiService) {}

  public get<T>(path: string, options?: ApiOptions): Promise<T> {
    return this.apiService.get(this.baseUrl + path, this.assignOptions(options));
  }

  public post<T>(path: string, body: unknown, options?: ApiOptions): Promise<T> {
    return this.apiService.post(this.baseUrl + path, body, this.assignOptions(options));
  }

  public put<T>(path: string, body: unknown, options?: ApiOptions): Promise<T> {
    return this.apiService.put(this.baseUrl + path, body, this.assignOptions(options));
  }

  public delete<T>(path: string, options?: ApiOptions): Promise<T> {
    return this.apiService.delete(this.baseUrl + path, this.assignOptions(options));
  }

  private assignOptions(options?: ApiOptions): ApiOptions {
    return {
      params: {
        code: this.code,
        ...options?.params,
      },
    };
  }
}
