<div
  *ngIf="settingUser"
  class="d-flex flex-column center">
  <div class="spinner-border text-primary"></div>
  <div class="mt-1">Setting C-Belt User</div>
</div>

<div
  class="mobile"
  *ngIf="!settingUser">
  <div class="px-2">
    <h2 class="text-center header mt-3">Select Activity</h2>
  </div>

  <div class="list-group list-group-mine mx-3 mt-4">
    <a
      (click)="resetState()"
      [ngClass]="installationInProgress ? '' : 'disable-grey'"
      class="nav-link list-group-item list-group-item-action mb-3 border-10">
      <div>
        <div class="fw-bold">Start a new configuration</div>
        <div class="grey">
          <strong>{{
            installationInProgress ? "Click here to reset local state" : "No installation to clear"
          }}</strong>
        </div>
      </div>
    </a>
  </div>

  <div class="list-group list-group-mine mx-3 mt-3">
    <div class="fw-bold mb-1 ms-2">CBLT Configuration</div>
    <a
      (click)="navTo('installing')"
      class="nav-link list-group-item list-group-item-action mb-3 border-10"
      ><div>
        <div class="fw-bold">
          {{
            installationInProgress
              ? "Continue with current installation"
              : "Setting up a new CBLT system"
          }}
        </div>
        <div class="grey">Put all devices in place and configure them</div>
      </div>
    </a>
    <a
      (click)="navTo('select-config')"
      class="nav-link list-group-item list-group-item-action mb-3 border-10">
      <div>
        <div class="fw-bold">Editing an existing CBLT system</div>
        <div class="grey">Replace devices or change their settings</div>
      </div>
    </a>
  </div>

  <div class="list-group list-group-mine mx-3 mt-3">
    <div class="fw-bold mb-1 ms-2">RFID Chip Configuration</div>
    <a
      (click)="navTo('fitting')"
      class="nav-link list-group-item list-group-item-action mb-3 border-10">
      <div>
        <div class="fw-bold">Fitting and preparing the belt</div>
        <div class="grey">Assign a belt item reference to an RFID chip</div>
      </div>
    </a>
    <a
      (click)="navTo('select-config-pair-rfid')"
      class="nav-link list-group-item list-group-item-action mb-3 border-10">
      <div>
        <div class="fw-bold">Pair RFID with Order to Conveyor</div>
        <div class="grey">Update the Belt with Conveyor ID</div>
      </div>
    </a>
  </div>
</div>
