<div class="mobile">
  <app-mobile-header
    [header]="'Add a new Sensor'"
    [routerLinkUrl]="['/installing', companyId, 'sensor-configuration', 'add-edit-sensors']">
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <form
      name="configuration-form"
      class="d-flex">
      <div class="bg-white card p-3">
        <button
          type="button"
          class="btn btn-primary"
          (click)="navToAddSensorNfc()">
          Or: Add With NFC
        </button>

        <form
          name="configuration-form"
          class="d-flex mt-3"
          *ngIf="this.sensor.conveyorId">
          <div class="bg-white card p-3">
            <div class="d-flex justify-content-center">
              Sensor already mapped to conveyor with ID: {{ sensor.conveyorId }}
            </div>
          </div>
        </form>

        <label
          for="sensorId"
          class="form-label mt-4"
          >Sensor ID
        </label>
        <div class="grey">This unique ID can be found on the box the sensor comes in</div>
        <input
          [(ngModel)]="sensor.edid"
          class="form-control mt-2"
          name="sensorId"
          type="number"
          placeholder="eg: 34735693"
          (blur)="findSensorByEdid(sensor.edid.toString())" />
        <label
          for="sensorNickname"
          class="form-label mt-3"
          >Sensor nickname</label
        >
        <input
          [(ngModel)]="sensor.sensorNickname"
          class="form-control"
          name="sensorNickname"
          type="text"
          placeholder="What do you want to call the sensor?" />
        <label
          for="description"
          class="form-label mt-3"
          >Description</label
        >
        <textarea
          [(ngModel)]="sensor.description"
          name="description"
          type="text"
          placeholder="Optional: Enter a short description of the sensor in this text box. It can be viewed in the dashboard later"
          class="form-control"
          rows="3"></textarea>

        <label
          *ngIf="returnSid()"
          for="sensorRole"
          class="form-label mt-3"
          >Sensor Role</label
        >
        <div
          *ngIf="returnSid()"
          class="relative">
          <input
            [(ngModel)]="sensor.role"
            type="text"
            class="form-control input dropdown-toggle"
            placeholder="What is the Sensor Role?"
            name="sensorRole"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            aria-expanded="false" />
          <ul class="dropdown-menu position-static w-100">
            <li class="pointer">
              <a
                class="dropdown-item"
                (click)="selectRole('DriveDrum')"
                >Drive Drum</a
              >
            </li>
            <li class="pointer">
              <a
                class="dropdown-item"
                (click)="selectRole('IdleDrum')"
                >Idle Drum</a
              >
            </li>
          </ul>
        </div>
        <button
          [disabled]="sensor.conveyorId"
          type="button"
          class="btn btn-primary mt-3"
          (click)="navToSensorConfiguration()">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
