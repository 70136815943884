<div class="mobile">
  <app-mobile-header
    [header]="'Pair a belt with an RFID chip'"
    (backButtonClicked)="onBackPressed()"
    [routerLinkUrl]="['/c-belt']">
  </app-mobile-header>

  <div class="d-flex justify-content-end mx-3 my-3 pe-3">
    <div class="fw-bold me-2">R&D User</div>
    <div class="form-check form-switch ms-3">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckChecked"
        (change)="toggleRndUser($event)"
        style="transform: scale(1.8)"
        [checked]="isRndUser" />
    </div>
  </div>

  <form
    name="configuration-form"
    class="d-flex mx-3 mt-3">
    <div class="bg-white card p-3">
      <label
        for="beltOrderNumber"
        class="form-label"
        >Belt Item Reference</label
      >
      <input
        class="form-control"
        autofocus
        name="beltOrderNumber"
        type="text"
        [(ngModel)]="belt.orderNumber"
        placeholder="Belt Item Reference" />
      <label
        for="beltLength"
        class="form-label mt-3"
        *ngIf="isRndUser || this.current.editing"
        >Belt Length (mm)</label
      >
      <input
        *ngIf="isRndUser || this.current.editing"
        class="form-control"
        name="beltLength"
        type="number"
        [(ngModel)]="belt.length"
        placeholder="Belt Length" />
      <label
        for="beltWidth"
        class="form-label mt-3"
        *ngIf="isRndUser || this.current.editing"
        >Belt Width (mm)</label
      >
      <input
        *ngIf="isRndUser || this.current.editing"
        class="form-control"
        name="beltWidth"
        type="number"
        [(ngModel)]="belt.width"
        placeholder="Belt Width" />
      <button
        type="button"
        class="btn btn-primary mt-4"
        (click)="openScanningPage()">
        Scan the RFID chip to pair
      </button>
      <div class="d-flex justify-content-center my-3">OR</div>
      <button
        type="button"
        class="btn btn-primary"
        (click)="openManualEntryPage()">
        Add Manually
      </button>
    </div>
  </form>
</div>
