import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Breadcrumb, BreadcrumbService } from '../../services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss',
})
export class BreadcrumbsComponent {
  @Input() public latestBreadcrumb?: string;
  public breadcrumbs: Breadcrumb[] = [];

  public breadcrumbSub: Subscription;

  public constructor(private breadcrumbService: BreadcrumbService, private router: Router) {}

  public ngOnInit(): void {
    this.breadcrumbSub = this.breadcrumbService.subscribeToBreadcrumbChanges((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    });
  }

  public ngOnDestroy(): void {
    if (this.breadcrumbSub) {
      this.breadcrumbSub.unsubscribe();
    }
  }

  public navigateToBreadcrumb(breadcrumb: Breadcrumb): void {
    if (this.breadcrumbs.length - 1 == this.breadcrumbs.indexOf(breadcrumb)) {
      return;
    }

    this.router.navigate(breadcrumb.routerLink, { queryParams: breadcrumb.queryParams });
    this.breadcrumbService.removeBreadcrumbsAfterBreadcrumb(breadcrumb);
  }

  public clearBreadcrumbs(): void {
    this.breadcrumbService.setBreadcrumbs([]);
  }
}
