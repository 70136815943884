import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';

import { Sensor } from '../../../models/sensor.model';
import { Customer } from '../../../models/customer.model';
import { Conveyor } from '../../../models/conveyor.model';
import { CBeltMachine } from '../../../models/cBeltMachine.model';
import { PairedSensor } from '../../../models/paired-sensor.model';

import { AlertService } from '../../../shared/services/alert/alert.service';
import { ConveyorsService } from '../../../services/c-belt/conveyors.service';
import { LoadingService } from '../../../shared/services/loading/loading.service';
import { MachinesService } from '../../../services/c-belt/machines.service';
import { MsalTokenService, MsalScope } from '../../../services/c-belt/msal-token.service';
import { OrganizationsService } from '../../../services/c-belt/organizations.service';
import { SensorsService } from '../../../services/c-belt/sensors.service';
import { TranslateService } from '../../../services/translate/translate.service';

import { Subscription } from 'rxjs';
import { NdefEvent, NFC } from '@awesome-cordova-plugins/nfc/ngx';

@Component({
  selector: 'app-scan-sensor',
  templateUrl: './scan-sensor.component.html',
  styleUrl: './scan-sensor.component.scss',
})
export class ScanSensorComponent implements OnInit, OnDestroy {
  public edid: string;
  public enabled: boolean;
  public manualEdid: number;
  public isNfcActive: boolean = false;
  public settingUser: boolean = false;

  public sensor: Sensor = new Sensor();
  public conveyor: Conveyor = new Conveyor();
  public customer: Customer = new Customer();
  public machine: CBeltMachine = new CBeltMachine();
  public pairedSensor: PairedSensor = new PairedSensor();

  public pairedSensors: PairedSensor[] = [];

  private nfcListener: any;

  private scannerSub: Subscription;

  public qrConfig = {
    preferFrontCamera: false,
    showFlipCameraButton: true,
    showTorchButton: true,
    torchOn: false,
    saveHistory: true,
    prompt: 'Place a barcode inside the scan area',
    resultDisplayDuration: 500,
    formats: 'QR_CODE,PDF_417',
    orientation: 'portrait',
    disableAnimations: true,
    disableSuccessBeep: false,
  };

  public constructor(
    private nfc: NFC,
    private ngZone: NgZone,
    private alertService: AlertService,
    private sensorService: SensorsService,
    private loadingService: LoadingService,
    private machineService: MachinesService,
    private conveyorService: ConveyorsService,
    private msalTokenService: MsalTokenService,
    private organizationService: OrganizationsService,
    public t: TranslateService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.loadingService.show();
    this.settingUser = true;
    if (MsalTokenService.isMobileApp()) {
      await this.msalTokenService.setScope(MsalScope.cBelt);
    }
    this.settingUser = false;
    this.loadingService.hide();
    this.checkNfcEnabled();

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    this.nfcListener = () => {
      this.ngZone.run(() => this.checkNfcEnabled());
    };

    document.addEventListener('resume', this.nfcListener, false);
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.scannerSub) {
      this.scannerSub.unsubscribe();
    }

    document.removeEventListener('resume', this.nfcListener);
  }

  private async checkNfcEnabled(): Promise<void> {
    try {
      await this.nfc.enabled();
      this.isNfcActive = true;
      this.addListener();
    } catch (err) {
      console.log(err);
      this.isNfcActive = false;
    }
  }

  public openSettings(): void {
    this.nfc.showSettings();
  }

  public async addListener(): Promise<void> {
    this.nfc.addNdefListener().subscribe(
      (tag) => this.ngZone.run(() => this.onTagFound(tag)),
      (err) => this.ngZone.run(() => this.onNfcError(err)),
    );
  }

  public onTagFound(tag: NdefEvent): void {
    const payload = tag.tag.ndefMessage[0].payload;
    const stringValue = this.nfc.bytesToString(payload);
    const hexValue = stringValue.slice(3, 20);
    this.edid = this.macAddressToDecimal(hexValue).toString();
    this.findSensorByEdid(this.edid);
  }

  public onNfcError(err: string): void {
    if (err == 'NFC_DISABLED') {
      this.enabled = false;
    }
  }

  public openAndScanQR(): void {
    window['cordova'].plugins['barcodeScanner'].scan(
      (result) => this.ngZone.run(() => (this.edid = result.text)),
      (err) => this.ngZone.run(() => this.onQRScanError(err)),
      this.qrConfig,
    );
  }

  public onQRScanError(err: Error): void {
    this.alertService.alertError('QR Scan Failed: ' + JSON.stringify(err));
  }

  public macAddressToDecimal(macAddress: string): number {
    const macParts = macAddress.split(':');
    let decimalNumber = 0;

    for (let i = 0; i < macParts.length; i++) {
      const hexPart = parseInt(macParts[i], 16);
      decimalNumber = decimalNumber * 256 + hexPart;
    }

    return decimalNumber;
  }

  public manualSearch(): void {
    this.edid = this.manualEdid.toString();
    this.findSensorByEdid(this.manualEdid.toString());
    this.manualEdid = null;
  }

  public async findSensorByEdid(edid: string): Promise<void> {
    this.loadingService.show();
    try {
      let sensor: Sensor;

      const result = await this.sensorService.getSensorsByEdid(edid);

      if (!result.length) {
        this.sensor = null;
      } else {
        sensor = result[0];
        this.sensor = result[0];
        if (sensor.conveyorId) {
          await this.findConveyorById(sensor.conveyorId);
        } else {
          this.conveyor = null;
          this.machine = null;
          this.customer = null;
          this.pairedSensor = null;
        }
      }
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();
  }

  public async findConveyorById(id: string): Promise<void> {
    const conveyor: Conveyor = await this.conveyorService.getConveyorById(id);

    if (!conveyor) {
      this.conveyor = null;
    } else {
      this.conveyor = conveyor;
      if (conveyor.machineId) {
        await this.findMachineById(conveyor.machineId);
      } else {
        this.machine = null;
        this.customer = null;
        this.pairedSensor = null;
      }
    }
  }

  public async findMachineById(id: string): Promise<void> {
    const machine: CBeltMachine = await this.machineService.getMachineById(id);

    if (!machine) {
      this.machine = null;
    } else {
      this.machine = machine;
      if (machine.organizationId) {
        await this.findCustomerById(machine.organizationId);
      } else {
        this.customer = null;
        this.pairedSensor = null;
      }
    }
  }

  public async findCustomerById(id: string): Promise<void> {
    const customer: Customer = await this.organizationService.getCustomerById(id);

    if (!customer) {
      this.customer = null;
    } else {
      this.customer = customer;
      await this.fetchPairedSensors();
    }
  }

  public async fetchPairedSensors(): Promise<void> {
    const sensors: PairedSensor[] = await this.sensorService.getPairedSensors();

    if (!sensors) {
      this.pairedSensors = [];
      this.pairedSensor = null;
    } else {
      this.pairedSensors = sensors;
      this.pairedSensor = this.pairedSensors.find((x) => x.edid == this.sensor.edid);
    }
  }

  public formatDateTime(date: string): string {
    return new Date(date).toLocaleString();
  }

  public newScan(): void {
    this.edid = null;
    this.sensor = null;
    this.conveyor = null;
    this.machine = null;
    this.customer = null;
    this.pairedSensor = null;
    this.pairedSensors = [];
  }
}
