import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrl: './mobile-header.component.scss',
})
export class MobileHeaderComponent {
  @Input() public routerLinkUrl;
  @Input() public showDefaultBackButton: boolean = false;
  @Input() public header: string;
  @Input() public addSpacer: boolean = false;

  @Output() public backButtonClicked = new EventEmitter();

  public emitBackPressed(): void {
    this.backButtonClicked.emit();
  }

  public onBackPressed(): void {
    window.history.back();
    this.emitBackPressed();
  }
}
