import { trigger, style, transition, animate, AnimationTriggerMetadata } from '@angular/animations';

export class Animations {
  public static flyInOut(): AnimationTriggerMetadata {
    return trigger('flyInOut', [
      transition('void => *', [style({ transform: 'translateX(100%)' }), animate('200ms')]),
      transition('* => void', [animate('200ms', style({ transform: 'translateX(100%)' }))]),
    ]);
  }

  public static fadeInSlideOut(): AnimationTriggerMetadata {
    return trigger('fadeInSlideOut', [
      transition('void => *', [
        style({ transform: 'translateX(10%)', opacity: 0 }),
        animate('300ms'),
      ]),
      transition('* => void', [
        animate('300ms', style({ transform: 'translateX(10%)', opacity: 0 })),
      ]),
    ]);
  }

  public static slideInFadeOut(): AnimationTriggerMetadata {
    return trigger('slideInFadeOut', [
      transition('void => *', [style({ transform: 'translateX(100%)' }), animate('200ms')]),
      transition('* => void', [animate('300ms', style({ opacity: 0 }))]),
    ]);
  }

  public static slideInOut(): AnimationTriggerMetadata {
    return trigger('slideInOut', [
      transition('void => *', [style({ transform: 'translateX(-100%)' }), animate('200ms')]),
      transition('* => void', [animate('200ms', style({ transform: 'translateX(-100%)' }))]),
    ]);
  }

  public static fadeInOut(): AnimationTriggerMetadata {
    return trigger('fadeInOut', [
      transition('void => *', [style({ opacity: 0 }), animate('300ms')]),
      transition('* => void', [animate('300ms', style({ opacity: 0 }))]),
    ]);
  }
}
