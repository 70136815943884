import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';

import { BeltFilter } from '../belt-list.component';
import { Belt } from './../../../../models/belt.model';
import { Survey } from './../../../../models/survey.model';

import { TranslateService } from '../../../../services/translate/translate.service';
import { UtilService } from './../../../../utils/util.service';

import * as BeltActions from './../../../../state/belt/belt.actions';
import * as BeltSelectors from './../../../../state/belt/belt.selector';

@Component({
  selector: 'app-mobile-belt-list',
  templateUrl: './mobile-belt-list.component.html',
  styleUrl: './mobile-belt-list.component.scss',
})
export class MobileBeltListComponent {
  @Input() public survey: Survey;
  @Input() public appliedFilters: BeltFilter;
  @Input() public filterCount: number;
  @Input() public hasActiveFilter: boolean;
  @Input() public belts: Belt[] = [];

  public paginatedBelts: Belt[] = [];

  @Input() public searchText: string;
  @Output() public searchTextChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() public itemClick: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() public copyClick: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() public removeClick: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() public filterButtonClick: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() public downloadSurveyCsvClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor(private utils: UtilService, private store: Store, public t: TranslateService) {
    document.addEventListener('scroll', (event) => {
      const element = document.body;

      const scroll = window.scrollY;
      const scrollAvailable = element.scrollHeight - element.clientHeight;
      const available = 100 - (scroll / scrollAvailable) * 100;

      if (available < 20) this.onScrollDown();
    });
  }

  public ngOnInit(): void {
    this.store.dispatch(BeltActions.fetchSurveyBelts({ surveyId: this.survey.id }));
    const belts$ = this.store.select(BeltSelectors.selectSurveyBelts({ surveyId: this.survey.id }));
    belts$.subscribe((belts: Belt[]) => {
      this.belts = belts;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['belts']) {
      this.paginatedBelts = this.utils.sortAlphaNumeric(this.paginatedBelts, 'created', false);
      this.populateRows();
    }
  }

  private populateRows(): void {
    if (!this.belts?.length) {
      this.paginatedBelts = [];
      return;
    }

    const tableRows = Math.min(20, this.belts.length);
    const displayedRows = Math.min(this.paginatedBelts.length, this.belts.length);
    const maxItems = Math.max(tableRows, displayedRows);

    const rows = [];
    for (let i = 0; i < maxItems; i++) {
      rows.push(this.belts[i]);
    }
    this.paginatedBelts = rows;
  }

  public onScrollDown(): void {
    if (this.paginatedBelts.length < this.belts.length) {
      const endIndex = Math.min(this.paginatedBelts.length + 20, this.belts.length);
      for (let i = this.paginatedBelts.length; i < endIndex; i++) {
        this.paginatedBelts.push(this.belts[i]);
      }
    }
  }

  public onItemClick(belt: Belt): void {
    this.itemClick.emit(belt);
  }

  public onCopyClick(belt: Belt): void {
    this.copyClick.emit(belt);
  }

  public onRemoveClick(belt: Belt): void {
    this.removeClick.emit(belt);
  }

  public onFilterButtonClick(): void {
    this.filterButtonClick.emit();
  }

  public onDownloadSurveyCsvClick(): void {
    this.downloadSurveyCsvClick.emit();
  }

  public onSearchTextChange(): void {
    this.searchTextChange.emit(this.searchText);
  }
}
