import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Router } from '@angular/router';

@Injectable()
export class UserEffects {
  public constructor(
    private actions$: Actions,
    private router: Router,
  ) {}

  // init$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CoreActions.appInit),

  //     switchMap(() =>
  //       from(auth.getUser()).pipe(
  //         map((user?: User) => {
  //           if (!user) {
  //             return UserActions.loginFailed({ error: null });
  //           }

  //           return UserActions.loginSuccess({ user });
  //         }),
  //         catchError((error) => of(UserActions.loginFailed({ error })))
  //       )
  //     )
  //   )
  // );

  // login$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserActions.login),

  //     switchMap((loginDto) =>
  //       from(auth.login(loginDto)).pipe(
  //         map((res) => {
  //           return UserActions.loginSuccess({ user: res.user });
  //         }),
  //         catchError((error) => of(UserActions.loginFailed({ error })))
  //       )
  //     )
  //   )
  // );

  // logout$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserActions.logout),

  //     switchMap(() =>
  //       from(auth.logout()).pipe(
  //         map(() => {
  //           this.router.navigate(['/auth/login']);
  //           return UserActions.logoutSuccess();
  //         }),
  //         catchError((_err) => EMPTY)
  //       )
  //     )
  //   )
  // );

  // markLoggedIn$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserActions.registerSuccess),
  //     switchMap((prop) => {
  //       return of(UserActions.loginSuccess(prop));
  //     })
  //   )
  // );

  // forgotPassword$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserActions.forgotPassword),
  //     switchMap((forgotPasswordDto: ForgotPasswordDto) =>
  //       from(auth.forgotPassword(forgotPasswordDto)).pipe(
  //         map((_message: MessageDto) => UserActions.forgotPasswordSuccess()),
  //         catchError((err) => of(UserActions.forgotPasswordFailed({ error: err })))
  //       )
  //     )
  //   )
  // );

  // resetPassword$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserActions.resetPassword),
  //     switchMap((resetPasswordDto: ResetPasswordDto) =>
  //       from(auth.resetPassword(resetPasswordDto)).pipe(
  //         map((_message: MessageDto) => UserActions.resetPasswordSuccess()),
  //         catchError((err) => of(UserActions.resetPasswordFailed({ error: err })))
  //       )
  //     )
  //   )
  // );
}
