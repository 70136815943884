import { Image } from '../shared/components/image-input/models/image';
import { Model } from '../state/state-utils';

export class Belt implements Model {
  // DB
  public id: string;
  public created: Date = new Date();
  public updated: Date = new Date();
  public createdBy: string;
  public updatedBy: string;

  // Shared required
  public wtl: string;
  public synOrMod: string = null;
  public productionLine: string;
  public productReference: string;
  public length: number;
  public width: number;
  public quantity: number = 1;
  public surveyId: string;
  public machineId: string;

  // Optional Shared
  public carriersBordoflex: string;
  public comments: string;
  public attachmentUrl: string;
  public surveyor?: unknown;
  public image?: Image;
  public deleted: boolean;
  public beltAccess: string;
  public itemReference: string;

  // SYNTHETIC BELTS
  weldType: string = null;
  weldDirection: string = null;
  joint: string = null;
  material: string;
  trackingGuide: string;

  // MODULAR BELTS
  public sprocketBore: string;
  public sprocketPd: string;
  public pinMaterial: string;
  public beltColour: string;
  public beltMaterial: string;
  public beltType: string;

  /**
   * @description Dirty flag should be `true` if the value has been edited on the client device.
   */
  public dirty: boolean = true;

  public constructor(surveyId: string) {
    this.surveyId = surveyId;
  }
}
