import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Customer } from 'src/app/models/customer.model';
import { Conveyor } from 'src/app/models/conveyor.model';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-system-setup-sensors',
  templateUrl: './system-setup-sensors.component.html',
  styleUrl: './system-setup-sensors.component.scss',
})
export class SystemSetupSensorsComponent implements OnInit {
  public editing: boolean;
  public companyId: string;
  public customer: Customer;
  public current: ConfigurationState = null;
  public containsRfidReader: boolean = true;

  public conveyors: Conveyor[] = [];

  public constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  public subscribeToCurrentConfiguration(): void {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.customer = this.current.company;
        this.containsRfidReader = this.current.containsRfidReader;
        this.editing = this.current.editing;
      });
  }

  public fetchConveyorsForMachine(id: string): Conveyor[] {
    return this.current.conveyors.filter((conveyor) => conveyor.machineId == id);
  }

  public selectConveyor(conveyor: Conveyor): void {
    this.store.dispatch(InstallationActions.setCurrentConveyor({ conveyor: conveyor }));
    this.router.navigate(['/installing', this.companyId, 'sensor-configuration']);
  }
}
