import { StateUtils } from '../state-utils';

import { Action, createReducer, on } from '@ngrx/store';
import * as InstallationActions from './installation.actions';
import { InstallationState, initialState } from './installation.state';

const installationReducer = createReducer(
  initialState,

  on(InstallationActions.resetState, () => ({ ...initialState })),

  on(InstallationActions.updateCompany, (state, data) => ({
    ...state,
    current: { ...state.current, company: data.customer },
  })),

  on(InstallationActions.updateBelt, (state, data) => ({
    ...state,
    current: {
      ...state.current,
      belts: StateUtils.combineState(state.current.belts, data.belt),
    },
  })),

  on(InstallationActions.setCurrentBelt, (state, data) => ({
    ...state,
    current: { ...state.current, belt: data.belt },
  })),

  on(InstallationActions.clearCurrentBelt, (state) => ({
    ...state,
    current: { ...state.current, belt: null },
  })),

  on(InstallationActions.updateConveyor, (state, data) => ({
    ...state,
    current: {
      ...state.current,
      conveyors: StateUtils.combineState(state.current.conveyors, data.conveyor),
    },
  })),

  on(InstallationActions.setCurrentConveyor, (state, data) => ({
    ...state,
    current: { ...state.current, conveyor: data.conveyor },
  })),

  on(InstallationActions.clearCurrentConveyor, (state) => ({
    ...state,
    current: { ...state.current, conveyor: null },
  })),

  on(InstallationActions.clearCurrentMachine, (state) => ({
    ...state,
    current: { ...state.current, machine: null },
  })),

  on(InstallationActions.updateGatewayDevice, (state, data) => ({
    ...state,
    current: {
      ...state.current,
      gateways: StateUtils.combineState(state.current.gateways, data.gatewayDevice),
    },
  })),

  on(InstallationActions.setCurrentGatewayDevice, (state, data) => ({
    ...state,
    current: { ...state.current, gateway: data.gatewayDevice },
  })),

  on(InstallationActions.clearCurrentGateway, (state) => ({
    ...state,
    current: { ...state.current, gateway: null },
  })),

  on(InstallationActions.updateConveyorReader, (state, data) => ({
    ...state,
    current: {
      ...state.current,
      conveyorReaders: StateUtils.combineState(state.current.conveyorReaders, data.conveyorReader),
    },
  })),

  on(InstallationActions.setCurrentConveyorReader, (state, data) => ({
    ...state,
    current: { ...state.current, conveyorReader: data.conveyorReader },
  })),

  on(InstallationActions.updateMachine, (state, data) => ({
    ...state,
    current: {
      ...state.current,
      machines: StateUtils.combineState(state.current.machines, data.machine),
    },
  })),

  on(InstallationActions.setCurrentMachine, (state, data) => ({
    ...state,
    current: { ...state.current, machine: data.machine },
  })),

  on(InstallationActions.updateReaderCalibrated, (state, data) => ({
    ...state,
    current: { ...state.current, readerCalibrated: data.calibrated },
  })),

  on(InstallationActions.addExistingSensors, (state, data) => ({
    ...state,
    current: { ...state.current, sensorsForConveyor: data.sensors },
  })),

  on(InstallationActions.addSensorToEdit, (state, data) => ({
    ...state,
    current: { ...state.current, sensor: data.sensor },
  })),

  on(InstallationActions.updateSensor, (state, data) => ({
    ...state,
    current: {
      ...state.current,
      sensors: StateUtils.combineState(state.current.sensors, data.sensor),
    },
  })),

  on(InstallationActions.deleteSensor, (state, data) => ({
    ...state,
    current: {
      ...state.current,
      sensors: state.current.sensors.filter((sensor) => sensor.id != data.sensor.id),
    },
  })),

  on(InstallationActions.updateNewSensorId, (state, data) => ({
    ...state,
    current: { ...state.current, newSensorId: data.sensorId },
  })),

  on(InstallationActions.updateEditingStatus, (state, data) => ({
    ...state,
    current: { ...state.current, editing: data.editing },
  })),

  on(InstallationActions.togglePairingRfid, (state, data) => ({
    ...state,
    current: { ...state.current, pairingRfid: data.pairingRfid },
  })),

  on(InstallationActions.updateContainsRfidStatus, (state, data) => ({
    ...state,
    current: { ...state.current, containsRfidReader: data.containsRfidReader },
  })),

  on(InstallationActions.updateIsRndUserStatus, (state, data) => ({
    ...state,
    current: { ...state.current, isRndUser: data.isRndUser },
  })),

  on(InstallationActions.setCurrentState, (state, data) => ({
    ...state,
    current: data.current ? data.current : state.current,
  }))
);

export function reducer(state: InstallationState | undefined, action: Action): InstallationState {
  return installationReducer(state, action);
}
