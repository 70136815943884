import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss'],
})
export class DesktopHeaderComponent {
  @Input() public header: string;
  @Input() public hasBottomPadding: boolean = true;
  @Input() public showBackButton: boolean = true;
  @Output() public back: EventEmitter<any> = new EventEmitter();

  public constructor(private breadcrumbService: BreadcrumbService) {}

  public onBackClick(): void {
    this.breadcrumbService.popBreadcrumb();
  }
}
