<app-desktop-header header="AMMcare Belt Import">
    <div content>
        <div class="d-flex gap-2">
            <button class="btn btn-primary" (click)="importBelts()">
                Import
            </button>
        </div>
    </div>
</app-desktop-header>


<div class="mx-4 mx-lg-5 my-3 mt-lg-8" *ngIf="ammcareBelts">
    <div class="mobile">
        <h3 class="d-flex justify-content-between align-items-center">
            <div class="col-1 text-muted link text-nowrap" [routerLink]="['/surveys', survey.id]">
                <i class="fa fa-angle-left me-2"></i>
            </div>
            <div class="col-11 text-secondary text-end">
                <div>Import AMMcare belts</div>
                <div class="small">
                    <div class="text-muted small">{{ survey.customer?.customer_name }}</div>
                    <div class="text-muted small">{{ survey.created | date }}</div>
                </div>
            </div>
        </h3>
    </div>

    <!-- TODO: replace with app-table -->
    <div class="p-3 rounded bg-white">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>
                        <input type="checkbox" [checked]="areAllChecked()" (click)="toggleAllBelts()">
                    </th>
                    <th>Image</th>
                    <th>Production Line</th>
                    <th>Product Reference</th>
                    <th>Length</th>
                    <th>Width</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let belt of ammcareBelts">
                    <td>
                        <input type="checkbox" [(ngModel)]="belt.mustImport">
                    </td>
                    <td>
                        <div class="belt-thumbnail no-image d-flex align-items-center justify-content-center" 
                            [class]="belt.images[0]?.imageUrl ? 'photo-btn text-white' : 'bg-light border'">
                            <img class="cursor-pointer"
                                (click)="openImageModal(imagePreviewModal, belt)"
                                [src]="belt.images[0].imageUrl" 
                                *ngIf="belt.images[0]?.imageUrl">
                            <i class="fa fa-question-circle" *ngIf="!belt.images[0]?.imageUrl"></i> 
                        </div>
                    </td>
                    <td>{{ belt.productionLine || '-' }}</td>
                    <td>{{ belt.machine_id }}</td>
                    <td>{{ belt.length || '0' }}mm</td>
                    <td>{{ belt.width || '0'}}mm</td>
                </tr>

                <tr *ngIf="!ammcareBelts.length">
                    <td colspan="6">
                        There are no belts available to import
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #imagePreviewModal let-modal>
    <div class="modal-header align-items-start">
        <div class="modal-title" id="modal-basic-title">
            <h4>
                Image Preview<br>
                <span class="text-muted small fw-light">{{ currentBelt.productReference }}</span>
            </h4>
        </div>
        <div class="close pointer fs-3" aria-label="Close" (click)="hideModal()">
            <span aria-hidden="true">&times;</span>
        </div>
    </div>
    <div class="modal-body">
        <div class="belt-image-preview d-flex justify-content-around">
            <img [src]="currentBelt.images[0].imageUrl" class="border" />
        </div>
    </div>
</ng-template>
