import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler } from '@angular/common/http';
import { HttpRequest, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth/auth.service';
import { MsalScope, MsalTokenService } from '../services/c-belt/msal-token.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  public constructor(
    private authService: AuthService,
    private msalTokenService: MsalTokenService,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return from(this.getRequestToken(request)).pipe(
      switchMap((token) => {
        if (!token) return next.handle(request);

        return next.handle(this.addAuthTokenToRequest(request, token));
      }),
    );
  }

  public async getRequestToken(request: HttpRequest<unknown>): Promise<string | null> {
    const ammscanApiUrl = environment.baseUrl;
    const ammcareApiUrl = environment.ammcareBaseUrl;
    const cBeltApiUrl = environment.cBeltApi;

    let scope: MsalScope;
    if (request.url.includes(ammscanApiUrl)) scope = MsalScope.ammscan;
    if (request.url.includes(ammcareApiUrl)) scope = MsalScope.ammscan;
    if (request.url.includes(cBeltApiUrl)) scope = MsalScope.cBelt;

    if (!scope) return null;
    return this.msalTokenService.getToken(scope);
  }

  private addAuthTokenToRequest(
    request: HttpRequest<unknown>,
    token: string,
  ): HttpRequest<unknown> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }
}
