import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Belt } from '../../../../../models/belt.model';
import { MenuItem } from '../../../../../shared/components/meta-menu/meta-menu.component';
import { TranslateService } from '../../../../../services/translate/translate.service';

@Component({
  selector: 'app-mobile-belt-item-card',
  templateUrl: './mobile-belt-item-card.component.html',
  styleUrl: './mobile-belt-item-card.component.scss',
})
export class MobileBeltItemCardComponent {
  @Input() public belt: Belt;

  @Output() public itemClick: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() public copyClick: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() public removeClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  public menuItems: MenuItem[] = [];

  constructor(public t: TranslateService) {}

  public ngOnInit(): void {
    this.menuItems = this.getBeltMenuItems(this.belt);
  }

  public hasValidImage(belt) {
    return belt.attachmentUrl || belt.image?.displayImage;
  }

  public isBase64(belt: Belt) {
    if (typeof belt.image?.displayImage == 'string') {
      return belt.image?.displayImage.includes('base64');
    }
    return false;
  }

  private getBeltMenuItems(belt: Belt): MenuItem[] {
    const menuItems = [
      {
        symbol: 'content_copy',
        title: `${this.t.translate('_COPY_BELT')}`,
        onClick: () => {
          this.copyClick.emit(belt);
        },
      },
      {
        symbol: 'delete_forever',
        title: `${this.t.translate('_REMOVE')}`,
        onClick: () => {
          this.removeClick.emit(belt);
        },
      },
    ];
    return menuItems;
  }

  public onItemClick(itemDetails: { id: string; event }) {
    this.itemClick.emit(itemDetails);
  }

  public componentTranslations(synOrMod: string) {
    if (synOrMod == 'synthetic') {
      return `${this.t.translate('_SYNTHETIC')}`;
    } else if (synOrMod == 'modular') {
      return `${this.t.translate('_MODULAR')}`;
    }
    return '';
  }
}
