import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ModalService } from 'src/app/services/modal/modal.service';
import { StateService } from 'src/app/services/c-belt/state.service';

import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';

@Component({
  selector: 'app-select-configuration',
  templateUrl: './select-configuration.component.html',
  styleUrls: ['./select-configuration.component.scss'],
})
export class SelectConfigurationComponent implements OnInit {
  public history: ConfigurationState[] = [];

  public constructor(
    private store: Store,
    private router: Router,
    private modalService: ModalService,
    private stateService: StateService
  ) {}

  public ngOnInit(): void {
    this.toggleEditingStatus(false);
    this.loadHistory();
  }

  public loadHistory(): void {
    this.history = this.stateService.loadConfigurationHistory();
  }

  public navTo(route: string, machineId: string): void {
    const current = this.history.find((x) => x.conveyors.find((y) => y.machineId == machineId));
    this.store.dispatch(InstallationActions.setCurrentState({ current }));
    this.stateService.subscribeToCurrentAndSave();
    this.toggleEditingStatus(true);
    this.router.navigate(['/installing', route]);
  }

  public toggleEditingStatus(status: boolean): void {
    this.store.dispatch(InstallationActions.updateEditingStatus({ editing: status }));
  }

  public removeConfigFromHistory(config: ConfigurationState): void {
    this.modalService.showDeleteModal({
      title: 'Delete Config',
      message: 'Are you sure you want to remove this config from history?',
      delete: () => {
        this.stateService.removeConfigFromHistory(config);
        this.loadHistory();
      },
    });
  }
}
