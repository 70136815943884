export class Gateway {
  public id: string;

  public gatewayDeviceId: string;
  public networkName: string;
  public devicePassword: string;
  public ipAddress: string;
  public networkMask: string;
  public gatewayAddress: string;
  public dns: string;
  public wifiType: string;

  public static fromJson(json: {
    id: string;
    gatewayDeviceId: string;
    networkName: string;
    devicePassword: string;
    ipAddress: string;
    networkMask: string;
    gatewayAddress: string;
    dns: string;
    wifiType: string;
  }): Gateway {
    const gateway = new Gateway();
    gateway.id = json.id;
    gateway.gatewayDeviceId = json.gatewayDeviceId;
    gateway.networkName = json.networkName;
    gateway.devicePassword = json.devicePassword;
    gateway.ipAddress = json.ipAddress;
    gateway.networkMask = json.networkMask;
    gateway.gatewayAddress = json.gatewayAddress;
    gateway.dns = json.dns;
    gateway.wifiType = json.wifiType;
    return gateway;
  }
}
