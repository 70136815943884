<div class="bg-white mt-3" *ngIf="belt">
  <div class="d-flex align-items-center mb-2">
    <i class="fa fa-check-circle fa-lg title-margin" *ngIf="complete()"></i>
    <i class="fa fa-times-circle fa-lg title-margin" *ngIf="incomplete()"></i>
    <div>Belt</div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="belt.orderNumber"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!belt.orderNumber"></i>
    <div class="small wrap">
      Belt Item Reference:
      <span class="grey">
        {{ belt.orderNumber ? belt.orderNumber : "N/A" }}
      </span>
    </div>
  </div>
  <div
    class="d-flex align-items-center"
    [ngClass]="{
      'optional-missing': emptyOptionalField(belt.conveyorId)
    }"
  >
    <i class="fa fa-check ms-1 me-3" *ngIf="belt.conveyorId"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!belt.conveyorId"></i>
    <div class="small wrap">
      Conveyor ID:
      <span class="grey">
        {{ belt.conveyorId ? belt.conveyorId : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="belt.rfidTagId"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!belt.rfidTagId"></i>
    <div class="small wrap">
      RFID Tag ID:
      <span class="grey">
        {{ belt.rfidTagId ? belt.rfidTagId : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="belt.width"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!belt.width"></i>
    <div class="small wrap">
      Width:
      <span class="grey">
        {{ belt.width ? belt.width : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="belt.length"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!belt.length"></i>
    <div class="small wrap">
      Length:
      <span class="grey">
        {{ belt.length ? belt.length : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="belt.salesLocationCode"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!belt.salesLocationCode"></i>
    <div class="small wrap">
      Sales Location Code:
      <span class="grey">
        {{ belt.salesLocationCode ? belt.salesLocationCode : "N/A" }}
      </span>
    </div>
  </div>
  <div
    class="d-flex align-items-center"
    [ngClass]="{
      'optional-missing': emptyOptionalField(belt.cpr)
    }"
  >
    <i class="fa fa-check ms-1 me-3" *ngIf="belt.cpr"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!belt.cpr"></i>
    <div class="small wrap">
      Customer Product Reference:
      <span class="grey">
        {{ belt.cpr ? belt.cpr : "N/A" }}
      </span>
    </div>
  </div>
</div>

<div class="bg-white mt-3" *ngIf="!belt">
  <div class="d-flex align-items-center">
    <i class="fa fa-times-circle fa-lg title-margin"></i>
    <div>No Belt for this Conveyor</div>
  </div>
</div>
