import { Injectable } from '@angular/core';
import { ModalOptions, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import {
  DeleteModalOptions,
  DeleteModalComponent,
} from './../../modals/delete-modal/delete-modal.component';
import {
  ConfirmModalOptions,
  ConfirmModalComponent,
} from './../../modals/confirm-modal/confirm-modal.component';

import {
  InfoModalOptions,
  InfoModalComponent,
} from 'src/app/modals/info-modal/info-modal.component';

@Injectable()
export class ModalService {
  public constructor(private bsModalService: BsModalService) {}

  public show(modal, options: ModalOptions = null): BsModalRef {
    if (!options) {
      options = { class: 'modal-lg' };
    }
    return this.bsModalService.show(modal, options);
  }

  public showDeleteModal(options: DeleteModalOptions): BsModalRef {
    return this.bsModalService.show(DeleteModalComponent, this.getModalOptions(options));
  }

  public showConfirmModal(options: ConfirmModalOptions): BsModalRef {
    return this.bsModalService.show(ConfirmModalComponent, this.getModalOptions(options));
  }

  public showInfoModal(options: InfoModalOptions): BsModalRef {
    return this.bsModalService.show(InfoModalComponent, this.getModalOptions(options));
  }

  private getModalOptions(options, modalClassSize: string = 'modal-lg'): ModalOptions {
    const modalOptions: ModalOptions = new ModalOptions();
    modalOptions.initialState = { options: options } as any;
    if (modalClassSize) {
      modalOptions.class = modalClassSize;
    }
    return modalOptions;
  }
}
