export class CBeltMachine {
  public id: string;

  public machineName: string;
  public aliasName: string;
  public organizationId: string;

  public static fromJson(json: {
    id: string;
    machineName: string;
    aliasName: string;
    organizationId: string;
  }): CBeltMachine {
    const machine = new CBeltMachine();
    machine.id = json.id;
    machine.machineName = json.machineName;
    machine.aliasName = json.aliasName;
    machine.organizationId = json.organizationId;
    return machine;
  }
}
