import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '../../../services/translate/translate.service';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrl: './filter-button.component.scss',
})
export class FilterButtonComponent {
  @Input() public hasActiveFilter: boolean;
  @Input() public filterCount: number;
  @Output() public filterButtonClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  public onFilterButtonClick(): void {
    this.filterButtonClick.emit();
  }

  public constructor(public t: TranslateService) {}
}
