import { BaseState } from '../base.state';

import { User } from 'src/app/services/auth/auth.service';

export class UserState extends BaseState {
  public currentUser?: User;
  public error?: unknown;

  public passwordResetInitiated: boolean;
  public passwordResetCompleted: boolean;
}

export const initialState: UserState = {
  currentUser: null,
  error: null,
  passwordResetInitiated: false,
  passwordResetCompleted: false,
};
