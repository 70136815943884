import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from '../../shared/services/alert/alert.service';
import { TranslateService } from '../../services/translate/translate.service';
import { BreadcrumbService } from './../../shared/services/breadcrumb/breadcrumb.service';
import { Language } from '../../models/language';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrl: './language.component.scss',
})
export class LanguageComponent {
  sub: Subscription;
  languageKey: any;
  languages: Language[] = [];

  public constructor(
    private alertService: AlertService,
    public t: TranslateService,
    private breadcrumbService: BreadcrumbService
  ) {}

  public ngOnInit(): void {
    this.languageKey = this.t.getLanguageKey();
    this.subscribeToLanguages();
    this.initBreadcrumbs();
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  private subscribeToLanguages() {
    this.sub = this.t.subscribeToTranslationData((data) => {
      this.languages = data.languages;
    });
  }

  private initBreadcrumbs(): void {
    this.breadcrumbService.setBreadcrumbs([]);
  }

  public onSelectLanguage(languageKey: unknown): void {
    this.languageKey = languageKey;
    this.setLanguage();
  }

  public setLanguage(): void {
    const language = this.languages.find((lang) => lang.key == this.languageKey);
    if (!language) return;
    if (!this.languageKey) {
      this.languageKey = language.key;
    }

    this.t.setLanguage(language);

    this.alertService.alertSuccess(this.t.translate('_SAVE_SUCCESS'));
  }
}
