<div class="inner-padding d-flex flex-column justify-content-between h-100">
  <div class="link-wrapper">
    <div class="p-4 d-flex justify-content-between align-items-end">
      <div class="image-wrapper">
        <div class="d-flex justify-content-center">
          <img src="/assets/icons/ammscan-512.png" />
        </div>
        <h5
          class="fw-700 text-end text-danger"
          *ngIf="!isProduction">
          {{ t.translate("_TESTING") }}
        </h5>
        <i
          class="material-symbols-outlined position-absolute top-0 end-0 mt-3 me-3 mobile"
          (click)="onClose()"
          >close</i
        >
      </div>
      <div class="d-flex justify-content-end">
        <i
          class="fis language-icon p-3 mt-3 cursor-pointer border"
          translate="no"
          [class]="'fi-' + currentLanguage?.country_flag"
          routerLinkActive="active"
          routerLink="/languages">
        </i>
      </div>
    </div>
    <hr />

    <div class="ps-3 small mb-3">{{ t.translate("_MENU") }}</div>
    <div class="d-flex flex-column">
      <a
        class="nav-link d-flex justify-content-between align-items-center rounded"
        routerLink="/surveys"
        routerLinkActive="active"
        (click)="navToRoute('/surveys')">
        <div class="d-flex align-items-center">
          <i class="fa fa-clipboard me-3"></i>
          <div>{{ t.translate("_SURVEYS") }}</div>
        </div>
        <i class="fa fa-chevron-right"></i>
      </a>

      <a
        *ngIf="isAdmin()"
        class="nav-link d-flex justify-content-between align-items-center rounded"
        routerLink="/translations"
        routerLinkActive="active"
        (click)="navToRoute('/translations')">
        <div class="d-flex align-items-center">
          <i class="fa fa-file-word-o me-3"></i>
          <div>{{ t.translate("_TRANSLATIONS") }}</div>
        </div>
        <i class="fa fa-chevron-right"></i>
      </a>

      <a
        *ngIf="isMobileOperatingSystem()"
        class="nav-link d-flex justify-content-between align-items-center rounded"
        routerLink="/c-belt"
        routerLinkActive="active"
        (click)="navToRoute('/c-belt')"
        routerLinkActive="active">
        <div class="d-flex align-items-center">
          <i class="fa fa-bullseye me-3"></i>
          <div>{{ t.translate("_C_BELT") }}</div>
        </div>
        <i class="fa fa-chevron-right"></i>
      </a>

      <a
        *ngIf="isMobileOperatingSystem()"
        class="nav-link d-flex justify-content-between align-items-center rounded"
        routerLink="/scanning"
        routerLinkActive="active"
        (click)="navToRoute('/scanning')"
        routerLinkActive="active">
        <div class="d-flex align-items-center">
          <i class="fa fa-rss me-3"></i>
          <div>{{ t.translate("_RFID_SCANNER") }}</div>
        </div>
        <i class="fa fa-chevron-right"></i>
      </a>

      <a
        *ngIf="isMobileOperatingSystem()"
        class="nav-link d-flex justify-content-between align-items-center rounded"
        routerLink="/scan-sensor"
        routerLinkActive="active"
        (click)="navToRoute('/scan-sensor')"
        routerLinkActive="active">
        <div class="d-flex align-items-center">
          <i class="fa fa-qrcode me-3"></i>
          <div>{{ t.translate("_SCAN_SENSOR") }}</div>
        </div>
        <i class="fa fa-chevron-right"></i>
      </a>
    </div>
  </div>

  <div class="d-flex flex-column">
    <hr />
    <div class="small ps-3 mb-2">{{ t.translate("_ACCOUNT") }}</div>
    <a class="nav-link d-flex justify-content-between align-items-center rounded">
      <div class="d-flex align-items-center gap-3 overflow-ellipsis">
        <i class="material-symbols-outlined">person</i>
        <div class="overflow-ellipsis">{{ getUserEmail() }}</div>
      </div>
    </a>

    <div class="text-center mt-2">
      <button
        class="d-flex align-items-center justify-content-center logout-btn btn w-100 mb-3"
        data-testid="logout-button"
        (click)="logout()">
        <i class="material-symbols-outlined me-2">logout</i>
        <span>{{ t.translate("_LOG_OUT") }}</span>
      </button>
      <div
        class="my-2 small"
        style="color: #ccc">
        <small class="small app-version">{{ t.translate("_VERSION") }}: {{ version }}</small>
      </div>
      <img src="../../../assets/images/powered-by-ammeraal.svg" />
    </div>
  </div>
</div>
