import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'confirm-modal.component.html',
})
export class ConfirmModalComponent {
  public options: ConfirmModalOptions;
  public hide: boolean = false;

  public constructor(private bsModalRef: BsModalRef) {}

  public onConfirm(): void {
    this.options.confirm();
    this.close();
  }

  public onReject(): void {
    if (this.options.reject) {
      this.options.reject();
    }
    this.close();
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public toggleHide(): void {
    this.hide = !this.hide;
  }
}

export class ConfirmModalOptions {
  public title: string;
  public message: string;
  public confirm: () => void;
  public reject?: () => void;
}
