/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerState } from './customer.state';

export const selectCustomers = createFeatureSelector<CustomerState>('customer');

export const selectAllCustomers = createSelector(selectCustomers, (state: CustomerState) => {
  return state.customers.filter((x) => !x.deleted);
});

export const selectCustomerById = (props: { id: string }) =>
  createSelector(selectAllCustomers, (customers) =>
    customers.find((customer) => customer.id == props.id)
  );
