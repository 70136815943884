<div class="mobile" *ngIf="sensor">
  <app-mobile-header
    [header]="'Edit Sensor'"
    [routerLinkUrl]="['/installing', companyId, 'sensor-configuration']"
  >
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <form name="configuration-form" class="d-flex">
      <div class="bg-white card p-3">
        <div>
          Sensor EDID: <span class="grey">{{ sensor.edid }}</span>
        </div>
        <div>Sensor Type: {{ returnSensorType(sensor.sid) }}</div>

        <label for="sensorAlias" class="form-label mt-3">Sensor Alias</label>
        <input
          [(ngModel)]="sensor.sensorNickname"
          class="form-control"
          name="sensorAlias"
          type="text"
          placeholder="Temperature Ambient"
        />

        <label for="description" class="form-label mt-3">Description</label>
        <textarea
          [(ngModel)]="sensor.description"
          name="description"
          type="text"
          placeholder="Optional: Enter a short description of the sensor in this text box. It can be viewed in the dashboard later"
          class="form-control"
          rows="3"
        ></textarea>

        <label *ngIf="returnSid()" for="sensorRole" class="form-label mt-3">Sensor Role</label>
        <div *ngIf="returnSid()" class="relative">
          <input
            [(ngModel)]="sensor.role"
            type="text"
            class="form-control input dropdown-toggle"
            placeholder="What is the Sensor Role?"
            name="sensorRole"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            aria-expanded="false"
          />
          <ul class="dropdown-menu position-static w-100">
            <li class="pointer">
              <a class="dropdown-item" (click)="selectRole('DriveDrum')">Drive Drum</a>
            </li>
            <li class="pointer">
              <a class="dropdown-item" (click)="selectRole('IdleDrum')">Idle Drum</a>
            </li>
          </ul>
        </div>
      </div>
    </form>

    <div class="d-flex">
      <button
        type="button"
        class="btn btn-primary mt-3"
        (click)="deleteAndNavToSensorConfiguration()"
      >
        Delete Sensor
      </button>
      <button
        type="button"
        class="btn btn-primary mt-3 ms-3"
        (click)="saveAndNavToSensorConfiguration()"
      >
        Save
      </button>
    </div>
  </div>
</div>
