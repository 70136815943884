<div class="mobile">
  <app-mobile-header
    [header]="'Calibrate conveyor reader'"
    [routerLinkUrl]="['/installing', companyId]"
  >
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <div class="bg-white card text-center p-3" *ngIf="!readerOn">
      <div>Please turn on the conveyor and ensure Bluetooth is turned on.</div>

      <button
        type="button"
        class="btn btn-primary mt-3"
        (click)="toggleReaderOn()"
      >
        NEXT STEP
      </button>
    </div>

    <div
      class="d-flex justify-content-center"
      *ngIf="readerOn && !connectedToDevice"
    >
      <button
        type="button"
        class="btn btn-primary mt-3 me-1"
        (click)="startScan()"
      >
        START SCANNING
      </button>

      <button
        type="button"
        class="btn btn-primary mt-3 ms-1"
        (click)="stopScan()"
      >
        STOP SCANNING
      </button>
    </div>

    <div
      class="bg-white card text-center p-3 my-3"
      *ngIf="readerOn && devices.length && !connectedToDevice"
    >
      <button
        *ngFor="let device of devices"
        type="button"
        class="btn btn-primary my-2 button"
        (click)="connectToDevice(device.name, device.address)"
      >
        {{ device.name + " " + device.address }}
      </button>
    </div>

    <div
      class="bg-white card text-center p-3 my-3"
      *ngIf="readerOn && connectedToDevice"
    >
      <div>
        <div>Connected to:</div>
        <strong class="py-3">{{ name + ": " + address }}</strong>
        <button
          *ngIf="readerOn && connectedToDevice && !servicesResolved"
          type="button"
          class="btn btn-primary my-2 button"
          (click)="fetchDeviceServices()"
        >
          RESOLVE SERVICES
        </button>
      </div>
    </div>

    <div
      class="bg-white card text-center p-3"
      *ngIf="readerOn && connectedToDevice && servicesResolved"
    >
      <div>
        In order for the device to run properly, the strength of the reader
        needs to be as low as possible, while still detecting the belt chip.
        Each time the chip passes the reader and is detected, an LED will blink.
      </div>

      <h4 class="d-flex justify-content-center mt-4">
        Current strength: {{ txPower.toString() }}/25
      </h4>

      <div class="d-flex justify-content-center mt-3">
        <div>
          <button
            type="button"
            class="btn btn-primary me-1"
            (click)="adjustTxPower('decrease')"
          >
            I see the LED
          </button>
          <div class="d-flex justify-content-center mt-1 grey">
            (decrease strength)
          </div>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary ms-1"
            (click)="adjustTxPower('increase')"
          >
            I don't see the LED
          </button>
          <div class="d-flex justify-content-center mt-1 grey">
            (increase strength)
          </div>
        </div>
      </div>
    </div>

    <button
      *ngIf="readerOn && connectedToDevice"
      type="button"
      class="btn btn-primary mt-3"
      (click)="navToInstallingHome()"
    >
      SAVE
    </button>
  </div>
</div>
