import { Survey } from '../../models/survey.model';
import { BaseState } from '../base.state';

export class SurveyState extends BaseState {
  public surveys: Survey[];
}

export const initialState = {
  surveys: [],
};
