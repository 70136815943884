import { Action, createReducer, on } from '@ngrx/store';

import { initialState, CustomerState } from './customer.state';
import { StateUtils } from '../state-utils';

import * as CustomerActions from './customer.actions';
import * as UserActions from '../user/user.actions';

const customerReducer = createReducer(
  initialState,

  on(CustomerActions.resetCustomerState, () => ({
    ...initialState,
  })),

  on(CustomerActions.fetchCustomers, (state) => ({
    ...state,
    loading: true,
  })),
  on(CustomerActions.fetchCustomersSuccess, (state, { customers }) => {
    return {
      ...state,
      customers: StateUtils.combineStateArr(state.customers, customers),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(CustomerActions.fetchCustomersFailed, (state) => ({
    ...state,
    loading: false,
  })),

  on(CustomerActions.setCustomersSuccess, (state, { customers }) => {
    return {
      ...state,
      customers,
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(CustomerActions.updateCustomersSuccess, (state, { customers }) => {
    return {
      ...state,
      customers: StateUtils.combineStateArr(state.customers, customers),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(CustomerActions.addCustomerSuccess, (state, { customer }) => {
    return {
      ...state,
      customers: StateUtils.combineStateArr(state.customers, [customer]),
      loading: false,
      loadedAt: new Date(),
    };
  }),

  on(UserActions.logoutSuccess, () => {
    return initialState;
  }),
);

export function reducer(state: CustomerState | undefined, action: Action): CustomerState {
  return customerReducer(state, action);
}
