import { cloneDeep } from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CBelt } from 'src/app/models/cBelt.model';
import { Customer } from 'src/app/models/customer.model';
import { Conveyor } from 'src/app/models/conveyor.model';
import { CBeltMachine } from 'src/app/models/cBeltMachine.model';

import { BeltsService } from 'src/app/services/c-belt/belts.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-system-setup-pair-rfid',
  templateUrl: './system-setup-pair-rfid.component.html',
  styleUrl: './system-setup-pair-rfid.component.scss',
})
export class SystemSetupPairRfidComponent implements OnInit, OnDestroy {
  public editing: boolean;
  public companyId: string;
  public customer: Customer;
  public current: ConfigurationState = null;
  public containsRfidReader: boolean = true;

  public belts: CBelt[] = [];
  public conveyors: Conveyor[] = [];

  public constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private beltService: BeltsService,
    private loadingService: LoadingService,
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(InstallationActions.clearCurrentMachine());
    this.store.dispatch(InstallationActions.clearCurrentConveyor());

    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });

    this.fetchAllBelts();
  }

  public ngOnDestroy(): void {
    this.store.dispatch(InstallationActions.togglePairingRfid({ pairingRfid: false }));
  }

  private async fetchAllBelts(): Promise<void> {
    this.loadingService.show();
    try {
      this.belts = await this.beltService.getAllBelts();
    } catch (err) {
      console.log(err);
      console.log('Failed to fetch belts');
    }
    this.loadingService.hide();
  }

  public isBeltAlreadyPairedToConveyor(conveyorId: string): boolean {
    return this.belts.some((belt) => belt.conveyorId == conveyorId);
  }

  private subscribeToCurrentConfiguration(): void {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.customer = this.current.company;
        this.containsRfidReader = this.current.containsRfidReader;
        this.editing = this.current.editing;
      });
  }

  public fetchConveyorsForMachine(id: string): Conveyor[] {
    return this.current.conveyors.filter((conveyor) => conveyor.machineId == id);
  }

  public selectConveyorToPairRfid(machine: CBeltMachine, conveyor: Conveyor): void {
    this.store.dispatch(InstallationActions.setCurrentMachine({ machine: machine }));
    this.store.dispatch(InstallationActions.setCurrentConveyor({ conveyor: conveyor }));
    this.store.dispatch(InstallationActions.togglePairingRfid({ pairingRfid: true }));
    this.router.navigate(['/installing', this.companyId, 'system-setup-pair-rfid', 'select-belt']);
  }
}
