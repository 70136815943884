import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SortColumn } from '../../directives/sortable.directive';
import { TranslateService } from '../../../services/translate/translate.service';
import { UtilService } from './../../../utils/util.service';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() public table: Table;
  @Input() public sortColumn: SortColumn;
  @Input() public isSticky: boolean = false;
  @Output() public rowClick: EventEmitter<unknown> = new EventEmitter();

  public tableHasActions: boolean = false;

  @Input() public sortDirection: string = 'ASC';

  public rows: { [columnName: string]: unknown }[] = [];

  public constructor(public utils: UtilService, public t: TranslateService) {
    t.translate('_DATE');
    t.translate('_SYNC');
    t.translate('_CUSTOMER_SITE');
    t.translate('_BELTS');
    t.translate('_OWNER');
    t.translate('_IMAGE');

    document.addEventListener('scroll', () => {
      const element = document.body;

      const scroll = window.scrollY;
      const scrollAvailable = element.scrollHeight - element.clientHeight;
      const available = 100 - (scroll / scrollAvailable) * 100;

      if (available < 20) this.onScrollDown();
    });
  }

  public ngOnChanges(changes: { table: unknown }): void {
    if (changes.table) {
      this.tableHasActions = this.table?.rows.some((x) => x['ACTIONS']);

      if (this.sortColumn) {
        this.sortByColumn(this.sortColumn, this.sortDirection);
      }

      this.populateRows();
    }
  }

  private populateRows(): void {
    if (!this.table?.rows?.length) {
      this.rows = [];
      return;
    }

    const tableRows = Math.min(20, this.table.rows.length);
    const displayedRows = Math.min(this.rows.length, this.table.rows.length);
    const maxItems = Math.max(tableRows, displayedRows);

    const rows = [];
    for (let i = 0; i < maxItems; i++) {
      rows.push(this.table.rows[i]);
    }
    this.rows = rows;
  }

  public onScrollDown(): void {
    if (this.rows.length < this.table.rows.length) {
      const endIndex = Math.min(this.rows.length + 20, this.table.rows.length);
      for (let i = this.rows.length; i < endIndex; i++) {
        this.rows.push(this.table.rows[i]);
      }
    }
  }

  public returnZero(): number {
    return 0;
  }

  public onRowClick(row: { id: unknown }, event?: unknown): void {
    this.rowClick.emit({ id: row.id, event });
  }

  public onColumnClick(columnName: SortColumn): void {
    if (this.sortColumn == columnName) {
      this.sortDirection = this.sortDirection == 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sortDirection = 'ASC';
    }
    this.sortByColumn(columnName, this.sortDirection);
  }

  public sortByColumn(columnName: SortColumn, sortDirection: string): void {
    if (!this.table?.rows?.length) return;

    this.sortColumn = columnName;
    this.table.rows = this.sortArray(this.table?.rows, this.sortColumn, sortDirection);
    this.populateRows();
  }

  public sortArray(array: unknown[], sortColumn: SortColumn, sortDirection: string): unknown[] {
    if (!array) return [];

    return this.utils.sortAlphaNumeric(array, sortColumn, sortDirection == 'ASC');
  }

  public getFormattedDate(value: Date): string {
    const fullDate = new Date(value);

    const day = fullDate.getDate();
    const month = MONTHS[fullDate.getMonth()].substring(0, 3);
    const year = fullDate.getFullYear();
    const hour = fullDate.getHours();
    const minutes = this.twoDigitString(fullDate.getMinutes());

    const date = `${day} ${month}, ${year}`;
    const time = hour + ':' + minutes;

    return `<div>${date}</div><div class="text-muted small">${time}</div>`;
  }

  private twoDigitString(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  public getTableTranslationKeys(rowName: string): string {
    const rowNameSubstring: string[] = rowName.split(' ');
    let rowNameTranslationKey: string = '';
    rowNameSubstring.forEach((string) => {
      rowNameTranslationKey += `_${string}`;
    });
    return rowNameTranslationKey;
  }
}

export class Table {
  public rows: { [columnName: string]: any }[];
}
