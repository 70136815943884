<div class="bg-white card mt-3 p-3" *ngIf="conveyor">
  <app-conveyor-reader-validation
    [conveyorReader]="conveyorReader"
    [containsRfidReader]="containsRfidReader"
  ></app-conveyor-reader-validation>

  <div class="d-flex align-items-center mb-2 mt-3">
    <i class="fa fa-check-circle fa-lg title-margin" *ngIf="complete()"></i>
    <i class="fa fa-times-circle fa-lg title-margin" *ngIf="incomplete()"></i>
    <div>Conveyor</div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyor.id"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyor.id"></i>
    <div class="small wrap">
      ID:
      <span class="grey">
        {{ conveyor.id ? conveyor.id : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyor.machineId"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyor.machineId"></i>
    <div class="small wrap">
      Machine ID:
      <span class="grey">
        {{ conveyor.machineId ? conveyor.machineId : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyor.aliasName"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyor.aliasName"></i>
    <div class="small wrap">
      Alias Name:
      <span class="grey">
        {{ conveyor.aliasName ? conveyor.aliasName : "N/A" }}
      </span>
    </div>
  </div>
  <div
    class="d-flex align-items-center"
    [ngClass]="{ 'optional-missing': emptyOptionalField(conveyor.description) }"
  >
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyor.description"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyor.description"></i>
    <div class="small wrap">
      Description:
      <span class="grey">
        {{ conveyor.description ? conveyor.description : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyor.diameter"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyor.diameter"></i>
    <div class="small wrap">
      Drive Drum Diameter:
      <span class="grey">
        {{ conveyor.diameter ? conveyor.diameter : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="conveyor.idleDrumDiameter"></i>
    <i class="fa fa-times ms-1 small-margin" *ngIf="!conveyor.idleDrumDiameter"></i>
    <div class="small wrap">
      Idle Drum Diameter:
      <span class="grey">
        {{ conveyor.idleDrumDiameter ? conveyor.idleDrumDiameter : "N/A" }}
      </span>
    </div>
  </div>

  <app-belt-validation [conveyor]="conveyor"></app-belt-validation>

  <app-sensor-validation
    [sensors]="sensors"
    [containsRfidReader]="containsRfidReader"
  ></app-sensor-validation>
</div>

<div class="bg-white card mt-3 p-3" *ngIf="!conveyor">
  <div class="d-flex align-items-center">
    <i class="fa fa-times-circle fa-lg title-margin"></i>
    <div>No Conveyors</div>
  </div>
</div>
