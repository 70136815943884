import { Component } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss',
})
export class LogoutComponent {
  public async ngOnInit(): Promise<void> {
    const redirectUrl = environment.loginRedirectUri;
    window.location.href =
      'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=' +
      redirectUrl;
  }
}
