import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Injectable({ providedIn: 'root' })
export class StateService {
  private currentSub: Subscription;
  private current: ConfigurationState = null;
  private history: ConfigurationState[] = [];

  public constructor(private store: Store) {}

  public initState(): void {
    this.store.dispatch(InstallationActions.resetState());
  }

  public subscribeToCurrentAndSave(): void {
    if (this.currentSub) this.currentSub.unsubscribe();

    const select = InstallationSelectors.selectCurrentSummary();
    this.currentSub = this.store.select(select).subscribe((current) => {
      this.current = cloneDeep(current);
      this.saveCurrentToLocalStorage();
    });
  }

  public saveCurrentToLocalStorage(): void {
    const serializedCurrent = JSON.stringify(this.current);
    localStorage.setItem('current-state', serializedCurrent);
  }

  public loadCurrentFromLocalStorage(): ConfigurationState {
    const serializedCurrent = localStorage.getItem('current-state');
    if (!serializedCurrent) return null;

    const current = JSON.parse(serializedCurrent);
    return ConfigurationState.fromJson(current);
  }

  public addCurrentToHistory(current: ConfigurationState): void {
    this.history = this.loadConfigurationHistory();
    this.history.push(current);
    const serializedHistory = JSON.stringify(this.history);
    localStorage.setItem('c-belt-history', serializedHistory);
  }

  public loadConfigurationHistory(): ConfigurationState[] {
    const serializedHistory = localStorage.getItem('c-belt-history');
    const history = JSON.parse(serializedHistory ?? '[]');

    this.history = history.map((x) => ConfigurationState.fromJson(x));

    return this.history;
  }

  public updateConfigurationHistory(current: ConfigurationState): void {
    const configToReplace = this.history.find((x) =>
      x.conveyors.find((y) => y.machineId == current.conveyors[0].machineId)
    );

    const index = this.history.indexOf(configToReplace);
    if (index != -1) {
      this.history[index] = current;
    }

    const serializedHistory = JSON.stringify(this.history);
    localStorage.setItem('c-belt-history', serializedHistory);
  }

  public removeConfigFromHistory(config: ConfigurationState): void {
    if (config.conveyors.length == 0) return;

    this.history = this.history.filter(
      (historyConfig) =>
        !historyConfig.conveyors.some((conveyor) =>
          config.conveyors.some((inputConveyor) => inputConveyor.machineId == conveyor.machineId)
        )
    );

    const serializedHistory = JSON.stringify(this.history);
    localStorage.setItem('c-belt-history', serializedHistory);
  }
}
