import { Customer } from '../../models/customer.model';
import { BaseState } from '../base.state';

export interface CustomerState extends BaseState {
  customers: Array<Customer>;
}

export const initialState: CustomerState = {
  customers: [],
};
