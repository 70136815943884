import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrl: './switch.component.scss',
})
export class SwitchComponent {
  @Input() public checked: boolean = false;
  @Output() public checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
}
