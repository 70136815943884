<div class="d-flex align-items-center justify-content-center px-3 fab-btn rounded bounce position-fixed start-0 cursor-pointer mobile"
    (click)="onInstallClick(iosInstallDialog)" *ngIf="isInstallable && !isInstalled">
    <div>
        <i class="material-symbols-outlined">install_mobile</i>
    </div>
    <div class="ps-3 fw-500">Install</div>
</div>

<ng-template #iosInstallDialog let-modal>
    <div class="ios-install-dialog">
        <h4>
            Install AMMscan on iOS
        </h4>

        <div class="mb-2">
            <div class="d-flex align-items-center">
                <span>1. Tap Share </span>
                <i class="ps-3 pb-2 material-symbols-outlined">ios_share</i>
            </div>
            <div class="d-flex align-items-center">
                <span>2. Tap Add to Home Screen </span>
                <i class="ps-3 material-symbols-outlined">add_box</i>
            </div>
            <div class="d-flex align-items-center">
                <span>3. Tap Done </span>
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary" (click)="iosInstallDialog.close()">
                Close
            </button>
        </div>
    </div>
</ng-template>