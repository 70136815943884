<div>
  <i
    class="material-symbols-outlined cursor-pointer"
    (click)="isMenuOpen = !isMenuOpen"
    translate="no"
  >
    {{ symbol }}
  </i>

  <div
    class="shade w-screen h-screen"
    (click)="isMenuOpen = false"
    *ngIf="isMenuOpen"
    [@fadeInOut]
  ></div>

  <div
    class="meta-menu position-absolute d-flex flex-column bg-white shadow text-black z-100"
    [@fadeInOut]
    *ngIf="isMenuOpen"
  >
    <ng-container *ngFor="let menuItem of menuItems">
      <div
        class="d-flex align-items-center menu-item p-3 text-nowrap"
        (click)="clickHandler(menuItem.onClick)"
      >
        <i class="material-symbols-outlined pe-3" translate="no">
          {{ menuItem.symbol }}
        </i>
        <span>
          {{t.translate(menuItem.title)}}
        </span>
      </div>
      <md-divider></md-divider>
    </ng-container>
  </div>
</div>
