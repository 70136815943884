import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Sensor } from 'src/app/models/sensor.model';

import { SensorsService } from 'src/app/services/c-belt/sensors.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { LoadingService } from './../../../../shared/services/loading/loading.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-add-sensor-manually',
  templateUrl: './add-sensor-manually.component.html',
  styleUrls: ['./add-sensor-manually.component.scss'],
})
export class AddSensorManuallyComponent implements OnInit {
  public baseRoute: string;
  public companyId: string;
  public sensor: Sensor = new Sensor();

  public current: ConfigurationState = null;

  public constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private sensorService: SensorsService,
    private loadingService: LoadingService,
  ) {}

  public ngOnInit(): void {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  public subscribeToCurrentConfiguration(): void {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        if (!this.current.containsRfidReader && this.current.newSensorId) {
          this.fetchMagneticSensor();
        } else if (this.current.newSensorId) {
          this.sensor.edid = this.current.newSensorId;
          this.findSensorByEdid(this.current.newSensorId);
        }
      });
  }

  public async fetchMagneticSensor(): Promise<void> {
    this.loadingService.show();
    try {
      this.sensor = await this.findSensorByEdid(this.current.newSensorId);
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();
  }

  public selectRole(role: string): void {
    this.sensor.role = role;
  }

  public selectSensorType(sensorType: string): void {
    this.sensor.sensorType = sensorType;
  }

  public navToAddSensorNfc(): void {
    this.router.navigate([
      this.baseRoute,
      this.companyId,
      'sensor-configuration',
      'add-edit-sensors',
    ]);
  }

  public async navToSensorConfiguration(): Promise<void> {
    this.loadingService.show();
    try {
      if (!this.isValid()) {
        this.alertService.alertError('Please complete all fields');
        this.loadingService.hide();
        return;
      }

      if (this.sensor.conveyorId) {
        return;
      }

      if (!this.current.gateway.gatewayDeviceId) {
        this.alertService.alertError('Cannot map Sensor without Gateway');
      }

      await this.updateSensor(this.sensor);
      await this.mapSensorAndGateway();

      this.router.navigate([this.baseRoute, this.companyId, 'sensor-configuration']);
    } catch (err) {
      this.loadingService.hide();
      console.log(err);
    }
    this.loadingService.hide();
  }

  public async findSensorByEdid(edid: string): Promise<Sensor> {
    this.loadingService.show();
    let sensor: Sensor;

    const result = await this.sensorService.getSensorsByEdid(edid);

    if (!result.length) {
      this.alertService.alertError(`No Sensors with EDID: ${edid}`);
      this.router.navigate([
        this.baseRoute,
        this.companyId,
        'sensor-configuration',
        'add-edit-sensors',
      ]);
    } else {
      sensor = result[0];
      this.sensor = result[0];
      if (Number(this.sensor.edid) == 5) {
        await this.fetchMagneticSensor();
      }
    }
    this.loadingService.hide();
    return sensor;
  }

  public async updateSensor(sensor: Sensor): Promise<Sensor> {
    let response: Sensor = null;

    const payload = {
      conveyorId: this.current.conveyor.id,
      aliasName: this.sensor.sensorNickname,
      description: this.sensor.description ? this.sensor.description : '',
      role: this.sensor.role ? this.sensor.role : null,
    };

    await this.sensorService.updateSensor(payload, sensor.id);

    response = Object.assign(sensor, payload);
    this.store.dispatch(InstallationActions.updateSensor({ sensor: response }));
    this.store.dispatch(InstallationActions.updateNewSensorId({ sensorId: null }));

    return response;
  }

  public async mapSensorAndGateway(): Promise<void> {
    await this.sensorService.mapSensorAndGateway(
      this.sensor.id,
      this.current.gateway.gatewayDeviceId,
    );
    this.alertService.alertSuccess('Sensor and Gateway Mapped Successfully!');
  }

  public returnSid(): boolean {
    return this.sensor.sid == '5';
  }

  public isValid(): boolean {
    if (this.sensor.edid && Number(this.sensor.edid) == 5 && !this.sensor.role) {
      return false;
    }
    if (!this.current.gateway.gatewayDeviceId) {
      this.alertService.alertError('No GatewayId found to map with Sensors');
      return false;
    }
    return this.sensor.edid != null && this.sensor.sensorNickname != '';
  }
}
