<div
  class="mobile"
  *ngIf="customer && current && !editing && !loading">
  <app-mobile-header
    [header]="returnTitle()"
    [routerLinkUrl]="['/installing']">
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <div class="list-group list-group-mine">
      <div class="d-flex justify-content-between mx-1 mb-3">
        <div class="fw-bold">Configuration with RFID Sensor Reader</div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            (change)="toggleRfidReader($event)"
            style="transform: scale(1.8)"
            [checked]="containsRfidReader" />
        </div>
      </div>
      <a
        class="d-flex align-items-center nav-link list-group-item list-group-item-action pointer border-10 mb-3"
        [routerLink]="['/installing', companyId, 'gateway-configuration']">
        <div class="d-flex align-items-center">
          <div>
            <div
              *ngIf="!current.gateways.length"
              class="incomplete"></div>
            <div
              *ngIf="current.gateways.length"
              class="complete"></div>
          </div>
          <div>
            <div class="fw-bold">Setting up internet access</div>
            <div class="grey">Select existing or set up a new wi-fi device</div>
          </div>
        </div></a
      >
      <a
        *ngIf="containsRfidReader"
        class="d-flex align-items-center nav-link list-group-item list-group-item-action pointer border-10 mb-3"
        [routerLink]="['/installing', companyId, 'system-setup']">
        <div class="d-flex align-items-center">
          <div>
            <div>
              <div
                *ngIf="!current.conveyors.length"
                class="incomplete"></div>
              <div
                *ngIf="current.conveyors.length"
                class="complete"></div>
            </div>
          </div>
          <div>
            <div class="fw-bold">Setting up the conveyor and its reader</div>
            <div class="grey">Enter conveyor details and pair the reader box</div>
          </div>
        </div></a
      >
      <a
        *ngIf="!containsRfidReader"
        class="d-flex align-items-center nav-link list-group-item list-group-item-action pointer border-10 mb-3"
        [routerLink]="['/installing', companyId, 'system-setup']">
        <div class="d-flex align-items-center">
          <div>
            <div>
              <div
                *ngIf="!current.conveyors.length"
                class="incomplete"></div>
              <div
                *ngIf="current.conveyors.length"
                class="complete"></div>
            </div>
          </div>
          <div>
            <div class="fw-bold">Setting up the conveyor</div>
            <div class="grey">Enter conveyor details</div>
          </div>
        </div></a
      >
      <a
        *ngIf="containsRfidReader"
        class="d-flex align-items-center nav-link list-group-item list-group-item-action pointer border-10 mb-3"
        (click)="navToConveyorCalibration()">
        <div class="d-flex align-items-center">
          <div>
            <div
              *ngIf="!current.readerCalibrated"
              class="incomplete"></div>
            <div
              *ngIf="current.readerCalibrated"
              class="complete"></div>
          </div>
          <div>
            <div class="fw-bold">Calibrating the conveyor reader</div>
            <div class="grey">Make sure the reader has the right strength</div>
          </div>
        </div>
      </a>
      <a
        class="d-flex align-items-center nav-link list-group-item list-group-item-action pointer border-10"
        (click)="navToSensorConfiguration()">
        <div class="d-flex align-items-center">
          <div>
            <div
              *ngIf="!current.sensors.length"
              class="incomplete"></div>
            <div
              *ngIf="current.sensors.length"
              class="complete"></div>
          </div>
          <div>
            <div class="fw-bold">Pairing and setting up sensors</div>
            <div class="grey">Connect sensors to the CBLT system</div>
          </div>
        </div>
      </a>
    </div>

    <button
      type="button"
      class="btn btn-primary mt-4"
      (click)="navToConfigurationOverview()">
      FINAL CHECK AND TEST
    </button>
  </div>
</div>

<div
  class="mobile"
  *ngIf="customer && current && editing && !loading">
  <app-mobile-header
    [header]="returnTitle()"
    [routerLinkUrl]="['/installing']">
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <div class="list-group list-group-mine">
      <a
        class="d-flex align-items-center nav-link list-group-item list-group-item-action pointer border-10 mb-3"
        [routerLink]="['/installing', companyId, 'gateway-configuration']">
        <div class="d-flex align-items-center">
          <div>
            <div
              *ngIf="!current.gateway"
              class="incomplete"></div>
            <div
              *ngIf="current.gateway"
              class="complete"></div>
          </div>
          <div>
            <div class="fw-bold">Internet access</div>
            <div class="grey">Manage wi-fi devices</div>
          </div>
        </div></a
      >
      <a
        *ngIf="containsRfidReader"
        class="d-flex align-items-center nav-link list-group-item list-group-item-action pointer border-10 mb-3"
        [routerLink]="['/installing', companyId, 'conveyor-reader-configuration']">
        <div class="d-flex align-items-center">
          <div>
            <div>
              <div
                *ngIf="!current.conveyors.length"
                class="incomplete"></div>
              <div
                *ngIf="current.conveyors.length"
                class="complete"></div>
            </div>
          </div>
          <div>
            <div class="fw-bold">Conveyor and reader</div>
            <div class="grey">Manage the conveyor and the reader</div>
          </div>
        </div></a
      >
      <a
        *ngIf="!containsRfidReader"
        class="d-flex align-items-center nav-link list-group-item list-group-item-action pointer border-10 mb-3"
        [routerLink]="['/installing', companyId, 'system-setup']">
        <div class="d-flex align-items-center">
          <div>
            <div>
              <div
                *ngIf="!current.conveyors.length"
                class="incomplete"></div>
              <div
                *ngIf="current.conveyors.length"
                class="complete"></div>
            </div>
          </div>
          <div>
            <div class="fw-bold">Conveyor</div>
            <div class="grey">Manage the conveyor</div>
          </div>
        </div></a
      >
      <a
        *ngIf="containsRfidReader"
        class="d-flex align-items-center nav-link list-group-item list-group-item-action pointer border-10 mb-3"
        (click)="navToConveyorCalibration()">
        <div class="d-flex align-items-center">
          <div>
            <div
              *ngIf="!current.readerCalibrated"
              class="incomplete"></div>
            <div
              *ngIf="current.readerCalibrated"
              class="complete"></div>
          </div>
          <div>
            <div class="fw-bold">Re-calibrate reader</div>
            <div class="grey">Make sure the reader has the right strength</div>
          </div>
        </div>
      </a>
      <a
        class="d-flex align-items-center nav-link list-group-item list-group-item-action pointer border-10"
        (click)="navToSensorConfiguration()">
        <div class="d-flex align-items-center">
          <div>
            <div
              *ngIf="!current.sensors.length"
              class="incomplete"></div>
            <div
              *ngIf="current.sensors.length"
              class="complete"></div>
          </div>
          <div>
            <div class="fw-bold">Sensors</div>
            <div class="grey">Manage, add or remove sensors</div>
          </div>
        </div>
      </a>
    </div>

    <button
      type="button"
      class="btn btn-primary mt-4"
      (click)="navToConfigurationOverview()">
      FINAL CHECK AND TEST
    </button>
  </div>
</div>

<div
  *ngIf="loading"
  class="center">
  <div class="spinner-border text-primary"></div>
</div>
