<div
  class="mobile"
  *ngIf="!editing">
  <app-mobile-header
    [header]="'Add or select a wi-fi device'"
    [routerLinkUrl]="['/installing', companyId]">
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <form
      name="configuration-form"
      class="d-flex">
      <div class="bg-white card p-3">
        <label
          for="newDevice"
          class="form-label"
          >New wi-fi device</label
        >
        <button
          type="button"
          class="btn btn-primary"
          (click)="navToNewDevice(false, null)">
          Set Up
        </button>
        <div class="mt-5">Existing CBLT wi-fi devices for this customer</div>
        <hr />
        <div
          class="d-flex align-items-center justify-content-between mb-3"
          *ngFor="let gateway of gatewayDevices">
          <div>
            {{ gateway.gatewayDeviceId ? truncate(gateway.gatewayDeviceId) : gateway.wifiType }}
          </div>
          <button
            type="button"
            class="btn btn-primary ms-3"
            (click)="navToNewDevice(true, gateway)">
            Use
          </button>
        </div>
        <div
          class="d-flex align-items-center"
          *ngIf="!this.gatewayDevices.length">
          No wi-fi devices
        </div>
      </div>
    </form>
  </div>
</div>

<div
  class="mobile"
  *ngIf="editing">
  <app-mobile-header
    [header]="'Wi-fi device'"
    [routerLinkUrl]="['/installing', companyId]">
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <form
      name="configuration-form"
      class="d-flex">
      <div class="bg-white card p-3">
        <div
          class="d-flex align-items-center justify-content-between mb-3"
          *ngIf="this.gatewayDevice">
          <div>
            <div>Current wi-fi device:</div>
            <div>
              {{
                gatewayDevice.gatewayDeviceId
                  ? truncate(gatewayDevice.gatewayDeviceId)
                  : gatewayDevice.wifiType
              }}
            </div>
          </div>
          <button
            type="button"
            class="btn btn-primary ms-3"
            (click)="navToNewDevice()">
            CHANGE
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
