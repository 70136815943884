import { BaseState } from '../base.state';
import { Belt } from '../../models/belt.model';

export class BeltState extends BaseState {
  public belts: Belt[];
  public syntheticMaterials: any[];
  public modularMaterials: any[];
  public modularTypes: any[];
  public modularColours: any[];
}

export const initialState: BeltState = {
  belts: [],
  syntheticMaterials: [],
  modularMaterials: [],
  modularTypes: [],
  modularColours: [],
};
