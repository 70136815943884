import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class CBeltAuthGuard {
  public constructor(private authService: AuthService) {}

  public async canActivate(): Promise<boolean> {
    return !!this.authService.getUser();
  }

  public async canActivateChild(): Promise<boolean> {
    return this.canActivate();
  }
}
