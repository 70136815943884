import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { CBeltAuthGuard } from './guards/c-belt-auth.guard';
import { MsalTokenService } from './services/c-belt/msal-token.service';

import { LanguageComponent } from './pages/language/language.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { TranslationsComponent } from './pages/translations/translations.component';

import { LogoutComponent } from './pages/logout/logout.component';

const guard = MsalTokenService.isMobileApp() ? CBeltAuthGuard : MsalGuard;

const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '',
    canActivate: [guard],
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'surveys',
      },
      {
        path: 'translations',
        canActivate: [guard],
        component: TranslationsComponent,
      },
      {
        path: 'languages',
        component: LanguageComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'surveys',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
