<div class="d-flex gap-2 border-bottom p-2">
  <div
    class="thumbnail bg-secondary rounded"
    (click)="onItemClick({ id: belt.id, event: $event })">
    <img
      [src]="belt.attachmentUrl || belt.image?.displayImage"
      *ngIf="hasValidImage(belt) && !isBase64(belt)"
      loading="lazy" />
    <img
      [src]="belt.image?.displayImage"
      *ngIf="hasValidImage(belt) && isBase64(belt)"
      loading="lazy" />
    <i
      class="material-symbols-outlined no-image w-100 text-primary flex-center"
      *ngIf="!hasValidImage(belt)"
      >question_mark</i
    >
  </div>
  <div class="content d-flex flex-column justify-content-between">
    <div class="header d-flex">
      <div
        class="d-flex gap-2 sync-name"
        (click)="onItemClick({ id: belt.id, event: $event })">
        <div class="sync">
          <i
            class="material-symbols-outlined text-muted d-block"
            *ngIf="belt.dirty"
            >cloud_off</i
          >
          <i
            class="material-symbols-outlined text-success d-block"
            *ngIf="!belt.dirty"
            >cloud_done</i
          >
        </div>

        <div class="name overflow-ellipsis">
          {{ belt.productReference }}
        </div>
      </div>

      <div class="menu position-relative">
        <app-meta-menu [menuItems]="menuItems"></app-meta-menu>
      </div>
    </div>

    <div
      class="details small d-flex flex-column justify-content-between h-100"
      (click)="onItemClick({ id: belt.id, event: $event })">
      <div class="text-muted">
        <div class="production-line small">
          {{ belt.productionLine || t.translate("_NO_PRODUCTION_LINE") }}
        </div>

        <div class="measurements small">{{ belt.length || "0" }}x{{ belt.width || "0" }}mm</div>
      </div>

      <div class="tags small d-flex justify-content-between gap-2">
        <div class="tag-wrapper d-flex gap-1">
          <div
            class="wtl bg-secondary text-white px-2 rounded"
            *ngIf="belt.wtl">
            #{{ belt.wtl }}
          </div>
          <div
            class="type text-white px-2 rounded"
            [class]="belt.synOrMod"
            *ngIf="belt.synOrMod">
            {{ componentTranslations(belt.synOrMod) }}
          </div>
        </div>

        <div class="date text-nowrap">
          {{ belt.created | date }}
        </div>
      </div>
    </div>
  </div>
</div>
