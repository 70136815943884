import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import * as CscSelectors from '../../state/csc/csc.selector';
import * as UserSelectors from '../../state/user/user.selector';
import { CscState } from '../../state/csc/csc.state';
import { UserState } from '../../state/user';

import { TranslateService } from '../../services/translate/translate.service';
import { OnlineService } from '../../services/online-service/online.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  public online: boolean;
  private subscriptions: Subscription[] = [];

  public $cscs = this.store.select(CscSelectors.selectCscs);
  public $user = this.store.select(UserSelectors.selectUser);

  public constructor(
    private store: Store,
    private onlineService: OnlineService,
    public t: TranslateService,
  ) {}

  public ngOnInit(): void {
    const onlineSub = this.onlineService.subscribe((online) => (this.online = online));
    this.subscriptions.push(onlineSub);
  }

  public ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public onReloadPageClicked(): void {
    window.location.reload();
  }

  public isCscsStateValid(cscState: CscState): boolean {
    if (!cscState || !cscState.loadedAt) return true;
    return !!cscState.cscs.length;
  }

  public isUserStateValid(userState: UserState): boolean {
    if (!userState || !userState.loadedAt) return true;
    return !!userState.currentUser;
  }
}
