var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { appstraxAuth } from './auth';
import { utils } from './shared/utils';
export class AppstraxServices {
    static init(urls) {
        return __awaiter(this, void 0, void 0, function* () {
            urls.authUrl != null ? (this.authBaseUrl = urls.authUrl) : null;
            urls.storageUrl != null ? (this.storageBaseUrl = urls.storageUrl) : null;
            urls.databaseUrl != null ? (this.databaseBaseUrl = urls.databaseUrl) : null;
            if (urls.authUrl) {
                yield appstraxAuth.init();
            }
        });
    }
    static hasAuthUrl() {
        return !!this.authBaseUrl;
    }
    static getAuthUrl(extension) {
        if (this.authBaseUrl) {
            return utils.pathJoin(this.authBaseUrl, 'api/auth', extension);
        }
        else {
            throw new Error('baseUrl missing, initialize Auth first.');
        }
    }
    static getUserUrl(extension) {
        if (this.authBaseUrl) {
            return utils.pathJoin(this.authBaseUrl, 'api/user', extension);
        }
        else {
            throw new Error('baseUrl missing, initialize Auth first.');
        }
    }
    static getDatabaseUrl(extension) {
        if (this.databaseBaseUrl) {
            return utils.pathJoin(this.databaseBaseUrl, '/api/collection', extension);
        }
        else {
            throw new Error('baseUrl missing, initialize Database first.');
        }
    }
    static getStorageUrl(extension) {
        if (this.storageBaseUrl) {
            return utils.pathJoin(this.storageBaseUrl, 'api/file', extension);
        }
        else {
            throw new Error('baseUrl missing, initialize Storage first.');
        }
    }
}
AppstraxServices.authBaseUrl = null;
AppstraxServices.storageBaseUrl = null;
AppstraxServices.databaseBaseUrl = null;
export * from './shared/models/query';
