<div class="mobile">
  <app-mobile-header
    [header]="current.containsRfidReader ? 'Scanning the RFID Chip' : 'Setting up conveyor'"
    [routerLinkUrl]="navBackTo()">
  </app-mobile-header>

  <div
    *ngIf="!loading"
    class="mx-3 mt-3">
    <form
      name="configuration-form"
      class="d-flex"
      *ngIf="this.machines">
      <div class="bg-white card p-3 margin-bottom">
        <label
          for="machineName"
          class="form-label"
          >Machine Name</label
        >
        <div class="relative">
          <input
            [(ngModel)]="conveyorReader.machineName"
            type="text"
            class="form-control input dropdown-toggle"
            placeholder="What is the official machine name?"
            name="machineName"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            aria-expanded="false" />
          <ul class="dropdown-menu position-static w-100">
            <li
              class="pointer"
              *ngFor="let machine of machines">
              <a
                class="dropdown-item"
                (click)="selectMachineName(machine.id)"
                [innerHTML]="machine.machineName"></a>
            </li>
          </ul>
        </div>

        <label
          for="machineNickname"
          class="form-label mt-3"
          >Machine nickname</label
        >
        <input
          [(ngModel)]="conveyorReader.machineNickName"
          class="form-control"
          name="machineNickname"
          type="text"
          placeholder="(Optional) add a nickname for the machine" />

        <label
          for="conveyorName"
          class="form-label mt-3"
          >Conveyor Name/CPR</label
        >
        <input
          [(ngModel)]="conveyorReader.conveyorName"
          class="form-control"
          name="conveyorName"
          type="text"
          placeholder="What is the official conveyor name?" />

        <input
          class="form-control"
          name="customMachineName"
          id="customMachineName"
          type="text"
          placeholder="Enter a custom machine name"
          style="display: none" />

        <label
          for="description"
          class="form-label mt-3"
          >Description</label
        >
        <textarea
          [(ngModel)]="conveyorReader.description"
          name="description"
          type="text"
          placeholder="(Optional) describe the conveyor"
          class="form-control"
          rows="3"></textarea>

        <label
          for="diameter"
          class="form-label mt-3"
          >Drive Drum Diameter (mm)</label
        >
        <input
          [(ngModel)]="conveyorReader.diameter"
          class="form-control"
          name="diameter"
          type="number"
          placeholder="Diameter of the drive drum?" />

        <label
          *ngIf="!current.containsRfidReader"
          for="idleDrumDiameter"
          class="form-label mt-3"
          >Idle Drum Diameter (mm)</label
        >
        <input
          *ngIf="!current.containsRfidReader"
          [(ngModel)]="conveyorReader.idleDrumDiameter"
          class="form-control"
          name="idleDrumDiameter"
          type="number"
          placeholder="Diameter of the idle drum?" />

        <button
          type="button"
          class="btn btn-primary mt-4"
          *ngIf="!isConveyorPairedToBelt"
          (click)="navToPairConveyor()">
          {{ current.containsRfidReader ? "Pair with reader box" : "Pair Conveyor with Belt" }}
        </button>

        <div
          class="d-flex justify-content-center my-2"
          *ngIf="!current.containsRfidReader && !isConveyorPairedToBelt">
          OR
        </div>

        <button
          type="button"
          class="btn btn-primary"
          (click)="navToHomeWithoutRfidPairing()"
          [ngClass]="{ 'mt-4': isConveyorPairedToBelt }"
          *ngIf="!current.containsRfidReader || isConveyorPairedToBelt">
          {{ isConveyorPairedToBelt ? "Save Conveyor & Machine" : "Pair RFID on Site" }}
        </button>
      </div>
    </form>
  </div>

  <div
    *ngIf="loading"
    class="d-flex flex-column center">
    <div class="spinner-border text-primary"></div>
    <div class="mt-1">Fetching Machines And Belts...</div>
  </div>
</div>
