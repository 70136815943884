export class UserAzureDetails {
  public email: string;
  public organizations: Organization[] = [];
  public permissions: Permission[] = [];
  public authRoles: AuthOrgRole[] = [];
}

export class Organization {
  public id: string = '';
  public name: string = '';
  public customerNumber: string = '';
  public salesLocationCode: string = '';
  public organizationType: string = '';
  public parentOrganizationId: string = '';
}

export class Permission {
  public orgId: string = '';
  public orgName: string = '';
  public permissionId: string = '';
  public permissionArea: string = '';
  public permissionName: string = '';
}

export class AuthOrgRole {
  public orgId: string = '';
  public orgName: string = '';
  public roleId: string = '';
  public roleName: string = '';
}

export enum AuthRole {
  CustomerUser = 'Customer_User',
  CustomerAdmin = 'Customer_Admin',
  CustomerUserAdmin = 'Customer_User_Admin',
  SalesUser = 'Sales_User',
  SysAdmin = 'Sys_Admin',
}
