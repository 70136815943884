import { createAction, props } from '@ngrx/store';

import { Csc } from './../../models/csc.model';

export const fetchCscs = createAction('[CSC API] Fetch CSCs Initiated');
export const fetchCscsSuccess = createAction(
  '[CSC API] Fetch CSCs Success',
  props<{ cscs: Csc[] }>(),
);
export const setCscsSuccess = createAction(
  '[CSC API] Set CSCs Success',
  props<{ cscs: Csc[] }>(),
);
export const fetchCscsFailed = createAction(
  '[CSC API] Fetch CSCs Failed',
  props<{ error: unknown }>(),
);
