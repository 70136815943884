import { Component, NgZone, OnInit } from '@angular/core';

import { Installation } from '../../../../../app/models/installation.model';

import { BeltsService } from '../../../../../app/services/c-belt/belts.service';
import { AlertService } from '../../../../../app/shared/services/alert/alert.service';
import { LoadingService } from './../../../../shared/services/loading/loading.service';
import { MsalTokenService, MsalScope } from '../../../../services/c-belt/msal-token.service';

@Component({
  selector: 'app-scanning',
  templateUrl: './scanning.component.html',
  styleUrls: ['./scanning.component.scss'],
})
export class ScanningComponent implements OnInit {
  public installations: Installation[] = [];
  public installation: Installation = new Installation();

  public scanning: boolean = false;
  public settingUser: boolean = false;

  public constructor(
    private ngZone: NgZone,
    private beltService: BeltsService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private msalTokenService: MsalTokenService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.loadingService.show();
    this.settingUser = true;
    if (MsalTokenService.isMobileApp()) {
      await this.msalTokenService.setScope(MsalScope.cBelt);
    }
    this.settingUser = false;

    this.fetchInstalls();
    this.loadingService.hide();
  }

  public async fetchInstalls(): Promise<void> {
    this.loadingService.show();
    try {
      this.installations = await this.beltService.getAllInstallations();
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();
  }

  public resetInstall(): void {
    this.installation = new Installation();
  }

  public startScanning(): void {
    this.toggleScanning();
    window['plugins'].rfidScannerPlugin.scan(
      (rfid) => this.ngZone.run(() => this.onScanFound(rfid)),
      (err) => this.ngZone.run(() => this.onRfidError(err))
    );
  }

  public async onScanFound(rfid: string): Promise<void> {
    this.stopScanning();
    this.fetchInstallDetails(rfid);
  }

  public onRfidError(err: unknown): void {
    this.scanning = false;
    console.log(JSON.stringify(err));
  }

  public async fetchInstallDetails(rfid: string): Promise<void> {
    this.loadingService.show();
    try {
      const foundInstall = this.installations.find((x) => x.rfidTagId == rfid);
      if (foundInstall) {
        this.installation = foundInstall;
      } else {
        this.alertService.alertError('No match for this RFID Tag ID.');
      }
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();
  }

  public stopScanning(): void {
    window['plugins'].rfidScannerPlugin.stop(
      () => this.ngZone.run(() => this.onScanningStopped()),
      (err) => this.ngZone.run(() => this.onRfidError(err))
    );
  }

  private onScanningStopped(): void {
    this.toggleScanning();
  }

  private toggleScanning(): void {
    this.scanning = !this.scanning;
  }

  public isValidInstall(): boolean {
    return !!this.installation.rfidTagId && !!this.installation.length && !!this.installation.width;
  }

  public formatDateTime(date: string): string {
    return new Date(date).toLocaleString();
  }
}
