import { Injectable } from '@angular/core';

import { Customer } from '../../models/customer.model';
import { MachineImport } from '../../models/machine.model';

import { AmmscanApiService } from '../api/ammscanApi.service';

@Injectable()
export class CustomerService {
  public constructor(private ammscanApiService: AmmscanApiService) {}

  public fetchAllCustomers(): Promise<Customer[]> {
    return this.ammscanApiService.get('/customers');
  }

  public fetchCustomerAmmcareBelts(customerId: string): Promise<MachineImport[]> {
    return this.ammscanApiService.get(`/customers/ammcare-belts/${customerId}`);
  }
}
