<div class="bg-white card p-3" *ngIf="customer">
  <div class="d-flex align-items-center mb-2">
    <i class="fa fa-check-circle fa-lg title-margin" *ngIf="complete()"></i>
    <i class="fa fa-times-circle fa-lg title-margin" *ngIf="incomplete()"></i>
    <div>Company</div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="customer.customer_name"></i>
    <i
      class="fa fa-times ms-1 small-margin"
      *ngIf="!customer.customer_name"
    ></i>
    <div class="small">
      Customer Name:
      <span class="grey">
        {{ customer.customer_name ? customer.customer_name : "N/A" }}
      </span>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <i class="fa fa-check ms-1 me-3" *ngIf="customer.customerNumber"></i>
    <i
      class="fa fa-times ms-1 small-margin"
      *ngIf="!customer.customerNumber"
    ></i>
    <div class="small">
      Customer Number:
      <span class="grey">
        {{ customer.customerNumber ? customer.customerNumber : "N/A" }}
      </span>
    </div>
  </div>
</div>

<div class="bg-white card mt-3 p-3" *ngIf="!customer">
  <div class="d-flex align-items-center">
    <i class="fa fa-times-circle fa-lg title-margin"></i>
    <div>No Customer</div>
  </div>
</div>
