export class ConveyorReader {
  public id: string;

  public machineName: string;
  public machineNickName: string;
  public conveyorName: string;
  public description: string;
  public diameter: number;
  public idleDrumDiameter: number;
  public customerProductReference: string;
  public readerId: string;

  public static fromJson(json: {
    id: string;
    machineName: string;
    machineNickName: string;
    conveyorName: string;
    description: string;
    diameter: number;
    idleDrumDiameter: number;
    customerProductReference: string;
    readerId: string;
  }): ConveyorReader {
    const reader = new ConveyorReader();
    reader.id = json.id;
    reader.machineName = json.machineName;
    reader.machineNickName = json.machineNickName;
    reader.conveyorName = json.conveyorName;
    reader.description = json.description;
    reader.diameter = json.diameter;
    reader.idleDrumDiameter = json.idleDrumDiameter;
    reader.customerProductReference = json.customerProductReference;
    reader.readerId = json.readerId;
    return reader;
  }
}
