import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';

import { Conveyor } from 'src/app/models/conveyor.model';
import { Customer } from 'src/app/models/customer.model';
import { CBeltMachine } from 'src/app/models/cBeltMachine.model';
import { ConveyorReader } from 'src/app/models/conveyorReader.model';
import { CBelt } from 'src/app/models/cBelt.model';

import { MachinesService } from 'src/app/services/c-belt/machines.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ConveyorsService } from 'src/app/services/c-belt/conveyors.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { BeltsService } from 'src/app/services/c-belt/belts.service';

import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-conveyor-reader-configuration',
  templateUrl: './conveyor-reader-configuration.component.html',
  styleUrls: ['./conveyor-reader-configuration.component.scss'],
})
export class ConveyorReaderConfigurationComponent implements OnInit {
  public baseRoute: string;
  public companyId: string;
  public machineId: string;
  public createdMachineId: string;

  public updating: boolean = false;
  public loading: boolean = false;
  public addingConveyorToMachine: boolean = false;

  public conveyors: Conveyor[] = [];
  public machines: CBeltMachine[] = [];

  public customer: Customer = null;
  public current: ConfigurationState = null;
  public machineSelected: CBeltMachine = null;

  public conveyor: Conveyor = new Conveyor();
  public machine: CBeltMachine = new CBeltMachine();
  public conveyorReader: ConveyorReader = new ConveyorReader();
  public isConveyorPairedToBelt: boolean = false;

  private belts: CBelt[] = [];

  public constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private machinesService: MachinesService,
    private conveyorService: ConveyorsService,
    private beltService: BeltsService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
    await this.fetchMachinesAndBelts();
    this.isConveyorPairedToBelt = this.belts.some((belt) => belt.conveyorId == this.conveyor.id);
  }

  public subscribeToCurrentConfiguration(): void {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.customer = this.current.company;
        if (this.current.machine && this.current.conveyor) {
          this.updating = true;
          this.loadCurrent();
          this.machine = this.current.machine;
          this.machine.id = this.current.conveyor.machineId;
          this.conveyor = this.current.conveyor;
          this.conveyor.diameter = this.conveyorReader.diameter;
          this.conveyor.idleDrumDiameter = this.conveyorReader.idleDrumDiameter;
          return;
        }
        if (this.current.machine) {
          this.addingConveyorToMachine = true;
          this.loadCurrentMachine();
          this.machine = this.current.machine;
          this.machineSelected = this.current.machine;
        }
      });
  }

  public loadCurrent(): void {
    this.conveyorReader.machineName = this.current.machine.machineName;
    this.conveyorReader.conveyorName = this.current.conveyor.aliasName;
    this.conveyorReader.description = this.current.conveyor.description;
    this.conveyorReader.machineNickName = this.current.machine.aliasName;
    this.conveyorReader.diameter = Number(this.current.conveyor.diameter) * 1000;
    if (this.current.containsRfidReader) {
      this.conveyorReader.idleDrumDiameter = null;
    } else {
      this.conveyorReader.idleDrumDiameter = Number(this.current.conveyor.idleDrumDiameter) * 1000;
    }
  }

  public loadCurrentMachine(): void {
    this.conveyorReader.machineName = this.current.machine.machineName;
    this.conveyorReader.machineNickName = this.current.machine.aliasName;
  }

  public navBackTo(): string[] {
    if (this.current.pairingRfid) {
      this.store.dispatch(InstallationActions.togglePairingRfid({ pairingRfid: false }));
      return ['/installing', this.companyId, 'system-setup-pair-rfid'];
    } else {
      return ['/installing', this.companyId, 'system-setup'];
    }
  }

  public async fetchMachinesAndBelts(): Promise<void> {
    this.loading = true;
    this.loadingService.show();
    try {
      this.machines = await this.machinesService.getMachines();
      this.belts = await this.beltService.getAllBelts();
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();
    this.loading = false;
  }

  public selectMachineName(machineId: string): void {
    this.machineId = machineId;
    this.machineSelected = this.machines.find((x) => x.id == machineId);
    this.conveyorReader.machineName = this.machineSelected.machineName;
    this.conveyorReader.machineNickName = this.machineSelected.aliasName;
  }

  public async navToPairConveyor(): Promise<void> {
    if (!this.isValid()) {
      this.alertService.alertError('Please fill in required fields');
      return;
    }

    this.loadingService.show();
    try {
      if (this.current.editing || this.updating) {
        await this.updateMachine();
        await this.updateConveyor();
      } else if (this.addingConveyorToMachine) {
        await this.updateMachine();
        await this.addConveyor();
      } else {
        if (!this.machineSelected) {
          await this.addMachine();
        } else {
          await this.updateMachine();
        }
        await this.addConveyor();
      }

      this.conveyorReader.id = this.machine.id + this.conveyorReader.conveyorName;
      this.store.dispatch(
        InstallationActions.updateConveyorReader({
          conveyorReader: this.conveyorReader,
        }),
      );
      this.store.dispatch(
        InstallationActions.setCurrentConveyorReader({
          conveyorReader: this.conveyorReader,
        }),
      );

      this.updating = false;

      if (this.current.containsRfidReader) {
        this.router.navigate([
          this.baseRoute,
          this.companyId,
          'conveyor-reader-configuration',
          'pair-conveyor-reader',
          this.conveyorReader.conveyorName,
        ]);
      } else {
        this.router.navigate([
          this.baseRoute,
          this.companyId,
          'system-setup-pair-rfid',
          'select-belt',
        ]);
      }
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();
  }

  public async navToHomeWithoutRfidPairing(): Promise<void> {
    if (!this.isValid()) {
      this.alertService.alertError('Please fill in required fields');
      return;
    }

    this.loadingService.show();
    try {
      if (this.current.editing || this.updating) {
        await this.updateMachine();
        await this.updateConveyor();
      } else {
        if (!this.machineSelected) {
          await this.addMachine();
        } else {
          await this.updateMachine();
        }
        await this.addConveyor();
      }

      this.conveyorReader.id = this.machine.id + this.conveyorReader.conveyorName;
      this.store.dispatch(
        InstallationActions.updateConveyorReader({
          conveyorReader: this.conveyorReader,
        }),
      );

      this.updating = false;
      this.router.navigate(['/installing', this.companyId, 'system-setup']);
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();
  }

  public isValid(): boolean {
    if (this.current.containsRfidReader) {
      return (
        !!this.conveyorReader.machineName &&
        !!this.conveyorReader.conveyorName &&
        !!this.conveyorReader.diameter
      );
    } else {
      return (
        !!this.conveyorReader.machineName &&
        !!this.conveyorReader.conveyorName &&
        !!this.conveyorReader.idleDrumDiameter &&
        !!this.conveyorReader.diameter
      );
    }
  }

  public async addMachine(): Promise<void> {
    this.machine.machineName = this.conveyorReader.machineName;
    this.machine.aliasName = this.conveyorReader.machineNickName
      ? this.conveyorReader.machineNickName
      : '';
    this.machine.organizationId = this.customer.id;
    const response = await this.machinesService.addMachine(this.machine);
    this.createdMachineId = response['id'];
    this.machine.id = this.createdMachineId;
    this.alertService.alertSuccess('Machine Created Successfully!');
    this.store.dispatch(InstallationActions.updateMachine({ machine: this.machine }));
  }

  public async updateMachine(): Promise<void> {
    if (this.machineSelected && this.machineSelected.id) {
      this.machine.id = this.machineSelected.id;
    } else {
      this.machine.id = this.conveyor.machineId;
    }
    this.machine.machineName = this.conveyorReader.machineName;
    this.machine.aliasName = this.conveyorReader.machineNickName
      ? this.conveyorReader.machineNickName
      : '';
    this.machine.organizationId = this.customer.id;
    await this.machinesService.updateMachine(this.machine);
    this.store.dispatch(InstallationActions.updateMachine({ machine: this.machine }));
    this.alertService.alertSuccess('Machine Updated Successfully!');
  }

  public async addConveyor(): Promise<void> {
    const createConveyor = new Conveyor();
    createConveyor.machineId = this.createdMachineId
      ? this.createdMachineId
      : this.machineSelected.id;

    createConveyor.aliasName = this.conveyorReader.conveyorName;
    createConveyor.description = this.conveyorReader.description;
    createConveyor.diameter = Number(this.conveyorReader.diameter) / 1000;
    if (this.current.containsRfidReader) {
      createConveyor.idleDrumDiameter = null;
    } else {
      createConveyor.idleDrumDiameter = Number(this.conveyorReader.idleDrumDiameter) / 1000;
    }

    const response = await this.conveyorService.addConveyor(createConveyor);
    createConveyor.id = response.id;

    this.store.dispatch(InstallationActions.updateConveyor({ conveyor: createConveyor }));
    this.store.dispatch(InstallationActions.setCurrentConveyor({ conveyor: createConveyor }));

    this.alertService.alertSuccess('Conveyor Created Successfully!');
  }

  public async updateConveyor(): Promise<void> {
    const createConveyor = new Conveyor();
    createConveyor.id = this.conveyor.id;
    createConveyor.machineId = this.conveyor.machineId;

    createConveyor.aliasName = this.conveyorReader.conveyorName;
    createConveyor.description = this.conveyorReader.description;
    createConveyor.diameter = Number(this.conveyorReader.diameter) / 1000;

    if (this.current.containsRfidReader) {
      createConveyor.idleDrumDiameter = null;
    } else {
      createConveyor.idleDrumDiameter = Number(this.conveyorReader.idleDrumDiameter) / 1000;
    }

    const response = await this.conveyorService.updateConveyor(createConveyor);
    createConveyor.id = response.id;

    this.store.dispatch(InstallationActions.updateConveyor({ conveyor: createConveyor }));
    this.store.dispatch(InstallationActions.setCurrentConveyor({ conveyor: createConveyor }));

    this.alertService.alertSuccess('Conveyor Updated Successfully!');
  }
}
