<div class="modal-header align-items-center">
  <h4 class="modal-title">{{ options.title }}</h4>
  <div class="close pointer text-white fs-3" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </div>
</div>

<div class="modal-body" [innerHTML]="options.message"></div>

<div class="modal-footer justify-content-between">
  <div class="d-flex">
    <button class="btn btn-primary me-2" (click)="onConfirm()">Confirm</button>
    <button class="btn btn-outline-secondary" (click)="onReject()">Cancel</button>
  </div>
</div>