import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { environment } from 'src/environments/environment';

import { UtilService, RoleEnum } from 'src/app/utils/util.service';
import { AmmscanApiService } from '../api/ammscanApi.service';
import { StorageService } from '../storage/storage.service';

import { AuthRole, UserAzureDetails } from 'src/app/models/userAzureDetails.model';

import { loginSuccess } from './../../state/user/user.actions';

export interface User {
  id: string;
  email: string;
  role?: RoleEnum;
  organisationRoles?: { [key: string]: AuthRole[] };
}

@Injectable()
export class AuthService {
  private user: User = null;

  public constructor(
    private ammscanApiService: AmmscanApiService,
    private store: Store,
    private http: HttpClient,
    private storageService: StorageService,
    private utils: UtilService,
  ) {}

  public getUser(): User {
    return this.user;
  }

  public async onMsalAuthChange(
    userData: {
      id: string;
      email: string;
    },
    options?: {
      fetchOrgPermissions: boolean;
    },
  ): Promise<void> {
    if (!userData) {
      this.user = null;
      return;
    }

    let role: RoleEnum = this.storageService.getUserRole();
    let userAzureDetails: UserAzureDetails;

    if (!role) {
      role = await this.fetchUserRole();
      this.storageService.setUserRole(role);
    }

    if (options?.fetchOrgPermissions && (!this.user || !this.user.organisationRoles)) {
      userAzureDetails = await this.fetchUserAzureDetails(userData.id);
    }

    this.user = {
      ...(this.user ? this.user : {}),
      id: userData.id,
      email: userData.email,
    };
    if (role) this.user.role = role;
    if (userAzureDetails) this.assignUserOrgPermissions(userAzureDetails);

    this.store.dispatch(loginSuccess({ user: this.user }));
  }

  private async fetchUserRole(): Promise<RoleEnum> {
    try {
      return this.ammscanApiService.get(`/users/role`);
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  private async fetchUserAzureDetails(userId: string): Promise<UserAzureDetails> {
    try {
      const code = environment.code;
      const baseUrl = environment.cBeltApi;
      const params = { principalId: userId, code };
      const httpObservable = this.http.get(baseUrl + '/users', { params: params });

      const response: any = await this.utils.observableToPromise(httpObservable);

      if (!response?.result) return null;
      return response.result;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  private assignUserOrgPermissions(details: UserAzureDetails): void {
    if (!this.user.organisationRoles) this.user.organisationRoles = {};

    for (const role of details.authRoles) {
      if (!this.user.organisationRoles[role.orgId]) this.user.organisationRoles[role.orgId] = [];

      if (this.user.organisationRoles[role.orgId].includes(role.roleName as AuthRole)) continue;
      this.user.organisationRoles[role.orgId].push(role.roleName as AuthRole);
    }
  }

  public hasInstallationPermission(): boolean {
    const organisationIds = Object.keys(this.user?.organisationRoles ?? {});
    for (const orgId of organisationIds) {
      const orgRoles = this.user.organisationRoles[orgId];
      if (orgRoles?.includes(AuthRole.SysAdmin) || orgRoles.includes(AuthRole.SalesUser)) {
        return true;
      }
    }
    return false;
  }
}
