import { SafeResourceUrl } from '@angular/platform-browser';

export class Image {
  public displayImage: string | SafeResourceUrl;
  public file: File;
  public isNew: boolean;

  public constructor() {
    this.displayImage = null;
    this.file = null;
    this.isNew = true;
  }

  public static fromUrl(url: string): Image {
    const image = new Image();
    image.displayImage = url;
    image.isNew = false;
    image.file = null;
    return image;
  }
}
