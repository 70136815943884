<div class="mobile">
  <app-mobile-header
    [header]="'New wi-fi device'"
    [routerLinkUrl]="['/installing', companyId, 'gateway-configuration']"
  >
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <div class="bg-white card p-3 mb-3">
      <div>Add device via QR code</div>
      <div class="grey">
        Scan the QR code on top of the box the device came in
      </div>
      <button
        type="button"
        class="btn btn-primary mt-3"
        (click)="openAndScanQR()"
      >
        SCAN QR
      </button>
    </div>

    <div class="d-flex justify-content-start mb-3 ms-3">OR</div>

    <div class="bg-white card p-3 mb-3">
      <div>Add device manually</div>
      <div class="grey">
        Enter the unique ID on top of the box the device came in
      </div>

      <label for="deviceId" class="form-label mt-3">Gateway Device ID</label>
      <input
        [(ngModel)]="gatewayDevice.gatewayDeviceId"
        class="form-control"
        name="deviceId"
        type="text"
        placeholder="Enter Gateway Device ID"
      />
      <button
        type="button"
        class="btn btn-primary mt-3"
        (click)="navToWifiSetup()"
      >
        Continue
      </button>
    </div>

    <div class="d-flex justify-content-start mb-3 ms-3">OR</div>

    <div class="bg-white card p-3 mb-3">
      <button type="button" class="btn btn-primary" (click)="navTo4gConfig()">
        Use a 4G device instead
      </button>
    </div>
  </div>
</div>
