export var AuthStatus;
(function (AuthStatus) {
    AuthStatus["authenticated"] = "authenticated";
    AuthStatus["pendingTwoFactorAuthCode"] = "pendingTwoFactorAuthCode";
    AuthStatus["notAuthenticated"] = "notAuthenticated";
})(AuthStatus || (AuthStatus = {}));
export var AuthErrors;
(function (AuthErrors) {
    // registration errors
    AuthErrors["emailAddressAlreadyExists"] = "emailAddressAlreadyExists";
    AuthErrors["badlyFormattedEmailAddress"] = "badlyFormattedEmailAddress";
    AuthErrors["noPasswordSupplied"] = "noPasswordSupplied";
    // login errors
    AuthErrors["invalidEmailOrPassword"] = "invalidEmailOrPassword";
    AuthErrors["userBlocked"] = "userBlocked";
    AuthErrors["invalidTwoFactorAuthCode"] = "invalidTwoFactorAuthCode";
    // forgot/reset password errors
    AuthErrors["emailAddressDoesNotExist"] = "emailAddressDoesNotExist";
    AuthErrors["invalidResetCode"] = "invalidResetCode";
    // unknown errors
    AuthErrors["unexpectedError"] = "unexpectedError";
})(AuthErrors || (AuthErrors = {}));
export class AuthResult {
    static authenticated(user) {
        return new AuthResult({
            status: AuthStatus.authenticated,
            user,
        });
    }
    static requireTwoFactorAuthCode() {
        return new AuthResult({
            status: AuthStatus.pendingTwoFactorAuthCode,
        });
    }
    constructor({ status, user, }) {
        this.status = status;
        this.user = user;
    }
}
