import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';

import { Machine, MachineImport } from './../../../models/machine.model';
import { Belt } from './../../../models/belt.model';
import { Survey } from './../../../models/survey.model';
import { Table } from './../../../shared/components/table/table.component';

import { AlertService } from './../../../shared/services/alert/alert.service';
import { AuthService } from './../../../services/auth/auth.service';
import {
  Breadcrumb,
  BreadcrumbService,
} from './../../../shared/services/breadcrumb/breadcrumb.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { TranslateService } from '../../../services/translate/translate.service';
import { LoadingService } from './../../../shared/services/loading/loading.service';
import { ModalService } from './../../../services/modal/modal.service';

import * as SurveySelectors from '../../../state/survey/survey.selector';
import * as BeltActions from '../../../state/belt/belt.actions';

@Component({
  selector: 'app-belt-import',
  templateUrl: './belt-import.component.html',
  styleUrl: './belt-import.component.scss',
})
export class BeltImportComponent implements OnInit, OnDestroy {
  public survey: Survey;
  private survey$: Observable<Survey>;
  private surveySubscription: any;

  public ammcareBelts: MachineImport[];
  public ammcareBeltTable: Table;
  public allBeltsSelected: boolean;
  public currentBelt: MachineImport;
  public bsModalRef: BsModalRef;

  public constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    private customerService: CustomerService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private store: Store,
    public t: TranslateService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.route.params.subscribe((params) => {
      // If survey wasn't loaded: load survey
      this.loadSurvey(params['survey']);
    });
  }

  public ngOnDestroy(): void {
    this.surveySubscription.unsubscribe();
  }

  private loadSurvey(surveyId: string): void {
    this.survey$ = this.store.select(SurveySelectors.selectSurveyById({ id: surveyId }));
    this.surveySubscription = this.survey$.subscribe((survey) => {
      if (!survey) return;

      if (this.survey && this.survey.id) {
        return;
      }

      this.survey = survey;
      this.fetchCustomerAmmcareBelts();

      const crumb = new Breadcrumb(
        `${this.t.translate('_AMMCARE_BELT_IMPORT')} (${survey?.customer?.customer_name})`,
        ['/', 'surveys', this.survey?.id, 'import']
      );
      this.breadcrumbService.pushBreadcrumb(crumb);
    });
  }

  private async fetchCustomerAmmcareBelts(): Promise<void> {
    try {
      if (!this.survey) return;

      this.ammcareBelts = await this.customerService.fetchCustomerAmmcareBelts(
        this.survey?.customerId
      );
    } catch (err) {
      console.log(err);
    }
  }

  public toggleAllBelts(): void {
    this.allBeltsSelected = !this.allBeltsSelected;
    this.ammcareBelts.forEach((belt: MachineImport) => {
      belt.mustImport = this.allBeltsSelected;
    });
  }

  public openImageModal(modal: unknown, belt: MachineImport): void {
    this.currentBelt = belt;
    this.bsModalRef = this.modalService.show(modal, { class: 'modal-md' });
  }

  public hideModal(): void {
    this.bsModalRef.hide();
  }

  public areAllChecked(): boolean {
    this.allBeltsSelected = !this.ammcareBelts.some((belt) => !belt.mustImport);
    return this.allBeltsSelected;
  }

  public importBelts(): void {
    const items = this.ammcareBelts.filter((belt) => belt.mustImport);
    this.createBelts(items);
  }

  private async createBelts(beltInputs: Machine[]): Promise<void> {
    this.loadingService.show();

    try {
      const belts: Belt[] = beltInputs.map((belt) => {
        return this.createBeltFromAmmcareItem(belt);
      });
      this.store.dispatch(BeltActions.addBeltsSuccess({ belts }));
      this.breadcrumbService.popBreadcrumb();
      this.alertService.alertSuccess(this.t.translate('_BELT_CONFIG_UPDATED_SUCCESSFULLY'));
    } catch (err) {
      this.alertService.handleHttpError(err);
    }

    this.loadingService.hide();
  }

  private createBeltFromAmmcareItem(ammcareItem: Machine): Belt {
    let belt: Belt = new Belt(this.survey.id);

    belt = {
      ...belt,
      id: uuidv4(),
      created: new Date(),
      updated: new Date(),
      attachmentUrl: ammcareItem.images[0]?.imageUrl,
      productReference: ammcareItem.machine_id,
      productionLine: ammcareItem.productionLine,
      quantity: ammcareItem.amount,
      length: ammcareItem.length,
      width: ammcareItem.width,
      surveyor: { email: this.authService.getUser().email },
      dirty: true,
    };

    return belt;
  }
}
