import { Component } from '@angular/core';

@Component({
  selector: 'app-installing-home',
  templateUrl: './installing-home.component.html',
  styleUrls: ['./installing-home.component.scss']
})
export class InstallingHomeComponent {

}
