<div class="mobile">
  <app-mobile-header
    [header]="'Setup Sensors for Conveyor'"
    [routerLinkUrl]="['/installing', companyId]"
  >
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <div class="bg-white card p-3 margin-bottom">
      <div class="fw-bold pb-1">Select the Conveyor to assign Sensors</div>
      <div class="pb-2"><hr /></div>
      <div *ngFor="let machine of current.machines; let i = index">
        <div class="mb-2">
          <div class="d-flex align-items-center">
            <div class="fw-bold">Machine {{ i + 1 }}:</div>
            <div class="small grey ps-2">
              {{ machine.machineName ? machine.machineName : "placeholder" }}
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-between pb-1"
            *ngFor="let conveyor of fetchConveyorsForMachine(machine.id); let j = index"
          >
            <div class="d-flex align-items-center">
              <div class="fw-bold ms-3">• Conveyor {{ j + 1 }}:</div>
              <div class="small grey ps-2">
                {{ conveyor.aliasName ? conveyor.aliasName : "placeholder" }}
              </div>
            </div>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm"
              (click)="selectConveyor(conveyor)"
            >
              <i class="fa fa-plus-square-o"></i>
            </button>
          </div>
        </div>
        <div class="pb-2 pt-1"><hr /></div>
      </div>
    </div>
  </div>
</div>
