import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

import { TranslateService } from '../translate/translate.service';

import { Belt } from './../../models/belt.model';
import { Table } from './../../shared/components/table/table.component';
import { Survey } from './../../models/survey.model';

@Injectable()
export class MappingService {
  public constructor(
    private sanitizer: DomSanitizer,
    public t: TranslateService,
  ) {}

  private getSyncedIcon(dirty: boolean): string {
    return dirty
      ? '<i class="material-symbols-outlined text-muted d-block">cloud_off</i>'
      : '<i class="material-symbols-outlined text-success d-block">cloud_done</i>';
  }

  public surveysToTable(
    surveys: Survey[],
    removeCallback: { (survey: unknown): Promise<void>; (arg0: Survey): unknown },
  ): Table {
    return {
      rows: surveys.map((survey) => {
        const actions = [];

        actions.push({
          symbol: 'delete',
          title: `${this.t.translate('_REMOVE')}`,
          onClick: () => {
            removeCallback(survey);
          },
        });

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const getOwner = (survey) => {
          return survey.user?.email
            ? `<div class="text-lowercase">${survey.user?.email}</div>`
            : `<div>${this.t.translate('_USER_REMOVED')}</div>`;
        };

        return {
          id: survey.id,
          date: survey.created,
          sync: this.getSyncedIcon(survey.dirty),
          'customer site': survey.customer ? survey.customer.customer_name.toLowerCase() : '',
          belts: survey.belts?.length,
          owner: getOwner(survey),
          ACTIONS: actions,
        };
      }),
    };
  }

  public beltsToTable(
    belts: Belt[],
    duplicateCallback: (belt: Belt) => void,
    removeCallback: (belt: Belt) => void,
  ): Table {
    return {
      rows: belts.map((belt) => {
        const actions = [];
        actions.push(
          {
            symbol: 'content_copy',
            title: `${this.t.translate('_COPY_BELT')}`,
            onClick: () => {
              duplicateCallback(belt);
            },
          },
          {
            symbol: 'delete',
            title: `${this.t.translate('_REMOVE')}`,
            onClick: () => {
              removeCallback(belt);
            },
          },
        );

        const className =
          belt.attachmentUrl || belt.image?.displayImage
            ? 'text-white photo-btn'
            : 'no-image text-primary';
        let content = `<i class="material-symbols-outlined no-image w-100 text-primary flex-center">
          question_mark
          </i>`;
        if (belt.image?.displayImage) {
          let safeUrl;
          if (typeof belt.image?.displayImage == 'string') {
            safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(belt.image.displayImage);
          } else {
            safeUrl = belt.image.displayImage;
          }
          content = `<img src="${safeUrl} loading="lazy" />`;
        } else if (belt.attachmentUrl) {
          content = `<img src="${belt.attachmentUrl}" loading="lazy" />`;
        }

        const image = `
          <div class="image-container rounded overflow-hidden position-relative ${className}">
            ${content}
          </div>`;

        const synOrModKey = this.t.formatTranslationKey(belt.synOrMod);
        const beltTypeHtml = belt.synOrMod
          ? `<div class="px-3 mt-1 text-white text-capitalize text-center rounded ${belt.synOrMod}">
            ${this.t.translate(synOrModKey)}
          </div>`
          : '-';

        return {
          id: belt.id,
          image: image,
          date: belt.updated,
          sync: this.getSyncedIcon(belt.dirty),
          'Production Line': belt.productionLine || '-',
          WTL: belt.wtl || '-',
          'Product Reference': `<div title="${belt.productReference}">${
            belt.productReference || '-'
          }</div>`,
          'Belt Type': beltTypeHtml,
          material: belt.synOrMod
            ? belt.synOrMod == 'synthetic'
              ? belt.material
              : belt.beltMaterial
            : '-',
          length: (belt.length || 0) + 'mm',
          width: (belt.width || 0) + 'mm',
          ACTIONS: actions,
        };
      }),
    };
  }
}
