import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { CBeltAuthGuard } from '../guards/c-belt-auth.guard';
import { MsalTokenService } from '../services/c-belt/msal-token.service';

import { AppLayoutComponent } from '../components/app-layout/app-layout.component';
import { SurveyListComponent } from './surveys/survey-list/survey-list.component';
import { AddCustomerComponent } from './surveys/add-customer/add-customer.component';

import { BeltImportComponent } from './belts/belt-import/belt-import.component';
import { BeltListComponent } from './belts/belt-list/belt-list.component';
import { BeltCreateComponent } from './belts/belt-create/belt-create.component';
import { BeltEditComponent } from './belts/belt-edit/belt-edit.component';

const guard = MsalTokenService.isMobileApp() ? CBeltAuthGuard : MsalGuard;

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'surveys',
  },
  {
    path: 'surveys',
    canActivate: [guard],
    canActivateChild: [guard],
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: SurveyListComponent,
      },
      {
        path: ':survey/import',
        component: BeltImportComponent,
      },
      {
        path: 'add-customer',
        component: AddCustomerComponent,
      },
      {
        path: ':survey',
        component: BeltListComponent,
      },
      {
        path: ':survey/belts/create',
        component: BeltCreateComponent,
      },
      {
        path: ':survey/belts/:belt/edit',
        component: BeltEditComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SurveyRoutingModule {}
