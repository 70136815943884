class PreferencesService {
    constructor() {
        this.authTokenKey = 'APPSTRAX_AUTH_TOKEN';
        this.refreshTokenKey = 'APPSTRAX_REFRESH_TOKEN';
        this.canRefreshTokenKey = 'APPSTRAX_CAN_REFRESH_TOKEN';
    }
    getItem(key) {
        try {
            return localStorage.getItem(key) || '';
        }
        catch (e) {
            return '';
        }
    }
    setItem(key, value) {
        try {
            localStorage.setItem(key, value);
        }
        catch (e) {
            // TODO handle err
        }
    }
    clear() {
        try {
            localStorage.removeItem(this.authTokenKey);
            localStorage.removeItem(this.refreshTokenKey);
            localStorage.removeItem(this.canRefreshTokenKey);
        }
        catch (e) {
            // TODO handle err
        }
    }
    getTokens() {
        const token = this.getItem(this.authTokenKey);
        const refreshToken = this.getItem(this.refreshTokenKey);
        if (!token || !refreshToken) {
            return null;
        }
        return { token, refreshToken };
    }
    setTokens(auth) {
        if (!auth || !auth.token || !auth.refreshToken) {
            this.clear();
        }
        else {
            this.setItem(this.authTokenKey, auth.token);
            this.setItem(this.refreshTokenKey, auth.refreshToken);
        }
    }
    canRefreshToken() {
        const value = this.getItem(this.canRefreshTokenKey);
        return value !== 'false';
    }
    setCanRefreshToken(value) {
        this.setItem(this.canRefreshTokenKey, value + '');
    }
}
const preferences = new PreferencesService();
export { preferences };
