import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'info-modal.component.html',
})
export class InfoModalComponent {
  public options: InfoModalOptions;
  public hide: boolean = false;

  public constructor(private bsModalRef: BsModalRef) {}

  public onConfirm(): void {
    this.options.confirm();
    this.close();
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public toggleHide(): void {
    this.hide = !this.hide;
  }
}

export class InfoModalOptions {
  public title: string;
  public message: string;
  public confirmButtonText?: string;
  public confirm: () => void;
}
