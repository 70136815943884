import { Component, ChangeDetectorRef } from '@angular/core';

import { ILoading } from '../../services/loading/loading.interface';
import { LoadingService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements ILoading {
  public isShown: boolean = false;

  public constructor(
    private cdr: ChangeDetectorRef,
    public loadingService: LoadingService,
  ) {
    loadingService.subscribeToAlerts(this);
  }

  public show(): void {
    this.isShown = true;
    this.cdr.detectChanges();
  }

  public hide(): void {
    this.isShown = false;
    this.cdr.detectChanges();
  }
}
