import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Customer } from '../../../../models/customer.model';

import { StateService } from '../../../../services/c-belt/state.service';
import { LoadingService } from './../../../../shared/services/loading/loading.service';
import { OrganizationsService } from '../../../../services/c-belt/organizations.service';

import { Store } from '@ngrx/store';
import * as InstallationActions from '../../../../state/installation/installation.actions';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss'],
})
export class SelectCustomerComponent implements OnInit {
  public searchText: string = '';
  public customers: Customer[] = [];

  public constructor(
    private store: Store,
    private router: Router,
    private stateService: StateService,
    private loadingService: LoadingService,
    private organizationsService: OrganizationsService
  ) {}

  public ngOnInit(): void {
    this.fetchOrganizations();
  }

  public async fetchOrganizations(): Promise<void> {
    this.loadingService.show();
    try {
      this.customers = await this.organizationsService.getOrganizations();
    } catch (err) {
      console.log(err);
      this.router.navigate(['/']);
    }
    this.loadingService.hide();
  }

  public async filterClients(): Promise<void> {
    if (this.searchText == '' || !this.customers.length) {
      await this.fetchOrganizations();
    }

    this.customers = this.customers.filter((x) =>
      x.customer_name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  public navToHome(): void {
    this.router.navigate(['c-belt']);
  }

  public navTo(route: string, customer: Customer): void {
    this.stateService.subscribeToCurrentAndSave();
    this.store.dispatch(InstallationActions.updateCompany({ customer }));
    this.router.navigate([this.router.url, route]);
  }
}
