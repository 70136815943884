import { FormsModule } from '@angular/forms';
import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { SharedModule } from '../shared/shared.module';
import { SurveyRoutingModule } from './survey-routing.module';

import { SurveyService } from '../services/survey/survey.service';
import { MappingService } from '../services/mapping/mapping.service';
import { AmmscanApiService } from '../services/api/ammscanApi.service';
import { CustomerService } from '../services/customer/customer.service';

import { BeltEditComponent } from './belts/belt-edit/belt-edit.component';
import { BeltListComponent } from './belts/belt-list/belt-list.component';
import { BeltInputComponent } from './belts/belt-input/belt-input.component';
import { BeltCreateComponent } from './belts/belt-create/belt-create.component';
import { BeltImportComponent } from './belts/belt-import/belt-import.component';
import { MobileBeltListComponent } from './belts/belt-list/mobile-belt-list/mobile-belt-list.component';
import { DesktopBeltListComponent } from './belts/belt-list/desktop-belt-list/desktop-belt-list.component';
import { MobileBeltItemCardComponent } from './belts/belt-list/mobile-belt-list/mobile-belt-item-card/mobile-belt-item-card.component';

import { SurveyListComponent } from './surveys/survey-list/survey-list.component';
import { AddCustomerComponent } from './surveys/add-customer/add-customer.component';
import { MobileSurveyListComponent } from './surveys/mobile-survey-list/mobile-survey-list.component';

@NgModule({
  declarations: [
    SurveyListComponent,
    AddCustomerComponent,
    MobileSurveyListComponent,
    BeltCreateComponent,
    BeltEditComponent,
    BeltImportComponent,
    BeltInputComponent,
    BeltListComponent,
    DesktopBeltListComponent,
    MobileBeltItemCardComponent,
    MobileBeltListComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    NgSelectModule,
    BsDatepickerModule,
    SharedModule,
    SurveyRoutingModule,
  ],
  providers: [AmmscanApiService, CustomerService, MappingService, SurveyService],
})
export class SurveyModule {}
