<div class="w-100 d-flex align-items-center border-bottom bg-white small text-muted">
  <div
    class="py-1 px-3 cursor-pointer"
    [routerLink]="['/']"
    (click)="clearBreadcrumbs()">
    <div class="material-symbols-outlined">home</div>
  </div>
  <div
    class="material-symbols-outlined"
    *ngIf="breadcrumbs.length">
    navigate_next
  </div>

  <ng-container *ngFor="let breadcrumb of breadcrumbs">
    <div
      class="cursor-pointer py-1 px-2"
      (click)="navigateToBreadcrumb(breadcrumb)">
      {{ breadcrumb.name }}
    </div>
    <div
      class="material-symbols-outlined"
      *ngIf="breadcrumbs.indexOf(breadcrumb) < breadcrumbs.length - 1">
      navigate_next
    </div>
  </ng-container>
</div>
