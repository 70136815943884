import { Belt } from './belt.model';
import { Customer } from './customer.model';
import { Model } from './../state/state-utils';

export class Survey implements Model {
  public id: string;
  public created: Date = new Date();
  public updated: Date = new Date();
  public createdBy: string;
  public updatedBy: string;

  public site: unknown;
  public belts: Belt[] = [];
  public customerId: string;
  public deleted: boolean;

  public customer_name: string;
  public customer?: Customer;
  public user?: any;

  public dirty: boolean = true;
}
