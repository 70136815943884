import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';

import { Customer } from 'src/app/models/customer.model';
import { Conveyor } from 'src/app/models/conveyor.model';
import { CBeltMachine } from 'src/app/models/cBeltMachine.model';

import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-system-setup',
  templateUrl: './system-setup.component.html',
  styleUrl: './system-setup.component.scss',
})
export class SystemSetupComponent implements OnInit {
  public editing: boolean;
  public companyId: string;
  public customer: Customer;
  public current: ConfigurationState = null;
  public containsRfidReader: boolean = true;

  public constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(InstallationActions.clearCurrentMachine());
    this.store.dispatch(InstallationActions.clearCurrentConveyor());

    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  public subscribeToCurrentConfiguration(): void {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.customer = this.current.company;
        this.containsRfidReader = this.current.containsRfidReader;
        this.editing = this.current.editing;
      });
  }

  public getConveyorsForMachine(machineId: string): Conveyor[] {
    return this.current.conveyors.filter((conveyor) => conveyor.machineId == machineId);
  }

  public addNewMachine(): void {
    this.store.dispatch(InstallationActions.clearCurrentMachine());
    this.navToAddConveyor();
  }

  public addConveyorToMachine(machine: CBeltMachine): void {
    this.store.dispatch(
      InstallationActions.setCurrentMachine({
        machine: machine,
      }),
    );
    this.navToAddConveyor();
  }

  public editConveyorAndMachine(machine: CBeltMachine, conveyor: Conveyor): void {
    this.store.dispatch(
      InstallationActions.setCurrentMachine({
        machine: machine,
      }),
    );
    this.store.dispatch(
      InstallationActions.setCurrentConveyor({
        conveyor: conveyor,
      }),
    );
    this.navToAddConveyor();
  }

  public navToAddConveyor(): void {
    this.router.navigate(['/installing', this.companyId, 'conveyor-reader-configuration']);
  }
}
