import { Injectable } from '@angular/core';

import { uuids } from './bluetooth-uuids';

interface PermRes {
  hasPermission: boolean;
}

interface PermReq {
  requestPermission: boolean;
}

export interface ReadRequest {
  status: string;
  address: string;
  service: string;
  characteristic: string;
  requestId: number;
  offset: number;
}

function promise<T>(
  callbackProducer: (handler: (success: T) => void) => void,
  handler: (success: T) => boolean
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    try {
      callbackProducer((res) => {
        resolve(handler(res));
      });
    } catch (err) {
      reject(err);
    }
  });
}

@Injectable({ providedIn: 'root' })
export class BluetoothLeService {
  public foundDevices = [];

  public hasLocationPermission(): Promise<boolean> {
    return promise<PermRes>(bluetoothle.hasPermission, (x) => x.hasPermission);
  }

  public hasScanPermission(): Promise<boolean> {
    return promise<PermRes>(bluetoothle.hasPermissionBtScan, (x) => x.hasPermission);
  }

  public hasConnectPermission(): Promise<boolean> {
    return promise<PermRes>(bluetoothle.hasPermissionBtConnect, (x) => x.hasPermission);
  }

  public hasAdvertizePermission(): Promise<boolean> {
    return promise<PermRes>(bluetoothle.hasPermissionBtAdvertise, (x) => x.hasPermission);
  }

  public requestLocationPermission(): Promise<boolean> {
    return promise<PermReq>(bluetoothle.requestPermission, (x) => x.requestPermission);
  }

  public requestScanPermission(): Promise<boolean> {
    return promise<PermReq>(bluetoothle.requestPermissionBtScan, (x) => x.requestPermission);
  }

  public requestConnectPermission(): Promise<boolean> {
    return promise<PermReq>(bluetoothle.requestPermissionBtConnect, (x) => x.requestPermission);
  }

  public requestAdvertizePermission(): Promise<boolean> {
    return promise<PermReq>(bluetoothle.requestPermissionBtAdvertise, (x) => x.requestPermission);
  }

  public initialize(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      document.addEventListener('deviceready', () => {
        try {
          bluetoothle.initialize((result) => resolve(result.status == 'enabled'), {
            request: true,
            statusReceiver: true,
          });
        } catch (err) {
          reject(err);
        }
      });
    });
  }

  public isEnabled(): Promise<{ isEnabled: boolean }> {
    return new Promise((resolve) => bluetoothle.isEnabled(resolve));
  }

  public enable(): Promise<{ status: boolean }> {
    return new Promise((resolve, reject) => bluetoothle.enable(resolve, reject));
  }

  public disable(): Promise<BluetoothlePlugin.Error> {
    return new Promise((resolve, reject) => bluetoothle.disable(resolve, reject));
  }

  public startScan(
    callback: (devices: BluetoothlePlugin.ScanStatus[]) => void,
    errorCallback: (error: BluetoothlePlugin.Error) => void
  ): void {
    this.foundDevices = [];

    bluetoothle.startScan(
      (result) => {
        if (this.onScanSuccess(result)) callback(this.foundDevices);
      },
      errorCallback,
      { services: [] }
    );
  }

  private onScanSuccess(result: BluetoothlePlugin.ScanStatus): boolean {
    if (result.status == 'scanStarted') {
      console.log('Scanning for devices');
    } else if (result.status == 'scanResult') {
      if (
        !this.foundDevices.some((device) => {
          return device.address == result.address;
        })
      ) {
        this.foundDevices.push(result);
        return true;
      }
    }
    return false;
  }

  public stopScan(): Promise<{ status: string }> {
    return new Promise((resolve, reject) => {
      bluetoothle.stopScan(resolve, reject);
    });
  }

  public connect(address: string): Promise<boolean> {
    this.stopScan();
    return new Promise((resolve, reject) => {
      bluetoothle.connect((result) => resolve(result.status == 'connected'), reject, {
        address: address,
      });
    });
  }

  public disconnectAndClose(address: string): Promise<boolean> {
    this.stopScan();
    new Promise((resolve, reject) => {
      bluetoothle.disconnect((result) => resolve(result.status == 'disconnected'), reject, {
        address: address,
      });
    });
    return new Promise((resolve, reject) => {
      bluetoothle.close((result) => resolve(result.status == 'disconnected'), reject, {
        address: address,
      });
    });
  }

  public connectSuccess(result: BluetoothlePlugin.DeviceInfo): void {
    if (result.status == 'connected') {
      this.getDeviceServices(result.address);
    } else if (result.status == 'disconnected') {
      console.log('Disconnected from device: ' + result.address, 'status');
    }
  }

  public getDeviceServices(address: string): Promise<BluetoothlePlugin.Device> {
    return new Promise((resolve, reject) => {
      bluetoothle.discover((result) => resolve(result), reject, {
        address: address,
      });
    });
  }

  public read(address: string): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      bluetoothle.read(
        (result) => {
          const bytes = bluetoothle.encodedStringToBytes(result.value);
          resolve(bytes);
        },
        reject,
        {
          address: address,
          service: '594F4E47-4157-534E-2000-C7907585FC48',
          characteristic: '594F4E47-4157-534E-2001-C7907585FC48',
        }
      );
    });
  }

  public stopAdvertising(): Promise<string> {
    return new Promise((resolve, reject) => {
      bluetoothle.stopAdvertising(resolve, reject);
    });
  }

  public updateMtu(address: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      bluetoothle.mtu(
        (result) => {
          if (result.status == 'mtu') {
            console.log('MTU updated: ', result.mtu);
          }
          resolve(true);
        },
        reject,
        {
          address: address,
          mtu: 256,
        }
      );
    });
  }

  public write(address: string, bytes: Uint8Array): Promise<Uint8Array> {
    const vi = new Uint8Array([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]);
    const combinedArray = new Uint8Array(vi.length + bytes.length);
    combinedArray.set(vi);
    combinedArray.set(bytes, vi.length);
    return new Promise((resolve, reject) => {
      bluetoothle.write(
        (result) => {
          const bytes = bluetoothle.encodedStringToBytes(result.value);
          resolve(bytes);
        },
        reject,
        {
          address: address,
          service: '594F4E47-4157-534E-2000-C7907585FC48',
          characteristic: '594F4E47-4157-534E-2001-C7907585FC48',
          value: bluetoothle.bytesToEncodedString(combinedArray),
        }
      );
    });
  }

  public handleError(error): void {
    console.log('Entered handleError(): ', JSON.stringify(error));
    let msg: string;

    if (error.error && error.message) {
      const errorItems = [];

      if (error.service) {
        errorItems.push('service: ' + (uuids[error.service] || error.service));
      }

      if (error.characteristic) {
        errorItems.push('characteristic: ' + (uuids[error.characteristic] || error.characteristic));
      }

      msg =
        'Error on ' +
        error.error +
        ': ' +
        error.message +
        (errorItems.length && ' (' + errorItems.join(', ') + ')');
    } else {
      msg = error;
    }

    this.log(msg, 'error');

    if (error.error == 'read' && error.service && error.characteristic) {
      this.reportValue(error.service, error.characteristic, 'Error: ' + error.message);
    }
  }

  public log(msg: string, level: string): void {
    level = level || 'log';

    if (typeof msg == 'object') {
      msg = JSON.stringify(msg, null, '  ');
    }

    console.log('Error message: ', msg);

    if (level == 'status' || level == 'error') {
      const msgDiv = document.createElement('div');
      msgDiv.textContent = msg;

      if (level == 'error') {
        msgDiv.style.color = 'red';
      }

      msgDiv.style.padding = '5px 0';
      msgDiv.style.borderBottom = 'rgb(192,192,192) solid 1px';
    }
  }

  public reportValue(serviceUuid: string, characteristicUuid: string, value: string): void {
    console.log('serviceUuid', JSON.stringify(serviceUuid));
    console.log('characteristicUuid', JSON.stringify(characteristicUuid));
    console.log('reportValue', JSON.stringify(value));
    document.getElementById(serviceUuid + '.' + characteristicUuid).textContent = value;
  }
}
