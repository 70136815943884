import { Injectable } from '@angular/core';

import { RoleEnum } from 'src/app/utils/util.service';

@Injectable()
export class StorageService {
  private static LANGUAGE_KEY: string = 'LANGUAGE_KEY';
  private static IS_AUTO_SYNC: string = 'IS_AUTO_SYNC';
  private static USER_ROLE: string = 'USER_ROLE';

  public getLanguageKey(): string {
    return localStorage.getItem(StorageService.LANGUAGE_KEY);
  }

  public setLanguageKey(languageKey: string): void {
    localStorage.setItem(StorageService.LANGUAGE_KEY, languageKey);
  }

  public getIsAutoSync(): boolean {
    return JSON.parse(localStorage.getItem(StorageService.IS_AUTO_SYNC));
  }

  public setIsAutoSync(isAutoSync: boolean): void {
    localStorage.setItem(StorageService.IS_AUTO_SYNC, isAutoSync.toString());
  }


  public getUserRole(): RoleEnum {
    return localStorage.getItem(StorageService.USER_ROLE) as RoleEnum;
  }

  public setUserRole(role: RoleEnum): void {
    localStorage.setItem(StorageService.USER_ROLE, role);
  }

  public clear(): void {
    localStorage.clear();
  }
}
