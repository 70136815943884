<div
  class="mobile"
  *ngIf="belt">
  <app-mobile-header
    [header]="'Manually Add RFID'"
    [routerLinkUrl]="['/fitting']">
  </app-mobile-header>

  <div class="mx-3">
    <div class="bg-white card p-3 mt-3">
      <label
        for="beltRfid"
        class="form-label"
        >RFID</label
      >
      <input
        [(ngModel)]="belt.rfidTagId"
        class="form-control mt-2"
        name="beltRfid"
        type="text"
        placeholder="eg: 34735693" />
      <button
        (click)="navToHome()"
        type="button"
        class="btn btn-primary mt-4">
        Pair
      </button>
    </div>
  </div>
</div>
