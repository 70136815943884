<app-desktop-header [header]="title">
  <div
    class="d-flex gap-2"
    content>
    <button
      class="btn btn-outline-secondary"
      (click)="openImportDialog(ammcareImportDialog)">
      <i class="fa fa-file-import"></i>
      {{ t.translate("_IMPORT") }}
    </button>
    <button
      class="btn btn-primary"
      (click)="onSave()"
      [disabled]="isSavingItem">
      {{ t.translate("_SUBMIT") }}
    </button>
  </div>
</app-desktop-header>

<div class="mx-0 mx-lg-5 mt-lg-8">
  <app-mobile-header
    [header]="title"
    [routerLinkUrl]="['/surveys', survey.id]"
    *ngIf="survey">
  </app-mobile-header>

  <div class="bg-white rounded mt-2 mb-4 mt-md-4 p-md-3">
    <div class="mobile">
      <div class="d-flex align-items-center justify-content-between px-3 py-2 border-bottom mb-3">
        <h1 class="header my-2">{{ t.translate("_BELT_CONFIG_DETAILS") }}</h1>
        <button
          class="btn btn-outline-primary"
          (click)="openImportDialog(ammcareImportDialog)">
          <i class="fa fa-file-import"></i>
          {{ t.translate("_IMPORT") }}
        </button>
      </div>
    </div>

    <form *ngIf="belt && survey">
      <div class="d-flex flex-wrap">
        <div class="col-12 col-lg-6 p-3">
          <div class="position-relative">
            <label
              class="form-label z-1"
              for="image">
              {{ t.translate("_IMAGE") }}
            </label>
            <div class="d-flex flex-column justify-content-around">
              <image-input
                [size]="200"
                [(image)]="imagePreview"
                (change)="onImageInputChange($event)">
              </image-input>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6 pt-lg-3">
          <div class="ps-lg-3 pt-lg-0 p-3">
            <div class="position-relative">
              <label
                class="form-label"
                for="productionLine">
                {{ t.translate("_PRODUCTION_LINE") }}
              </label>

              <ng-select
                [addTag]="true"
                [items]="surveyProdLines"
                [(ngModel)]="currentProdLine"
                (ngModelChange)="setBeltProdLine($event)"
                [placeholder]="t.translate('_PRODUCTION_LINE')"
                name="productionLine"
                data-testid="production-line-dropdown">
              </ng-select>
            </div>
          </div>

          <div class="ps-lg-3 p-3">
            <app-input
              [form]="beltForm"
              [label]="t.translate('_PRODUCT_REFERENCE')"
              type="text"
              name="productReference"
              [placeholder]="t.translate('_PRODUCT_REFERENCE')"
              [(model)]="belt.productReference"
              data-testid="conveyor-reference-input">
            </app-input>
          </div>

          <div class="ps-lg-3 p-3">
            <app-input
              [form]="beltForm"
              [label]="t.translate('_WTL')"
              type="text"
              name="wtl"
              [placeholder]="t.translate('_WTL')"
              [(model)]="belt.wtl"
              data-testid="WTL">
            </app-input>
          </div>
        </div>
      </div>

      <div class="p-3">
        <div class="position-relative">
          <label
            class="form-label"
            for="synOrMod">
            {{ t.translate("_BELT_TYPE") }} ({{ t.translate("_SYNTHETIC_OR_MODULAR") }})
            <span class="text-danger small">*</span>
          </label>
          <select
            class="form-control py-3"
            name="synOrMod"
            [class.border-danger]="beltForm.submitted && !belt.synOrMod"
            [(ngModel)]="belt.synOrMod"
            (ngModelChange)="checkSynOrMod()"
            data-testid="type-dropdown">
            <option
              [ngValue]="null"
              disabled>
              {{ t.translate("_SELECT_BELT_TYPE") }}
            </option>
            <option [ngValue]="'synthetic'">{{ t.translate("_SYNTHETIC") }}</option>
            <option [ngValue]="'modular'">{{ t.translate("_MODULAR") }}</option>
          </select>
          <div
            class="errors text-danger"
            *ngIf="beltForm.submitted && !belt.synOrMod">
            <div class="error">{{ t.translate("_REQUIRED") }}</div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="belt.synOrMod == 'synthetic'">
        <div class="p-3">
          <div class="position-relative">
            <label
              class="form-label"
              for="material">
              {{ t.translate("_MATERIAL") }}
            </label>
            <ng-select
              [(ngModel)]="belt.material"
              [ngModelOptions]="{ standalone: true }"
              [addTag]="true"
              placeholder="E.g. 577001 or 2 ply PU A21 Blue">
              <ng-option
                [value]="item.material"
                *ngFor="let item of syntheticMaterials$ | async">
                <div>
                  <div class="fw-bold">{{ item.material }}</div>
                  <div>{{ item.title }}</div>
                </div>
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_LENGTH') + ' (mm)'"
            placeholder=""
            type="number"
            name="length"
            min="1"
            [(model)]="belt.length">
          </app-input>
        </div>

        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_WIDTH') + ' (mm)'"
            type="number"
            name="width"
            min="1"
            [(model)]="belt.width">
          </app-input>
        </div>

        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_QUANTITY')"
            placeholder=""
            type="number"
            name="quantity"
            min="1"
            [(model)]="belt.quantity">
          </app-input>
        </div>

        <div class="p-3">
          <div class="position-relative">
            <label
              class="form-label"
              for="joint">
              {{ t.translate("_JOINT") }}
              <span class="text-danger">*</span>
            </label>
            <ng-select
              [class.border-danger]="highlightErrors && !belt.joint"
              [(ngModel)]="belt.joint"
              [ngModelOptions]="{ standalone: true }"
              [addTag]="true"
              [placeholder]="t.translate('_SELECT_A_JOINT')">
              <ng-option
                [value]="joint.key"
                *ngFor="let joint of jointTypes">
                <div class="text-capitalize">{{ joint.value }}</div>
              </ng-option>
            </ng-select>
            <div
              class="errors text-danger"
              *ngIf="highlightErrors && !belt.joint">
              <div class="error">{{ t.translate("_REQUIRED") }}</div>
            </div>
          </div>
        </div>

        <div class="p-3 d-flex">
          <div class="col-6">
            <div class="position-relative me-3">
              <label
                class="form-label"
                for="material">
                {{ t.translate("_WELD_TYPE") }}
              </label>
              <ng-select
                [(ngModel)]="belt.weldType"
                [ngModelOptions]="{ standalone: true }"
                [addTag]="true"
                [placeholder]="t.translate('_SELECT_A_WELD_TYPE')">
                <ng-option
                  [value]="weldType.key"
                  *ngFor="let weldType of weldTypes">
                  <div class="text-capitalize">
                    {{ weldType.value }}
                  </div>
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="position-relative col-6">
            <label
              class="form-label"
              for="weld">
              {{ t.translate("_WELD_DIRECTION") }}
            </label>
            <ng-select
              [(ngModel)]="belt.weldDirection"
              [ngModelOptions]="{ standalone: true }"
              [placeholder]="t.translate('_SELECT_A_WELD_DIRECTION')">
              <ng-option
                [value]="weldDirection.key"
                *ngFor="let weldDirection of weldDirections">
                <div class="text-capitalize">
                  {{ weldDirection.value }}
                </div>
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_TRACKING_GUIDE')"
            type="text"
            name="trackingGuide"
            [(model)]="belt.trackingGuide"
            data-testid="tracking-guide-input">
          </app-input>
        </div>
      </ng-container>

      <ng-container *ngIf="belt.synOrMod == 'modular'">
        <div class="p-3">
          <div class="position-relative">
            <label
              class="form-label"
              for="beltType">
              {{ t.translate("_BELT_TYPE") }}
            </label>
            <ng-select
              [(ngModel)]="belt.beltType"
              [ngModelOptions]="{ standalone: true }"
              [addTag]="true"
              [items]="modularTypes$ | async"
              bindLabel="type"
              bindValue="type"
              placeholder="E.g. Flex ASB">
            </ng-select>
          </div>
        </div>

        <div class="p-3">
          <div class="position-relative">
            <label
              class="form-label"
              for="beltMaterial">
              {{ t.translate("_BELT_MATERIAL") }}
            </label>
            <ng-select
              [(ngModel)]="belt.beltMaterial"
              [ngModelOptions]="{ standalone: true }"
              [addTag]="true"
              [items]="modularMaterials$ | async"
              bindLabel="material"
              bindValue="material"
              placeholder="E.g. POM">
            </ng-select>
          </div>
        </div>

        <div class="p-3">
          <div class="position-relative">
            <label
              class="form-label"
              for="beltColour">
              {{ t.translate("_BELT_COLOUR") }}
            </label>
            <ng-select
              [(ngModel)]="belt.beltColour"
              [ngModelOptions]="{ standalone: true }"
              [addTag]="true"
              [items]="modularColours$ | async"
              bindLabel="colour"
              bindValue="colour"
              placeholder="E.g. Blue">
            </ng-select>
          </div>
        </div>

        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_PIN_MATERIAL')"
            type="text"
            name="pinMaterial"
            [(model)]="belt.pinMaterial">
          </app-input>
        </div>

        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_LENGTH') + ' (mm)'"
            placeholder=""
            type="number"
            name="length"
            min="1"
            [(model)]="belt.length">
          </app-input>
        </div>

        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_WIDTH') + ' (mm)'"
            type="number"
            name="width"
            min="1"
            [(model)]="belt.width">
          </app-input>
        </div>

        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_QUANTITY')"
            placeholder=""
            type="number"
            name="quantity"
            min="1"
            [(model)]="belt.quantity">
          </app-input>
        </div>

        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_SPROCKET_PD')"
            [placeholder]="t.translate('_WHAT_IS_THE_PITCH_DIAMETER_OR_NUMBER_OF_TEETH')"
            type="text"
            name="sprocketPd"
            [(model)]="belt.sprocketPd">
          </app-input>
        </div>

        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_SPROCKET_BORE') + '(' + t.translate('_SIZE_AND_SHAPE') + ')'"
            [placeholder]="t.translate('_E.G_40mm_SQUARED')"
            type="type"
            name="sprocketBore"
            [(model)]="belt.sprocketBore">
          </app-input>
        </div>
      </ng-container>

      <ng-container *ngIf="belt.synOrMod">
        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_CARRIERS/BORDOFLEX')"
            type="text"
            name="carriersBordoflex"
            [(model)]="belt.carriersBordoflex"
            data-testid="carriers-bordoflex-input">
          </app-input>
        </div>

        <div class="p-3">
          <div class="position-relative">
            <label
              class="form-label"
              for="beltAccess">
              {{ t.translate("_BELT_ACCESS_ENVIRONMENT") }}
            </label>
            <ng-select
              [(ngModel)]="beltAccess"
              [ngModelOptions]="{ standalone: true }"
              [addTag]="true"
              [multiple]="true"
              [placeholder]="t.translate('_HOW_SHOULD_YOU_ACCESS_THE_BELT?')">
              <ng-option
                [value]="beltAccessType.key"
                *ngFor="let beltAccessType of beltAccessTypes">
                <div class="text-capitalize">
                  {{ beltAccessType.value }}
                </div>
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="p-3">
          <app-input
            [form]="beltForm"
            [label]="t.translate('_ITEM_REFERENCE')"
            placeholder="NLO-xxxxxx"
            name="itemReference"
            type="text"
            [(model)]="belt.itemReference"
            data-testid="comments-item-reference">
          </app-input>
        </div>

        <div class="p-3">
          <div class="position-relative">
            <label
              class="form-label"
              for="commentsOptions">
              {{ t.translate("_COMMENTS") }}
            </label>
            <ng-select
              id="commentsOptions"
              [(ngModel)]="comments"
              [ngModelOptions]="{ standalone: true }"
              [addTag]="true"
              [multiple]="true"
              [placeholder]="t.translate('_PLEASE_ENTER_ANY_ADDITIONAL_INFORMATION')">
              <ng-option
                [value]="comment.key"
                *ngFor="let comment of beltCommentOptions">
                <div class="text-capitalize">
                  {{ comment.value }}
                </div>
              </ng-option>
            </ng-select>
          </div>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="px-2 mb-4 mobile">
    <button
      type="submit"
      class="btn btn-primary w-100 mb-4"
      data-testid="submit-button"
      (click)="onSave()"
      [disabled]="isSavingItem">
      {{ t.translate("_SUBMIT") }}
    </button>
  </div>
</div>

<ng-template
  let-modal
  #ammcareImportDialog>
  <h4 class="modal-header">
    {{ t.translate("_IMPORT_FROM_AMMCARE") }}
  </h4>

  <div class="modal-body position-relative ammscan-content">
    <div class="position-relative">
      <label class="form-label">{{ t.translate("_AMMCARE_BELT") }}</label>
      <ng-select
        data-testid="ammcare-items-dropdown"
        [class.border-danger]="!ammcareItemOptionId && isImportFormSubmitted"
        [items]="ammcareItems"
        [(ngModel)]="ammcareItemOptionId"
        (ngModelChange)="setAmmcareImportItem()"
        [placeholder]="beltImportPlaceholder"
        bindValue="id">
        <ng-template
          ng-option-tmp
          ng-label-tmp
          let-item="item">
          <div>
            <div>{{ item.machine_id || "No CPR" }}</div>
            <div class="d-flex gap-2 small text-muted">
              <div>{{ item.productionLine || t.translate("_NO_LINE") }}</div>
              <div>({{ item.length || "?" }}mm x {{ item.width || "?" }}mm)</div>
            </div>
          </div>
        </ng-template>
      </ng-select>
      <div
        class="errors"
        *ngIf="isImportFormSubmitted && !ammcareItemOptionId">
        <div class="error">{{ t.translate("_REQUIRED") }}</div>
      </div>
    </div>

    <div class="mt-3">
      <div>{{ t.translate("_SELECT_ITEMS_FOR_IMPORT") }}</div>
      <div class="gap-3 d-flex justify-content-between align-items-start text-center mt-2">
        <div
          class="import-item-container"
          [class.disabled]="field.disabled"
          *ngFor="let field of importItemFields">
          <div
            (click)="toggleImport(field)"
            [class.border-danger]="
              isImportFormSubmitted &&
              this.selectedFields.length == 0 &&
              this.availableFields.length > 0
            "
            [class.selected]="field.selected"
            class="import-item rounded material-symbols-outlined border">
            {{ field.logo }}
          </div>
          <div class="mt-2">{{ field.translation }}</div>
        </div>
      </div>
      <div
        class="mt-3 alert alert-warning text-small"
        *ngIf="overwrittenFields.length">
        {{ overwrittenFields.toString().replaceAll(",", ", ") }} will be overwritten with imported
        values
      </div>
    </div>
  </div>

  <div class="modal-footer d-flex align-items-center flex-end">
    <a
      href="javascript:;"
      class="link fw-bold text-decoration-none me-5"
      (click)="closeImportDialog()">
      {{ t.translate("_CANCEL") }}
    </a>
    <button
      class="btn btn-primary"
      (click)="importAmmcareItem()">
      {{ t.translate("_IMPORT") }}
    </button>
  </div>
</ng-template>
