import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';

import { TranslateService } from '../../services/translate/translate.service';
import { UtilService } from '../../utils/util.service';

@Component({ templateUrl: 'delete-modal.component.html' })
export class DeleteModalComponent {
  public options: DeleteModalOptions;

  public constructor(
    public bsModalRef: BsModalRef,
    public t: TranslateService,
    public u: UtilService,
  ) {}

  public ngOnInit(): void {
    this.componentTranslations();
  }

  public componentTranslations(): void {
    this.t.translate('_YES');
    this.t.translate('_NO');
  }

  public onDelete(): void {
    this.options.delete();
    this.bsModalRef.hide();
  }

  public close(): void {
    this.bsModalRef.hide();
  }
}

export class DeleteModalOptions {
  public title: string;
  public message: string;
  public delete: () => void;
}
