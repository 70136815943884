import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { CBelt } from 'src/app/models/cBelt.model';
import { Installation } from 'src/app/models/installation.model';

import { ApiService } from '../api/api.service';
import { CBeltApiService } from '../api/cBeltApi.service';

class BeltDetails {
  public length: number;
  public width: number;
  public cpr: string;
  public salesLocationCode: string;
}

@Injectable({ providedIn: 'root' })
export class BeltsService {
  public constructor(
    private apiService: ApiService,
    private cBeltApiService: CBeltApiService,
  ) {}

  public async getAllBelts(): Promise<CBelt[]> {
    const options = { params: { pageSize: 1000 } };
    const response = await this.cBeltApiService.get('/belts', options);
    return response['result']['items'].map((x) => CBelt.fromJson(x));
  }

  public async getAllInstallations(): Promise<Installation[]> {
    const response = await this.cBeltApiService.get('/belts');
    return response['result']['items'].map((x) => Installation.fromJson(x));
  }

  public async getBelts(tagId: string): Promise<CBelt[]> {
    const options = { params: { rfidTagId: tagId, unpaired: true } };
    const response = await this.cBeltApiService.get('/belts', options);
    return response['result']['items'].map((x) => CBelt.fromJson(x));
  }

  public async addBelt(belt: CBelt): Promise<CBelt> {
    return await this.cBeltApiService.post('/belts', belt);
  }

  public async updateBelt(belt: CBelt): Promise<CBelt> {
    return await this.cBeltApiService.put(`/belts/${belt.id}`, belt);
  }

  public async updateBeltWithConveyorId(belt: CBelt): Promise<CBelt> {
    const body = { conveyorId: belt.conveyorId };
    return await this.cBeltApiService.put(`/belts/${belt.id}/conveyors`, body);
  }

  public async deleteBelt(belt: CBelt): Promise<CBelt> {
    return await this.cBeltApiService.delete(`/belts/${belt.id}`);
  }

  public async getSalesLocationCode(orderNumber: string): Promise<BeltDetails> {
    const ammcareBaseUrl = environment.ammcareBaseUrl;
    const response = await this.apiService.get(
      ammcareBaseUrl + `/machines/item-details/${orderNumber}`,
      {},
    );
    return response['result'];
  }
}
