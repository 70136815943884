import { cloneDeep } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CBelt } from '../../../../models/cBelt.model';
import { Conveyor } from '../../../../models/conveyor.model';
import { CBeltMachine } from '../../../../models/cBeltMachine.model';

import { StateService } from '../../../../services/c-belt/state.service';
import { AlertService } from '../../../../shared/services/alert/alert.service';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigurationState } from '../../../../state/installation/installation.state';
import * as InstallationActions from '../../../../state/installation/installation.actions';
import * as InstallationSelectors from '../../../../state/installation/installation.selector';
import { ConveyorReader } from 'src/app/models/conveyorReader.model';
import { Sensor } from 'src/app/models/sensor.model';

@Component({
  selector: 'app-configuration-overview',
  templateUrl: './configuration-overview.component.html',
  styleUrls: ['./configuration-overview.component.scss'],
})
export class ConfigurationOverviewComponent implements OnInit {
  public companyId: string;
  public belts: CBelt[] = [];
  public editing: boolean = false;
  public conveyors: Conveyor[] = [];
  public selectedMachine: CBeltMachine;
  public current: ConfigurationState = null;
  public containsRfidReader: boolean = false;

  public constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateService,
    private alertService: AlertService,
  ) {}

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.subscribeToCurrentConfiguration();
    });
  }

  public async subscribeToCurrentConfiguration(): Promise<void> {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.editing = current.editing;
        this.containsRfidReader = current.containsRfidReader;
      });
  }

  public selectMachine(machine: CBeltMachine): void {
    this.selectedMachine = machine;
    this.getConveyorsByMachineId(machine.id);
  }

  public getConveyorsByMachineId(machineId: string): void {
    this.conveyors = this.current.conveyors.filter((x) => x.machineId == machineId);
  }

  public getConveyorReadersForMachineAndConveyor(
    machineId: string,
    conveyorAlias: string,
  ): ConveyorReader {
    return this.current.conveyorReaders.find(
      (x) => x.id == machineId.trim() + conveyorAlias.trim(),
    );
  }

  public getSensorsForConveyor(conveyorId: string): Sensor[] {
    return this.current.sensors.filter((x) => x.conveyorId == conveyorId);
  }

  public addConfigToHistory(): void {
    if (!this.validateConfiguration()) {
      this.alertService.alertError('Invalid Configuration.');
      return;
    }

    if (this.editing) {
      this.stateService.updateConfigurationHistory(this.current);
    } else {
      this.stateService.addCurrentToHistory(this.current);
    }

    this.store.dispatch(InstallationActions.resetState());

    if (this.editing) {
      this.alertService.alertSuccess('Configuration successfully updated.');
    } else {
      this.alertService.alertSuccess('Configuration successfully added.');
    }

    this.router.navigate(['c-belt']);
  }

  public validateConfiguration(): boolean {
    const company: boolean = this.validateCompany();
    const gateway: boolean = this.validateGateways();
    const conveyorReader: boolean = this.validateConveyorReaders();
    const conveyor: boolean = this.validateConveyors();
    const sensors: boolean = this.validateSensors();

    return company && gateway && conveyorReader && conveyor && sensors;
  }

  public validateCompany(): boolean {
    if (!this.current.company) {
      this.alertService.alertError('No Company.');
      return false;
    }
    if (this.current.company.customer_name && this.current.company.customerNumber) {
      return true;
    } else {
      this.alertService.alertError('Invalid Company.');
      return false;
    }
  }

  public validateGateways(): boolean {
    for (const gateway of this.current.gateways) {
      if (!this.current.gateways.length) {
        this.alertService.alertError('No Gateway Devices.');
        return false;
      }

      switch (gateway.wifiType) {
        case '4g Device':
          return true;
        case 'Static':
          return (
            gateway.gatewayDeviceId != null &&
            gateway.networkName != null &&
            gateway.devicePassword != null &&
            gateway.ipAddress != null &&
            gateway.networkMask != null &&
            gateway.gatewayAddress != null &&
            gateway.dns != null
          );
        case 'DHCP':
          return (
            gateway.gatewayDeviceId != null &&
            gateway.networkName != null &&
            gateway.devicePassword != null
          );
      }
    }
    return false;
  }

  public validateConveyorReaders(): boolean {
    for (const conveyorReader of this.current.conveyorReaders) {
      if (!conveyorReader) {
        this.alertService.alertError('No Conveyor Readers.');
        return false;
      }
      if (
        (conveyorReader.machineName &&
          conveyorReader.conveyorName &&
          conveyorReader.diameter &&
          conveyorReader.readerId) ||
        (!this.current.containsRfidReader &&
          conveyorReader.machineName &&
          conveyorReader.conveyorName &&
          conveyorReader.diameter &&
          conveyorReader.idleDrumDiameter &&
          !conveyorReader.readerId)
      ) {
        return true;
      } else {
        this.alertService.alertError('Invalid Conveyor Reader.');
        return false;
      }
    }
    return false;
  }

  public validateConveyors(): boolean {
    for (const conveyor of this.current.conveyors) {
      if (!conveyor) {
        this.alertService.alertError('No Conveyors.');
        return false;
      }
      if (conveyor.aliasName && conveyor.diameter) {
        return true;
      } else {
        this.alertService.alertError(`Conveyor Invalid.`);
        return false;
      }
    }
    return false;
  }

  public validateSensors(): boolean {
    if (!this.current.sensors.length) {
      this.alertService.alertError('No Sensors.');
      return false;
    }
    for (let i = 0; i < this.current.sensors.length; i++) {
      if (
        this.current.sensors[i].conveyorId &&
        this.current.sensors[i].sid &&
        this.current.sensors[i].edid
      ) {
        return true;
      } else {
        this.alertService.alertError(`Invalid Sensor ${i + 1} Invalid.`);
        return false;
      }
    }
    return true;
  }
}
