import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';

import { AlertService } from './../../../shared/services/alert/alert.service';
import { AuthService } from '../../../services/auth/auth.service';
import {
  Breadcrumb,
  BreadcrumbService,
} from './../../../shared/services/breadcrumb/breadcrumb.service';
import { LoadingService } from './../../../shared/services/loading/loading.service';
import { SyncService } from './../../../services/sync/sync.service';
import { TranslateService } from '../../../services/translate/translate.service';

import { Belt } from '../../../models/belt.model';
import { Survey } from './../../../models/survey.model';

import * as BeltActions from '../../../state/belt/belt.actions';
import * as SurveySelectors from '../../../state/survey/survey.selector';

@Component({
  selector: 'app-belt-create',
  templateUrl: './belt-create.component.html',
  styleUrls: ['./belt-create.component.scss'],
})
export class BeltCreateComponent implements OnInit, OnDestroy {
  private survey: Survey;
  private surveySubscription: Subscription;

  public belt: Belt;
  public survey$: Observable<Survey>;

  public constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private syncService: SyncService,
    public t: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.survey$ = this.store.select(SurveySelectors.selectSurveyById({ id: params['survey'] }));
      this.surveySubscription = this.survey$.subscribe((survey) => (this.survey = survey));

      this.belt = new Belt(params['survey']);
    });

    const crumb = new Breadcrumb(this.t.translate('_CREATE_BELT_CONFIG'), [
      '/',
      'surveys',
      this.survey?.id,
      'belts',
      'create',
    ]);
    this.breadcrumbService.pushBreadcrumb(crumb);
  }

  public ngOnDestroy(): void {
    this.surveySubscription.unsubscribe();
  }

  public async createBelt(belt: Belt): Promise<void> {
    this.loadingService.show();
    try {
      belt.id = uuidv4();
      belt.surveyor = { email: this.authService.getUser().email };

      this.store.dispatch(BeltActions.addBeltSuccess({ belt }));
      this.syncService.trySyncUpToApi();

      this.breadcrumbService.popBreadcrumb();
      this.router.navigate(['/surveys', this.survey.id]);

      this.alertService.alertSuccess(this.t.translate('_BELT_CONFIG_ADDED_SUCCESSFULLY'));
    } catch (err) {
      this.alertService.handleHttpError(err);
    }
    this.loadingService.hide();
  }
}
