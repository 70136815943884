import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { StateService } from 'src/app/services/c-belt/state.service';

import { Store } from '@ngrx/store';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';

@Component({
  selector: 'app-select-configuration-pair-rfid',
  templateUrl: './select-configuration-pair-rfid.component.html',
  styleUrls: ['./select-configuration-pair-rfid.component.scss'],
})
export class SelectConfigurationPairRfidComponent implements OnInit {
  public history: ConfigurationState[] = [];

  public constructor(
    private store: Store,
    private router: Router,
    private stateService: StateService
  ) {}

  public ngOnInit(): void {
    this.loadHistory();
  }

  public loadHistory(): void {
    this.history = this.stateService.loadConfigurationHistory();
  }

  public navTo(route: string, machineId: string): void {
    const current = this.history.find((x) => x.conveyors.find((y) => y.machineId == machineId));
    this.store.dispatch(InstallationActions.setCurrentState({ current }));
    this.stateService.subscribeToCurrentAndSave();
    this.toggleEditingStatus(true);
    this.router.navigate(['/installing', route, 'system-setup-pair-rfid']);
  }

  public toggleEditingStatus(status: boolean): void {
    this.store.dispatch(InstallationActions.updateEditingStatus({ editing: status }));
  }
}
