export var Operator;
(function (Operator) {
    /** Equal To Operator */
    Operator["EQUAL"] = "EQUAL";
    /** Not Equal To Operator */
    Operator["NOT_EQUAL"] = "NOT_EQUAL";
    /** And Operator */
    Operator["AND"] = "AND";
    /** Or Operator */
    Operator["OR"] = "OR";
    /** Greater Than Operator */
    Operator["GT"] = "GT";
    /** Greater Than or Equal To Operator */
    Operator["GTE"] = "GTE";
    /** Less Than Operator */
    Operator["LT"] = "LT";
    /** Less Than or Equal To Operator */
    Operator["LTE"] = "LTE";
    /** Like Operator */
    Operator["LIKE"] = "LIKE";
    /** Not Like Operator */
    Operator["NOT_LIKE"] = "NOT_LIKE";
    /** In Operator */
    Operator["IN"] = "IN";
})(Operator || (Operator = {}));
export var OrderDirection;
(function (OrderDirection) {
    /** Ascending Order Direction */
    OrderDirection["ASC"] = "ASC";
    /** Descending Order Direction */
    OrderDirection["DESC"] = "DESC";
})(OrderDirection || (OrderDirection = {}));
