<div class="mobile">
  <app-mobile-header
    [header]="'4G Connection'"
    [routerLinkUrl]="[
      '/installing',
      companyId,
      'gateway-configuration',
      'new-gateway-device'
    ]"
  >
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <form name="configuration-form" class="d-flex" (submit)="navToSteps()">
      <div class="bg-white card p-3">
        <div>Checking 4G connection...</div>

        <button type="submit" class="btn btn-primary mt-4">Save</button>
      </div>
    </form>
  </div>
</div>
