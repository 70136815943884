import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NFC } from '@awesome-cordova-plugins/nfc/ngx';

import { SharedModule } from '../shared/shared.module';
import { InstallationRoutingModule } from './intallation-routing.module';

import { CBeltApiService } from '../services/api/cBeltApi.service';
import { OrganizationsService } from '../services/c-belt/organizations.service';

import { CBeltComponent } from './c-belt/c-belt.component';
import { ScanSensorComponent } from './c-belt/scan-sensor/scan-sensor.component';
import { ScanningComponent } from './c-belt/scanning/scanning/scanning.component';

import { CbeltListComponent } from './c-belt/installing/cbelt-list/cbelt-list.component';
import { EditSensorComponent } from './c-belt/installing/edit-sensor/edit-sensor.component';
import { AddSensorsComponent } from './c-belt/installing/add-sensors/add-sensors.component';
import { SystemSetupComponent } from './c-belt/installing/system-setup/system-setup.component';
import { ConfigureWifiComponent } from './c-belt/installing/configure-wifi/configure-wifi.component';
import { InstallingHomeComponent } from './c-belt/installing/installing-home/installing-home.component';
import { NewGatewayDeviceComponent } from './c-belt/installing/new-gateway-device/new-gateway-device.component';
import { ConfiguringDeviceComponent } from './c-belt/installing/configuring-device/configuring-device.component';
import { CBeltInstallationComponent } from './c-belt/installing/c-belt-installation/c-belt-installation.component';
import { SystemSetupSensorsComponent } from './c-belt/installing/system-setup-sensors/system-setup-sensors.component';
import { AddSensorManuallyComponent } from './c-belt/installing/add-sensor-manually/add-sensor-manually.component';
import { PairConveyorReaderComponent } from './c-belt/installing/pair-conveyor-reader/pair-conveyor-reader.component';
import { SensorConfigurationComponent } from './c-belt/installing/sensor-configuration/sensor-configuration.component';
import { GatewayConfigurationComponent } from './c-belt/installing/gateway-configuration/gateway-configuration.component';
import { SystemSetupPairRfidComponent } from './c-belt/installing/system-setup-pair-rfid/system-setup-pair-rfid.component';
import { PairConveyorAndBeltComponent } from './c-belt/installing/pair-conveyor-and-belt/pair-conveyor-and-belt.component';
import { ConfigurationOverviewComponent } from './c-belt/installing/configuration-overview/configuration-overview.component';
import { BeltValidationComponent } from './c-belt/installing/configuration-overview/belt-validation/belt-validation.component';
import { ConfiguringDeviceFourGComponent } from './c-belt/installing/configuring-device-four-g/configuring-device-four-g.component';
import { SensorValidationComponent } from './c-belt/installing/configuration-overview/sensor-validation/sensor-validation.component';
import { CalibrateConveyorReaderComponent } from './c-belt/installing/calibrate-conveyor-reader/calibrate-conveyor-reader.component';

import { GatewayValidationComponent } from './c-belt/installing/configuration-overview/gateway-validation/gateway-validation.component';
import { CompanyValidationComponent } from './c-belt/installing/configuration-overview/company-validation/company-validation.component';
import { ConveyorValidationComponent } from './c-belt/installing/configuration-overview/conveyor-validation/conveyor-validation.component';
import { PairConveyorReaderManuallyComponent } from './c-belt/installing/pair-conveyor-reader-manually/pair-conveyor-reader-manually.component';
import { ConveyorReaderConfigurationComponent } from './c-belt/installing/conveyor-reader-configuration/conveyor-reader-configuration.component';
import { ConveyorReaderValidationComponent } from './c-belt/installing/configuration-overview/conveyor-reader-validation/conveyor-reader-validation.component';
import { AddRfidManuallyComponent } from './c-belt/installing/add-rfid-manually/add-rfid-manually.component';

import { CBeltConfigurationComponent } from './c-belt/fitting/c-belt-configuration/c-belt-configuration.component';
import { ScanningRfidComponent } from './c-belt/fitting/scanning-rfid/scanning-rfid.component';
import { SelectCustomerComponent } from './c-belt/shared/select-customer/select-customer.component';
import { SelectConfigurationComponent } from './c-belt/modifying/select-configuration/select-configuration.component';
import { SelectConfigurationPairRfidComponent } from './c-belt/modifying/select-configuration-pair-rfid/select-configuration-pair-rfid.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AddSensorManuallyComponent,
    AddSensorsComponent,
    BeltValidationComponent,
    CalibrateConveyorReaderComponent,
    CBeltComponent,
    CBeltConfigurationComponent,
    CBeltInstallationComponent,
    CompanyValidationComponent,
    ConfigurationOverviewComponent,
    ConfigureWifiComponent,
    ConfiguringDeviceComponent,
    SystemSetupComponent,
    ConveyorReaderConfigurationComponent,
    SelectConfigurationComponent,
    PairConveyorReaderComponent,
    PairConveyorAndBeltComponent,
    PairConveyorReaderManuallyComponent,
    ConfiguringDeviceFourGComponent,
    AddSensorsComponent,
    ScanSensorComponent,
    SensorConfigurationComponent,
    SystemSetupSensorsComponent,
    SystemSetupPairRfidComponent,
    CbeltListComponent,
    SelectConfigurationPairRfidComponent,
    AddSensorManuallyComponent,
    EditSensorComponent,
    ConfigurationOverviewComponent,
    CompanyValidationComponent,
    ConfiguringDeviceFourGComponent,
    ConveyorReaderConfigurationComponent,
    ConveyorReaderValidationComponent,
    ConveyorValidationComponent,
    EditSensorComponent,
    AddRfidManuallyComponent,
    GatewayConfigurationComponent,
    GatewayValidationComponent,
    InstallingHomeComponent,
    NewGatewayDeviceComponent,
    PairConveyorAndBeltComponent,
    PairConveyorReaderComponent,
    PairConveyorReaderManuallyComponent,
    ScanningComponent,
    ScanningRfidComponent,
    ScanSensorComponent,
    SelectConfigurationComponent,
    SelectCustomerComponent,
    SensorConfigurationComponent,
    SensorValidationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    InstallationRoutingModule,
    SharedModule,
  ],
  providers: [CBeltApiService, OrganizationsService, NFC],
})
export class InstallationModule {}
