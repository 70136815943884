<div class="mobile">
  <app-mobile-header
    [header]="'Add Sensor'"
    [routerLinkUrl]="['/installing', companyId, 'sensor-configuration']">
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <form
      name="configuration-form"
      class="d-flex"
      *ngIf="!tagId">
      <div class="bg-white card p-3">
        <div>Hold the top of your phone against the sensor to scan its NFC tag</div>
        <button
          type="button"
          class="btn btn-primary mt-3"
          (click)="navToAddSensorManually()">
          Or: Add Manually
        </button>
      </div>
    </form>

    <form
      name="configuration-form"
      class="d-flex mt-3"
      *ngIf="!tagId && !isNfcActive">
      <div class="bg-white card p-3">
        <div class="d-flex justify-content-center red">Please Enable NFC</div>
        <button
          type="button"
          class="btn btn-primary mt-3"
          (click)="openSettings()">
          Open NFC Settings
        </button>
      </div>
    </form>

    <form
      name="configuration-form"
      class="d-flex mt-3"
      *ngIf="!tagId && isNfcActive">
      <div class="bg-white card p-3">
        <div class="d-flex justify-content-center">
          NFC Enabled: You may need to move the phone around, NFC scanner location vary amongst
          devices
        </div>
        <div class="d-flex justify-content-center">
          <img src="/assets/images/nfc_scanning.gif" />
        </div>
      </div>
    </form>

    <form
      name="configuration-form"
      class="d-flex mt-3"
      *ngIf="!tagId">
      <div class="bg-white card p-3 mb-3">
        <div>Add device via QR code</div>
        <div class="grey">Scan the QR code on top of the box the device came in</div>
        <button
          type="button"
          class="btn btn-primary mt-3"
          (click)="openAndScanQR()">
          SCAN QR
        </button>
      </div>
    </form>

    <form
      name="enable-nfc-form"
      class="d-flex mt-3"
      *ngIf="enabled != null && !enabled">
      <div class="bg-white card p-3">
        <div>NFC is disabled, please enable NFC then click 'Start Scan'</div>
        <button
          type="button"
          class="btn btn-primary mt-3"
          (click)="addListener()">
          Start Scan
        </button>
      </div>
    </form>
  </div>
</div>
