export class PairedSensor {
  public aliasName: string;
  public batteryLevel: number;
  public conveyorId: string;
  public conveyorName: string;
  public createdUtc: string;
  public description: string;
  public edid: string;
  public gatewayId: string;
  public gatewayName: string;
  public id: string;
  public isActive: boolean;
  public macAddress: string;
  public machineId: string;
  public machineName: string;
  public role: string;
  public sid: string;

  public static fromJson(json: {
    aliasName: string;
    batteryLevel: number;
    conveyorId: string;
    conveyorName: string;
    createdUtc: string;
    description: string;
    edid: string;
    gatewayId: string;
    gatewayName: string;
    id: string;
    isActive: boolean;
    macAddress: string;
    machineId: string;
    machineName: string;
    role: string;
    sid: string;
  }): PairedSensor {
    const sensor = new PairedSensor();
    sensor.aliasName = json.aliasName;
    sensor.batteryLevel = json.batteryLevel;
    sensor.conveyorId = json.conveyorId;
    sensor.conveyorName = json.conveyorName;
    sensor.createdUtc = json.createdUtc;
    sensor.description = json.description;
    sensor.edid = json.edid;
    sensor.gatewayId = json.gatewayId;
    sensor.gatewayName = json.gatewayName;
    sensor.id = json.id;
    sensor.isActive = json.isActive;
    sensor.macAddress = json.macAddress;
    sensor.machineId = json.machineId;
    sensor.machineName = json.machineName;
    sensor.role = json.role;
    sensor.sid = json.sid;
    return sensor;
  }
}
