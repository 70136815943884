import { Component } from '@angular/core';

import { AlertService } from '../../services/alert/alert.service';
import { IAlert } from '../../services/alert/alert.interface';
import { Animations } from '../../../services/animations/animations';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [Animations.slideInFadeOut()],
})
export class AlertComponent implements IAlert {
  public alerts: any[] = [];
  private timeout = 4000;

  public constructor(private alertService: AlertService) {
    this.alertService.subscribeToAlerts(this);
  }

  public onSuccessAlert(text: string): void {
    this.showAlert({
      text: text,
      time: new Date().getTime(),
      type: 'success',
    });
  }

  public onErrorAlert(text: string): void {
    this.showAlert({
      text: text,
      time: new Date().getTime(),
      type: 'error',
    });
  }

  public onWarningAlert(text: string): void {
    this.showAlert({
      text: text,
      time: new Date().getTime(),
      type: 'warning',
    });
  }

  public onInfoAlert(text: string): void {
    this.showAlert({
      text: text,
      time: new Date().getTime(),
      type: 'info',
    });
  }

  private showAlert(alert): void {
    this.alerts.push(alert);
    setTimeout(() => this.removeAlert(alert), this.timeout);
  }

  public removeAlert(alert: { time: unknown }): void {
    this.alerts = this.alerts.filter((x) => x.time != alert.time);
  }

  public getAlertClass(alert: { type: string }): string {
    if (alert.type == 'success') {
      return 'alert-success';
    }
    if (alert.type == 'error') {
      return 'alert-danger';
    }
    if (alert.type == 'warning') {
      return 'alert-warning';
    }
    if (alert.type == 'info') {
      return 'alert-info';
    }
    return '';
  }
}
