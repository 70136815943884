import { createAction, props } from '@ngrx/store';

import { ConfigurationState } from './installation.state';

import { CBelt } from 'src/app/models/cBelt.model';
import { Sensor } from 'src/app/models/sensor.model';
import { Gateway } from 'src/app/models/gateway.model';
import { Customer } from 'src/app/models/customer.model';
import { Conveyor } from 'src/app/models/conveyor.model';
import { CBeltMachine } from 'src/app/models/cBeltMachine.model';
import { ConveyorReader } from 'src/app/models/conveyorReader.model';

export const resetState = createAction('[C-Belt Reset State]');

export const updateCompany = createAction(
  '[C-Belt Update Company]',
  props<{ customer: Customer }>(),
);

export const updateBelt = createAction('[C-Belt Update Belt]', props<{ belt: CBelt }>());

export const setCurrentBelt = createAction('[C-Belt Set Current Belt]', props<{ belt: CBelt }>());

export const clearCurrentBelt = createAction('[C-Belt Clear Current Belt]');

export const updateConveyor = createAction(
  '[C-Belt Update Conveyor]',
  props<{ conveyor: Conveyor }>(),
);

export const setCurrentConveyor = createAction(
  '[C-Belt Set Current Conveyor]',
  props<{ conveyor: Conveyor }>(),
);

export const clearCurrentConveyor = createAction('[C-Belt Clear Current Conveyor]');

export const updateGatewayDevice = createAction(
  '[C-Belt Update Gateway Device]',
  props<{ gatewayDevice: Gateway }>(),
);

export const setCurrentGatewayDevice = createAction(
  '[C-Belt Set Current Gateway Device]',
  props<{ gatewayDevice: Gateway }>(),
);

export const clearCurrentGateway = createAction('[C-Belt Clear Current Gateway]');

export const updateConveyorReader = createAction(
  '[C-Belt Update Conveyor Reader]',
  props<{ conveyorReader: ConveyorReader }>(),
);

export const setCurrentConveyorReader = createAction(
  '[C-Belt Set Current Conveyor Reader]',
  props<{ conveyorReader: ConveyorReader }>(),
);

export const updateMachine = createAction(
  '[C-Belt Update Machine]',
  props<{ machine: CBeltMachine }>(),
);

export const setCurrentMachine = createAction(
  '[C-Belt Set Current Machine]',
  props<{ machine: CBeltMachine }>(),
);

export const clearCurrentMachine = createAction('[C-Belt Clear Current Machine]');

export const updateReaderCalibrated = createAction(
  '[C-Belt Update Reader Calibrated]',
  props<{ calibrated: boolean }>(),
);

export const addExistingSensors = createAction(
  '[C-Belt Add Existing Sensors]',
  props<{ sensors: Sensor[] }>(),
);

export const addSensorToEdit = createAction(
  '[C-Belt Add Sensor To Edit]',
  props<{ sensor: Sensor }>(),
);

export const updateSensor = createAction('[C-Belt Update Sensor]', props<{ sensor: Sensor }>());

export const deleteSensor = createAction('[C-Belt Delete Sensor]', props<{ sensor: Sensor }>());

export const updateNewSensorId = createAction(
  '[C-Belt Update New Sensor ID]',
  props<{ sensorId: string }>(),
);

export const updateEditingStatus = createAction(
  '[C-Belt Update Editing Status]',
  props<{ editing: boolean }>(),
);

export const updateContainsRfidStatus = createAction(
  '[C-Belt Update Contains RFID Status]',
  props<{ containsRfidReader: boolean }>(),
);

export const updateIsRndUserStatus = createAction(
  '[C-Belt Update isRndUser Status]',
  props<{ isRndUser: boolean }>(),
);

export const togglePairingRfid = createAction(
  '[C-Belt Toggle Pairing RFID Status]',
  props<{ pairingRfid: boolean }>(),
);

// Set Current State From Local Storage
export const setCurrentState = createAction(
  '[C-Belt Set Current State From Local Storage]',
  props<{ current: ConfigurationState }>(),
);
