<app-desktop-belt-list
  [survey]="survey"
  [belts]="filteredBelts"
  [filterCount]="getFilterCount()"
  [(searchText)]="searchText"
  (searchTextChange)="filterAndSortBelts()"
  (filterButtonClick)="showFilterModal()"
  (itemClick)="onItemClick($event)"
  (copyClick)="onCopyBelt($event)"
  (removeClick)="onRemoveBelt($event)"
  (removeSurveyClick)="onRemoveSurvey($event)"
  (downloadSurveyCsvClick)="downloadSurveyCsv()">
</app-desktop-belt-list>

<app-mobile-belt-list
  [survey]="survey"
  [belts]="filteredBelts"
  [filterCount]="getFilterCount()"
  [(searchText)]="searchText"
  (searchTextChange)="filterAndSortBelts()"
  (filterButtonClick)="showFilterModal()"
  (itemClick)="onItemClick($event)"
  (copyClick)="onCopyBelt($event)"
  (removeClick)="onRemoveBelt($event)"
  (downloadSurveyCsvClick)="downloadSurveyCsv()"
  *ngIf="isScreenSmall">
</app-mobile-belt-list>

<!-- TODO: refactor into own components -->
<ng-template
  #copyModal
  let-modal>
  <div class="modal-header align-items-start">
    <div
      class="modal-title"
      id="modal-basic-title">
      <h4>{{ t.translate("_COPY_BELT") }} '{{ currentBelt?.productReference || "" }}'</h4>
    </div>
    <div
      class="close pointer fs-3"
      aria-label="Close"
      (click)="hideModal()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <label class="mb-3"> {{ t.translate("_NEW_BELT_PRODUCT_REFERENCE") }}</label>
    <input
      type="text"
      class="form-control"
      [(ngModel)]="newBeltReference" />
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-primary"
        (click)="copyBelt(currentBelt)">
        {{ t.translate("_COPY_BELT") }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template
  #filterModal
  let-modal>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title">
      {{ t.translate("_FILTER") }}
    </h4>
    <div
      class="close pointer fs-3"
      aria-label="Close"
      (click)="hideModal()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="d-flex flex-wrap gap-md-2">
      <div class="col mb-4 position-relative">
        <label class="form-label">{{ t.translate("_BELT_TYPE") }}</label>
        <ng-select
          [placeholder]="t.translate('_SELECT_BELT_TYPE')"
          [(items)]="beltTypes"
          [(ngModel)]="selectedFilters.beltTypes"
          [multiple]="true"
          [notFoundText]="t.translate('_NOTHING_FOUND')">
        </ng-select>
      </div>

      <div class="col-12 col-md-6 mb-4 position-relative">
        <label class="form-label">{{ t.translate("_PRODUCTION_LINE") }}</label>
        <ng-select
          [placeholder]="t.translate('_SELECT_PRODUCTION_LINE')"
          [(items)]="productionLines"
          [(ngModel)]="selectedFilters.productionLines"
          [multiple]="true"
          [notFoundText]="t.translate('_NOTHING_FOUND')">
        </ng-select>
      </div>
    </div>

    <div class="position-relative">
      <label class="form-label">{{ t.translate("_DATE_RANGE") }}</label>
      <div class="position-relative d-flex align-items-center">
        <input
          type="text"
          [placeholder]="t.translate('_SELECT_THE_DATE_RANGE_OF_SURVEYS')"
          class="form-control py-3"
          bsDaterangepicker
          [maxDate]="maxDate"
          [(ngModel)]="selectedFilters.dates"
          [bsConfig]="bsConfig" />
        <div
          class="clear-date"
          title="Clear"
          (click)="clearDates()"
          *ngIf="selectedFilters.dates.length">
          &times;
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-outline-danger me-2"
        (click)="clearFilters()">
        {{ t.translate("_CLEAR") }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="applyFilters()">
        {{ t.translate("_APPLY_FILTER") }}
      </button>
    </div>
  </div>
</ng-template>
