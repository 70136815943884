import { Injectable } from '@angular/core';

import { Survey } from './../../models/survey.model';
import { UtilService } from './../../utils/util.service';

@Injectable()
export class SurveyService {
  private capitalizeArrayValues(arr: string[]): string[] {
    return arr.map((item) => UtilService.capitalizeFirstLetter(item));
  }

  private capitalizeAndSpaceFields(arr: string[]): string[] {
    let newArr = arr.map((x) => x.replace(/([A-Z])/g, ' $1'));
    newArr = this.capitalizeArrayValues(newArr);
    return newArr;
  }

  public getSurveyCsvString(survey: Survey): string {
    let csvContents: string;

    try {
      const baseHeaders1 = [
        'customerName',
        'attachmentUrl',
        'productionLine',
        'wtl',
        'productReference',
        'itemReference',
        'synOrMod',
        'material',
      ];
      const beltHeaders = ['beltType', 'beltMaterial', 'beltColour', 'pinMaterial'];
      const baseHeaders2 = [
        'length',
        'width',
        'quantity',
        'joint',
        'trackingGuide',
        'carriersBordoflex',
      ];
      const sprocketHeaders = ['sprocketPd', 'sprocketBore'];
      const baseHeaders3 = ['beltAccess', 'comments', 'created'];

      const formattedBaseHeaders1 = this.capitalizeAndSpaceFields(baseHeaders1);
      const formattedBeltHeaders = this.capitalizeAndSpaceFields(beltHeaders);
      const formattedBaseHeaders2 = this.capitalizeAndSpaceFields(baseHeaders2);
      const formattedSprocketHeaders = this.capitalizeAndSpaceFields(sprocketHeaders);
      const formattedBaseHeaders3 = this.capitalizeAndSpaceFields(baseHeaders3);

      // replace any commas with forward slash for belt and sprocket
      const beltHeader = formattedBeltHeaders.toString().replace(/,/g, '/');
      const sprocketHeader = formattedSprocketHeaders.toString().replace(/,/g, '/');

      // add all headers together with line break
      let headers = `${formattedBaseHeaders1.toString()},${beltHeader},
      ${formattedBaseHeaders2.toString()},
      ${sprocketHeader},${formattedBaseHeaders3.toString()}\n`;
      headers = headers.replace('Wtl', 'WTL');
      headers = headers.replace('Syn Or Mod', 'Product Group');

      // Populate CSV row header
      csvContents = headers;

      // Populate CSV rows
      survey.belts.forEach((belt) => {
        const prunedBelt: any = {};

        // add 1st batch standard columns
        baseHeaders1.forEach((key) => {
          if (key == 'customerName') {
            prunedBelt[key] = survey.customer.customer_name;
          } else {
            prunedBelt[key] = belt[key];
          }
        });

        // add merged pruned columns
        prunedBelt[beltHeader] = '';
        beltHeaders.forEach((key) => {
          prunedBelt[beltHeader] += `${belt[key] || ''} `;
        });

        // add 2nd batch standard columns
        baseHeaders2.forEach((key) => {
          if (key == 'joint') {
            prunedBelt[key] = UtilService.capitalizeAndReplaceUnderscores(belt[key]);
          } else {
            prunedBelt[key] = belt[key];
          }
        });

        // add merged sprocket columns
        prunedBelt[sprocketHeader] = '';
        sprocketHeaders.forEach((key) => {
          prunedBelt[sprocketHeader] += `${belt[key] || ''} `;
        });

        // add 3rd batch standard columns
        baseHeaders3.forEach((key) => {
          prunedBelt[key] = belt[key];
        });

        // sanitise data
        const prunedBeltValues = Object.values(prunedBelt);
        const formattedValues = prunedBeltValues.map((cell) => {
          if (!cell) cell = '';
          return `"${cell.toString().trim().replace(/"/g, '""')}"`;
        });

        // add each row to csv string
        csvContents += formattedValues.toString() + '\n';
      });
    } catch (err) {
      console.log(err);
    }

    return csvContents;
  }
}
