import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService, User } from '../../../services/auth/auth.service';
import { ForageService } from './../../../services/forage/forage.service';
import { MsalTokenService } from '../../../services/c-belt/msal-token.service';
import { TranslateService } from '../../../services/translate/translate.service';
import { UtilService } from './../../../utils/util.service';

import { Language } from './../../../models/language';

import { AuthRole } from '../../../models/userAzureDetails.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  @Input() isProduction: boolean;
  @Output() public closeSidebar: EventEmitter<unknown> = new EventEmitter<unknown>();

  public user: User;
  public version: string = environment.version;

  public currentLanguage: Language;
  public languageSub: Subscription;

  public constructor(
    private router: Router,
    private msalTokenService: MsalTokenService,
    private authService: AuthService,
    private forageService: ForageService,
    public t: TranslateService,
    public utils: UtilService
  ) {}

  ngOnDestroy(): void {
    if (this.languageSub) this.languageSub.unsubscribe();
  }

  async ngOnInit() {
    this.languageSub = this.t.subscribeToTranslationData((data) => {
      const languages = data.languages;
      const languageKey = this.t.getLanguageKey();

      this.currentLanguage = languages.find((lang) => lang.key == languageKey);
    });
  }

  public getUserEmail(): string {
    this.user = this.authService.getUser();
    return this.user?.email;
  }

  public isAdmin(): boolean {
    this.user = this.authService.getUser();
    if (!this.user) return false;
    return this.user.role == 'admin' || this.isSysAdmin();
  }

  public isSysAdmin(): boolean {
    if (!this.user) return false;
    const orgRoles = this.user.organisationRoles ?? {};
    return Object.values(orgRoles).some((roles) => roles.includes(AuthRole.SysAdmin));
  }

  public onClose(): void {
    this.closeSidebar.emit();
  }

  public navToRoute(route: string): void {
    this.onClose();
    this.router.navigate([route]);
  }

  public async logout(): Promise<void> {
    await this.forageService.clearForage();
    await this.msalTokenService.logout();
    this.onClose();
  }

  public isMobileOperatingSystem(): boolean {
    return MsalTokenService.isMobileApp();
  }
}
