import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '../../../services/translate/translate.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
  @Input() public searchText: string;
  @Output() public searchTextChange: EventEmitter<unknown> = new EventEmitter<unknown>();

  public constructor(public t: TranslateService) {}

  public onTextInput(): void {
    this.searchTextChange.emit(this.searchText);
  }
}
