import { cloneDeep } from 'lodash';
import { NFC } from '@awesome-cordova-plugins/nfc/ngx';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';

import { ConveyorReader } from 'src/app/models/conveyorReader.model';

import { AlertService } from 'src/app/shared/services/alert/alert.service';

import { Store } from '@ngrx/store';
import { Subscription, take } from 'rxjs';
import { ConfigurationState } from 'src/app/state/installation/installation.state';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-pair-conveyor-reader',
  templateUrl: './pair-conveyor-reader.component.html',
  styleUrls: ['./pair-conveyor-reader.component.scss'],
})
export class PairConveyorReaderComponent implements OnInit, OnDestroy {
  public tagId: string;
  public editing: boolean;
  public enabled: boolean;
  public baseRoute: string;
  public companyId: string;
  public isNfcActive: boolean = false;
  public current: ConfigurationState = null;
  public conveyorReader: ConveyorReader = new ConveyorReader();

  private nfcListener: any;

  private scannerSub: Subscription;

  public constructor(
    private nfc: NFC,
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private alertService: AlertService,
  ) {}

  public ngOnInit(): void {
    this.route.url.subscribe((route) => {
      this.baseRoute = route[0].path;
    });
    this.route.params.subscribe((params) => {
      this.companyId = params['company_id'];
      this.conveyorReader.conveyorName = params['conveyorName'];
    });
    this.subscribeToCurrentConfiguration();

    this.checkNfcEnabled();

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    this.nfcListener = () => {
      this.ngZone.run(() => this.checkNfcEnabled());
    };

    document.addEventListener('resume', this.nfcListener, false);
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.scannerSub) {
      this.scannerSub.unsubscribe();
    }

    document.removeEventListener('resume', this.nfcListener);
  }

  private async checkNfcEnabled(): Promise<void> {
    try {
      await this.nfc.enabled();
      this.isNfcActive = true;
      this.addListener();
    } catch (err) {
      console.log(err);
      this.isNfcActive = false;
    }
  }

  public openSettings(): void {
    this.nfc.showSettings();
  }

  public subscribeToCurrentConfiguration(): void {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.editing = this.current.editing;
        if (this.editing) {
          this.tagId = this.current.conveyorReader.readerId;
        }
      });
  }

  public async addListener(): Promise<void> {
    try {
      this.alertService.alertInfo('Attempting to Scan');
      this.scannerSub = this.nfc.addNdefListener().subscribe({
        next: (tag) => {
          const payload = tag['tag']['ndefMessage'][0]['payload'];
          const stringValue = this.nfc.bytesToString(payload);
          const hexValue = stringValue.slice(3, 20);
          this.tagId = this.macAddressToDecimal(hexValue).toString();
        },
        error: (err) => {
          if (err == 'NFC_DISABLED') {
            this.enabled = false;
            this.alertService.alertError('Please Enable NFC');
          }
        },
        complete: () => {
          console.log('Cameron: Complete');
        },
      });
    } catch (err) {
      console.log(err);
      this.alertService.alertError('Error scanning NFC tag');
    }
  }

  public navToPairReaderManually(): void {
    if (this.tagId) {
      this.updateSensorId();
    }
    this.router.navigate([
      this.baseRoute,
      this.companyId,
      'conveyor-reader-configuration',
      'pair-conveyor-reader-manually',
      this.conveyorReader.conveyorName,
    ]);
  }

  public updateSensorId(): void {
    this.store.dispatch(InstallationActions.updateNewSensorId({ sensorId: this.tagId }));
  }

  public macAddressToDecimal(macAddress: string): number {
    const macParts = macAddress.split(':');
    let decimalNumber = 0;

    for (let i = 0; i < macParts.length; i++) {
      const hexPart = parseInt(macParts[i], 16);
      decimalNumber = decimalNumber * 256 + hexPart;
    }

    return decimalNumber;
  }
}
