import { Component, Input, EventEmitter } from '@angular/core';

import { Output, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AppFormGroup } from '../../../utils/app-form-group';
import { TranslateService } from '../../../services/translate/translate.service';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css'],
})
export class TextareaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public model;
  @Output() public modelChange: EventEmitter<any> = new EventEmitter();

  @Input() public rows;
  @Input() public label;
  @Input() public name = '';
  @Input() public placeholder = '';
  @Input() public autofocus = false;
  @Input() public form: AppFormGroup;

  public focus = false;

  public get formControl(): FormControl {
    return this.form.get(this.name) as FormControl;
  }

  private sub: Subscription;

  constructor(public t: TranslateService) {}

  ngOnInit() {
    this.sub = this.formControl.valueChanges.subscribe((change) => {
      this.modelChange.emit(change);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['model']) {
      this.formControl.setValue(this.model);
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public isRequired(): boolean {
    if (this.formControl.validator) {
      const validator = this.formControl.validator({} as FormControl);
      if (validator && validator['required']) {
        return true;
      }
    }
    return false;
  }

  public getError(): string {
    const key = Object.keys(this.formControl.errors)[0];
    switch (key) {
      case 'required':
        return this.t.translate('_REQUIRED');
      case 'email':
        return `${this.label} ${this.t.translate('_INVALID')}`;
      case 'pattern':
        return `${this.label} ${this.t.translate('_INVALID')}`;
      case 'matchOther':
        return `${this.t.translate('_PASSWORDS_DO_NOT_MATCH')}`;
      case 'minlength':
        return `${this.t.translate('_MINIMUM')} ${
          this.formControl.errors['minlength'].requiredLength
        } ${this.t.translate('_CHARACTER')}`;
      case 'maxlength':
        return `${this.t.translate('_MAXIMUM')} ${
          this.formControl.errors['maxlength'].requiredLength
        } ${this.t.translate('_CHARACTERS')}`;
    }

    return key || '';
  }
}
