<div
  class="mobile"
  *ngIf="gatewayDevice">
  <app-mobile-header
    [header]="returnTitle()"
    [routerLinkUrl]="['/installing', companyId, 'gateway-configuration', 'new-gateway-device']">
  </app-mobile-header>

  <div class="mx-3 mt-3">
    <form
      name="configuration-form"
      class="d-flex"
      (submit)="navToConfiguring()">
      <div class="bg-white card p-3">
        <label
          for="wifiSsid"
          class="form-label mt-3"
          >Network name</label
        >
        <input
          [(ngModel)]="gatewayDevice.networkName"
          class="form-control"
          name="wifiSsid"
          type="text"
          placeholder="Enter SSID" />
        <label
          for="wifiPassword"
          class="form-label mt-3"
          >Network Password</label
        >
        <input
          [(ngModel)]="gatewayDevice.devicePassword"
          class="form-control"
          name="wifiPassword"
          type="password"
          placeholder="Enter Device Password" />
        <label
          for="ipMode"
          class="form-label mt-3"
          >IP Mode</label
        >

        <div class="d-flex">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="ipType"
              id="static"
              value="Static"
              [(ngModel)]="gatewayDevice.wifiType" />
            <label
              class="form-check-label"
              for="static">
              Static
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input ms-3"
              type="radio"
              name="ipType"
              id="dhcp"
              value="DHCP"
              [(ngModel)]="gatewayDevice.wifiType" />
            <label
              class="form-check-label ms-2"
              for="dhcp">
              DHCP
            </label>
          </div>
        </div>
        <input
          [(ngModel)]="gatewayDevice.ipAddress"
          class="form-control mt-2"
          name="ippAddress"
          type="text"
          placeholder="Enter IP Address"
          [disabled]="gatewayDevice.wifiType == 'DHCP'" />
        <label
          for="networkMask"
          class="form-label mt-3"
          [ngClass]="{ disabled: gatewayDevice.wifiType == 'DHCP' }"
          >Network Mask</label
        >
        <input
          [(ngModel)]="gatewayDevice.networkMask"
          class="form-control"
          name="networkMask"
          type="text"
          placeholder="Enter Device Network Mask"
          [disabled]="gatewayDevice.wifiType == 'DHCP'" />
        <label
          for="gateway"
          class="form-label mt-3"
          [ngClass]="{ disabled: gatewayDevice.wifiType == 'DHCP' }"
          >Gateway</label
        >
        <input
          [(ngModel)]="gatewayDevice.gatewayAddress"
          class="form-control"
          name="gateway"
          type="text"
          placeholder="Gateway Address"
          [disabled]="gatewayDevice.wifiType == 'DHCP'" />
        <label
          for="dns"
          class="form-label mt-3"
          [ngClass]="{ disabled: gatewayDevice.wifiType == 'DHCP' }"
          >DNS</label
        >
        <input
          [(ngModel)]="gatewayDevice.dns"
          class="form-control"
          name="dns"
          type="text"
          placeholder="DNS"
          [disabled]="gatewayDevice.wifiType == 'DHCP'" />

        <button
          type="submit"
          class="btn btn-primary mt-4">
          Configure Gateway
        </button>
      </div>
    </form>
  </div>
</div>
