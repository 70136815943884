import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { CBelt } from 'src/app/models/cBelt.model';

import { BeltsService } from 'src/app/services/c-belt/belts.service';
import { StateService } from 'src/app/services/c-belt/state.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { ConfigurationState } from 'src/app/state/installation/installation.state';

import { take } from 'rxjs';
import { Store } from '@ngrx/store';
import * as InstallationActions from 'src/app/state/installation/installation.actions';
import * as InstallationSelectors from 'src/app/state/installation/installation.selector';

@Component({
  selector: 'app-add-rfid-manually',
  templateUrl: './add-rfid-manually.component.html',
  styleUrl: './add-rfid-manually.component.scss',
})
export class AddRfidManuallyComponent implements OnInit {
  public editing: boolean;
  public belt: CBelt = new CBelt();
  public current: ConfigurationState = null;
  public isRndUser: boolean = false;

  public constructor(
    private store: Store,
    private router: Router,
    private cBeltService: BeltsService,
    private alertService: AlertService,
    private stateService: StateService,
    private loadingService: LoadingService,
  ) {}

  public ngOnInit(): void {
    this.subscribeToCurrentConfiguration();
  }

  public subscribeToCurrentConfiguration(): void {
    const select = InstallationSelectors.selectCurrentSummary();
    this.store
      .select(select)
      .pipe(take(1))
      .subscribe((current) => {
        this.current = cloneDeep(current);
        this.isRndUser = this.current.isRndUser;
        this.belt = this.current.belt;
        this.editing = this.current.editing;
      });
  }

  public async navToHome(): Promise<void> {
    if (this.isRndUser) {
      this.belt.salesLocationCode = 'DEMO';
    } else {
      await this.fetchSalesLocationCode();
    }

    if (!this.isValid()) {
      this.alertService.alertError('Please fill in all fields');
      return;
    }

    let belt;
    if (this.editing) {
      belt = await this.updateBelt();
    } else {
      belt = await this.addBelt();
    }
    if (!belt) {
      return;
    }
    this.belt.id = belt.id;
    this.alertService.alertSuccess('Belt Added Successfully!');
    this.store.dispatch(InstallationActions.updateBelt({ belt: this.belt }));
    this.store.dispatch(InstallationActions.setCurrentBelt({ belt: this.belt }));
    this.stateService.subscribeToCurrentAndSave();
    this.router.navigate(['c-belt']);
  }

  public isValid(): boolean {
    if (this.isRndUser) {
      return (
        !!this.belt.width &&
        !!this.belt.length &&
        !!this.belt.rfidTagId &&
        !!this.belt.orderNumber &&
        !!this.belt.salesLocationCode
      );
    } else {
      return (
        !!this.belt.cpr &&
        !!this.belt.width &&
        !!this.belt.length &&
        !!this.belt.rfidTagId &&
        !!this.belt.orderNumber &&
        !!this.belt.salesLocationCode
      );
    }
  }

  public async fetchSalesLocationCode(): Promise<void> {
    this.loadingService.show();
    try {
      const response = await this.cBeltService.getSalesLocationCode(this.belt.orderNumber);
      this.belt.width = Number(response.width) / 1000;
      this.belt.length = Number(response.length) / 1000;
      this.belt.cpr = response.cpr;
      this.belt.salesLocationCode = response.salesLocationCode;
    } catch (err) {
      this.alertService.handleError(err);
    }
    this.loadingService.hide();
  }

  public async updateBelt(): Promise<CBelt | null> {
    this.loadingService.show();
    try {
      return await this.cBeltService.updateBelt(this.belt);
    } catch (err) {
      console.error(err);
      this.alertService.alertError('Failed to update belt');
      return null;
    } finally {
      this.loadingService.hide();
    }
  }

  public async addBelt(): Promise<CBelt | null> {
    this.loadingService.show();
    try {
      return await this.cBeltService.addBelt(this.belt);
    } catch (err) {
      console.error(err);
      this.alertService.alertError('Failed to add belt');
      return null;
    } finally {
      this.loadingService.hide();
    }
  }
}
